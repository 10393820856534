import React from "react";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { configuratorPanelIconCards } from "./constants";
import { useHistory } from "react-router-dom";
import { gridCardKey, gridKey } from "../../../costants/costants";
import { useTranslation } from "react-i18next";

type ConfiguratorPanelProps = {
  role: string;
};

const ConfiguratorPanel: React.FC<ConfiguratorPanelProps> = ({ role }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ marginTop: "2%" }}
    >
      {configuratorPanelIconCards(role, t).map((iconItem, index) => (
        <Grid
          className="home-icon-card"
          item
          xs={12}
          sm={6}
          key={gridKey(String(index))}
        >
          <Card
            key={gridCardKey(String(index))}
            style={{
              backgroundColor: "#f4f6f9",
              backgroundImage: "none",
              boxShadow: "none",
              border: "none",
              width: "250px",
            }}
          >
            <CardContent className="card-content">
              <img alt="card-logo" className="card-logo" src={iconItem.img} />
            </CardContent>
            <div className="card-buttons">
              {iconItem.text.map((buttonText, buttonIndex) => (
                <Button
                  style={{ textTransform: "none" }}
                  key={buttonIndex}
                  className="z-button"
                  onClick={() => {
                    history.push(iconItem.link[buttonIndex]);
                  }}
                >
                  {buttonText}
                </Button>
              ))}
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ConfiguratorPanel;
