import React from "react";
import { useHistory } from "react-router";
import {
  alarmConfigurationUrl,
  calibrationUrl,
  irrigationConfigurationUrl,
  testingConfigurationUrl,
  updateDeviceConnectionConfigurationUrl,
} from "../costants";
import { primaryType } from "../../../costants/costants";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { DeveloperBoard } from "@mui/icons-material";
import ButtonItem from "../../custom/ButtonItem";
import { useTranslation } from "react-i18next";
import { DeleteInstallation } from "../../../api/services/installationsService";
import ButtonConfItem from "../../custom/ButtonConfItem";

type DeviceCardProps = {
  installationId: string;
  device: any;
  irrigation: boolean;
};

const DeviceCard: React.FC<DeviceCardProps> = ({
  installationId,
  device,
  irrigation,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Card variant="outlined">
      <CardHeader
        avatar={<DeveloperBoard />}
        title={<Typography variant="h6">{device.name}</Typography>}
      />
      <CardContent>
        <Grid container spacing={2} flexDirection="column">
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container spacing={1} justifyContent="space-around">
            <Grid item container xs={12} spacing={1}>
              <Grid
                item
                xs={12}
                md={device?.model === "actuator" && irrigation ? 4 : 6}
              >
                <ButtonItem
                  fullWidth
                  size="small"
                  buttonType={primaryType}
                  label={t("alarms")}
                  buttonOnClick={() =>
                    history.push(alarmConfigurationUrl(installationId))
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={device?.model === "actuator" && irrigation ? 4 : 6}
              >
                <ButtonItem
                  fullWidth
                  size="small"
                  buttonType={primaryType}
                  label={t("calibration")}
                  buttonOnClick={() =>
                    history.push(calibrationUrl(installationId))
                  }
                />
              </Grid>
              {device && device.model === "actuator" && irrigation && (
                <Grid item xs={12} md={4}>
                  <ButtonItem
                    fullWidth
                    size="small"
                    buttonType={primaryType}
                    label={t("irrigation")}
                    buttonOnClick={() =>
                      history.push(irrigationConfigurationUrl(installationId))
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} spacing={1}>
              {device && device.model === "plant" && (
                <Grid item xs={12} md={4}>
                  <ButtonItem
                    fullWidth
                    size="small"
                    buttonType={primaryType}
                    label={t("testing")}
                    buttonOnClick={() =>
                      history.push(testingConfigurationUrl(installationId))
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} md={device?.model === "plant" ? 4 : 6}>
                <ButtonItem
                  fullWidth
                  size="small"
                  buttonType={primaryType}
                  label={t("configuration")}
                  buttonOnClick={() =>
                    history.push(
                      updateDeviceConnectionConfigurationUrl(
                        device?.id || installationId
                      )
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} md={device?.model === "plant" ? 4 : 6}>
                <ButtonConfItem
                  fullWidth
                  size="small"
                  buttonType={primaryType}
                  buttonLabel={t("delete")}
                  buttonOnConfirm={() => {
                    DeleteInstallation(installationId).then((res) => {
                      if (res && !res.err) {
                        window.location.reload();
                      }
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DeviceCard;
