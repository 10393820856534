import React, { useState } from "react";
import {
  customersListColumnsAdmin,
  customersListColumnsInstaller,
} from "./column";
import { GetCustomers } from "../../../api/services/userService";
import CustomTitle from "../../custom/CustomTitle";
import { useHistory } from "react-router-dom";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { updateSubscriptionUrl, updateUserPageUrl } from "../costants";
import { customersKey, name } from "../../../costants/costants";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

type UsersListTableProps = {
  role: string;
  SetPopupObject: (obj: any) => void;
};

const UsersListTable: React.FC<UsersListTableProps> = ({
  role,
  SetPopupObject,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<Object[]>([]);
  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) => {
    history.push(updateUserPageUrl(value));
  };
  const linkAdminFunction: (value: string) => void = (value: string) => {
    history.push(updateSubscriptionUrl(value));
  };

  return (
    <>
      <CustomTitle title={t("editFleet")} />
      <ResponsiveTable
        columns={
          role === "admin"
            ? customersListColumnsAdmin(
                t,
                data,
                SetPopupObject,
                linkAdminFunction,
                enqueueSnackbar
              )
            : customersListColumnsInstaller(
                t,
                data,
                SetPopupObject,
                linkFunction
              )
        }
        getPageAndSortedData={GetCustomers}
        defaultSort={name.name}
        responseDataName={customersKey}
        setData={setData}
      />
    </>
  );
};

export default UsersListTable;
