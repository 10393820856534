import { installationFields } from "./inputs";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import React, { useCallback, useEffect, useState } from "react";
import { CreateInstallation } from "../../../api/services/installationsService";
import CustomTitle from "../../custom/CustomTitle";
import {
  addInstallationTitleKey,
  primaryType,
} from "../../../costants/costants";
import OperationResult from "../../custom/OperationResult";
import { Card, CardContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { addField, checkValues } from "../../../utilities/utilities";
import { GetCustomers } from "../../../api/services/userService";

const AddInstallation: React.FC = () => {
  const { t } = useTranslation();

  const [subOpt, setSubOpt] = useState<any[]>([]);
  const [status, setStatus] = useState<string>("");

  const goBack = () => {
    setValues({
      customer_id: "",
      name: "",
      type: "",
      description: "",
      address: "",
      city: "",
    });
    setStatus("");
  };

  useEffect(() => {
    GetCustomers("-1", "", "").then((res: any) => {
      let tmp: any[] = [];
      if (res && res.customers) {
        res.customers.map((u: any) => {
          tmp = [
            ...tmp,
            {
              [u.uid]: `${u.name} (${u.email})`,
            },
          ];
        });
        setSubOpt([...tmp]);
      }
    });
  }, []);

  const [error, setError] = useState<boolean>(false);
  const [values, setValues] = useState({
    customer_id: "",
    name: "",
    type: "",
    description: "",
    address: "",
    city: "",
  });

  const submit: any = useCallback(() => {
    if (checkValues(values)) {
      CreateInstallation({
        customer_id: values.customer_id,
        name: values.name,
        type: values.type,
        description: values.description,
        address: values.address,
        city: values.city,
      }).then((res: any) => {
        if (res && res.subscription) {
          setStatus("success");
        } else {
          setStatus(res?.err?.message || "");
        }
      });
    } else {
      setError(true);
    }
  }, [values]);

  if (status) {
    return (
      <OperationResult
        onClickProp={goBack}
        status={status === "success" ? "success" : "error"}
        operation="create"
        entity="installation"
        error={status}
      />
    );
  }

  return (
    <>
      <CustomTitle title={t("createDevice")} key={addInstallationTitleKey} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item container spacing={2}>
                  {installationFields(t, subOpt).map((el: any) =>
                    addField(el, values, setValues, error, true)
                  )}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ButtonConfItem
                      buttonLabel={t("reset")}
                      buttonOnConfirm={() => {
                        setValues({
                          customer_id: "",
                          name: "",
                          type: "",
                          description: "",
                          address: "",
                          city: "",
                        });
                      }}
                      questionLabel={t("question")}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("ok")}
                      buttonOnClick={submit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AddInstallation;
