/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { dccTitleKey, primaryType } from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ButtonItem from "../../custom/ButtonItem";
import { UsbOff } from "@mui/icons-material";
import { installationsListToUpdateUrl } from "../costants";

type TechnicalTestingProps = {
  installation_id: string;
};

const TechnicalTesting: React.FC<TechnicalTestingProps> = ({
  installation_id,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const hasReloaded = localStorage.getItem("hasTestReloaded");
    if (!hasReloaded || hasReloaded === "false") {
      // Esegui il ricaricamento solo se non è già stato fatto
      window.location.reload();
      localStorage.setItem("hasTestReloaded", "true"); // Imposta il flag di ricaricamento
    } else {
      navigator.serial.getPorts().then(async (ports) => {
        if (ports.length === 1) {
          console.log("Device port: ", ports[0]);
          setDevicePort(ports[0]);
          await ports[0].open({ baudRate: 115200 });
        } else {
          setPortError(true);
        }
      });
    }
  }, []);

  const [portError, setPortError] = useState<boolean>(false);

  const [technicalTests, setTechnicalTests] = useState<any[]>([
    { label: "version", value: "version", response: "---" },
    { label: "set_addr1", value: "set_addr1", response: "---" },
    { label: "get_1", value: "get_1", response: "---" },
    { label: "set_addr2", value: "set_addr2", response: "---" },
    { label: "get_2", value: "get_2", response: "---" },
    { label: "set_addr3", value: "set_addr3", response: "---" },
    { label: "get_3", value: "get_3", response: "---" },
    { label: "set_addr4", value: "set_addr4", response: "---" },
    { label: "get_4", value: "get_4", response: "---" },
  ]);

  const [devicePort, setDevicePort] = useState<any>(null);

  const readFromSerialPort = async () => {
    if (devicePort) {
      console.log("DevicePort:", devicePort);
      const reader = devicePort.readable.getReader();
      try {
        let data = "";
        while (!data.endsWith("\n")) {
          const { value, done } = await reader.read();
          if (done) break;
          data += new TextDecoder().decode(value);
        }
        console.log("Dati dalla porta seriale:", data);
        return data;
      } catch (error) {
        console.error("Errore durante la lettura dalla porta seriale:", error);
        return "ERROR while reading from serial port";
      } finally {
        reader.releaseLock();
      }
    }
  };

  const writeToSerialPort = async (data: string) => {
    if (devicePort) {
      const writer = devicePort.writable.getWriter();
      try {
        const encoder = new TextEncoder();
        const dataUint8 = encoder.encode(`${data}\n`);
        await writer.write(dataUint8);
        console.log("Dati scritti sulla porta seriale:", data);
      } catch (error) {
        console.error(
          "Errore durante la scrittura sulla porta seriale:",
          error
        );
      } finally {
        writer.releaseLock();
      }
    }
  };

  return (
    <>
      <TitleWithBack
        title={t("testing")}
        key={dccTitleKey}
        onClickProp={() => history.push(installationsListToUpdateUrl)}
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11} style={{ marginBottom: "64px" }}>
          <Card variant="outlined">
            <CardContent>
              {portError ? (
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="h6">{t("disconnectedUsb")}</Typography>
                  </Grid>
                  <Grid item>
                    <UsbOff style={{ fontSize: "128px" }} />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("retry")}
                      buttonOnClick={() => window.location.reload()}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item container xs={12} alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">COMANDO</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="subtitle2">RISPOSTA</Typography>
                    </Grid>
                  </Grid>
                  {technicalTests.map((item) => (
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={3}>
                        <ButtonItem
                          fullWidth
                          buttonType={primaryType}
                          label={item.label}
                          disabled={technicalTests.some(
                            (i) => i.response === "IN CORSO..."
                          )}
                          buttonOnClick={() => {
                            setTechnicalTests(
                              technicalTests.map((i) => {
                                if (i.value === item.value) {
                                  return {
                                    ...item,
                                    response: "IN CORSO...",
                                  };
                                } else {
                                  return i;
                                }
                              })
                            );
                            writeToSerialPort(item.value).then(() => {
                              readFromSerialPort().then((res) => {
                                setTechnicalTests(
                                  technicalTests.map((i) => {
                                    if (i.value === item.value) {
                                      return {
                                        ...item,
                                        response: res,
                                      };
                                    } else {
                                      return i;
                                    }
                                  })
                                );
                              });
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        {item.response}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default TechnicalTesting;
