import { Alert } from "@mui/material";
import {
  address,
  birthdate,
  city,
  companyAddress,
  companyCity,
  companyCountry,
  companyPhone,
  companyProvince,
  companySdi,
  companyVat,
  country,
  dateType,
  email,
  linkType,
  name,
  numberType,
  password,
  passwordType,
  pec,
  phone,
  province,
  reportEmail,
  sdi,
  selectType,
  stringType,
  subscriptonName,
  surname,
  tagType,
  vat,
} from "../../../costants/costants";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { InputType } from "../../types/types";

export const columns: (
  t: any,
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (
  t: any,
  linkFunction: (value: string) => void
) => [
  {
    label: t("utility"),
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={linkType}
          value={value}
          link={() => linkFunction(data.tableData[data.rowIndex].uid)}
        />
      ),
    },
  },
  {
    label: t("businessName"),
    name: "user_info.billing_info.soc_reason",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: "user_info.address",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("city"),
    name: "user_info.city",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
];

export const installationsColumns: (
  t: any,
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (
  t: any,
  linkFunction: (value: string) => void
) => [
  {
    label: t("installation"),
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={linkType}
          value={value}
          link={() => linkFunction(data.tableData[data.rowIndex].id)}
        />
      ),
    },
  },
  {
    label: t("type"),
    name: "type",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("description"),
    name: "description",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },

  {
    label: t("address"),
    name: "address",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("city"),
    name: "city",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("expiration"),
    name: "expiry_date",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
  {
    label: t("status"),
    name: "status",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ResponsiveTableCell
            type={tagType}
            value={value === "enabled" ? t("enabled") : t("disabled")}
            color={value === "enabled" ? "success" : "error"}
          />
          {data.tableData[data.rowIndex].requested_status &&
            ((data.tableData[data.rowIndex].requested_status ===
              "deactivation" &&
              value === "enabled") ||
              (data.tableData[data.rowIndex].requested_status ===
                "activation" &&
                value === "disabled")) && (
              <Alert
                variant="filled"
                style={{ marginLeft: "32px" }}
                severity={
                  data.tableData[data.rowIndex].requested_status ===
                  "deactivation"
                    ? "error"
                    : "success"
                }
              >
                {data.tableData[data.rowIndex].requested_status ===
                  "activation" && t("activationRequest")}
                {data.tableData[data.rowIndex].requested_status ===
                  "deactivation" && t("deactivationRequest")}
              </Alert>
            )}
        </div>
      ),
    },
  },
];

export const billingDatafields: (t: any, countries: any[]) => InputType[] = (
  t: any,
  countries: any[]
) => [
  {
    key: subscriptonName.key,
    name: subscriptonName.name,
    label: t("businessName"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyAddress.key,
    name: companyAddress.name,
    label: t("address"),
    path: address.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyCity.key,
    name: companyCity.name,
    label: t("city"),
    path: city.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyProvince.key,
    name: companyProvince.name,
    label: t("province"),
    path: province.name,
  },
  {
    key: companyCountry.key,
    name: companyCountry.name,
    label: t("country"),
    path: country.name,
    options: countries,
    rules: { required: true, message: t("requiredField") },
    type: selectType,
  },
  {
    key: companyVat.key,
    name: companyVat.name,
    label: t("vat"),
    path: vat.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companySdi.key,
    name: companySdi.name,
    path: sdi.name,
    label: t("sdi"),
  },
  {
    key: pec.key,
    name: pec.name,
    label: t("pec"),
    path: pec.name,
  },
];

export const fleetDatafields: (t: any, countries: any[]) => InputType[] = (
  t: any,
  countries: any[]
) => [
  {
    key: subscriptonName.key,
    name: subscriptonName.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyAddress.key,
    name: companyAddress.name,
    label: t("address"),
    path: address.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyCity.key,
    name: companyCity.name,
    label: t("city"),
    path: city.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyProvince.key,
    name: companyProvince.name,
    label: t("province"),
    path: province.name,
  },
  {
    key: companyCountry.key,
    name: companyCountry.name,
    label: t("country"),
    path: country.name,
    options: countries,
    rules: { required: true, message: t("requiredField") },
    type: selectType,
  },
];

export const ownerDataFields: (t: any) => InputType[] = (t: any) => [
  {
    key: name.key,
    name: name.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: surname.key,
    name: surname.name,
    label: t("surname"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: birthdate.key,
    name: birthdate.name,
    label: t("birthdate"),
    path: birthdate.name,
    type: "date",
  },
  {
    key: companyCity.key,
    name: companyCity.name,
    label: t("city"),
    path: city.name,
    rules: { required: true, message: t("requiredField") },
  },
];

export const accountDataFields: (t: any) => InputType[] = (t: any) => [
  {
    key: email.key,
    name: email.name,
    label: t("email"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: password.key,
    name: password.name,
    label: t("password"),
    rules: { required: true, message: t("requiredField") },
    type: passwordType,
  },
  {
    key: reportEmail.key,
    name: reportEmail.name,
    label: t("reportEmail"),
    path: reportEmail.name,
  },
  {
    key: companyPhone.key,
    name: companyPhone.name,
    label: t("phone"),
    path: phone.name,
    rules: { required: true, message: t("requiredField") },
    type: numberType,
  },
];
