import React, { useEffect, useState } from "react";
import {
  dashboardActuatorUrl,
  dashboardPlantUrl,
  dashboardPumpUrl,
  installationListToLineChartUrl,
} from "../costants";
import { Installation } from "../../../api/requests/installationsService";
import AlarmSummary from "./summaryCards/AlarmSummary";
import InstallationSummary from "./summaryCards/InstallationSummary";
import RealTimeSummary from "./summaryCards/RealtimeSummary";
import TitleWithBack from "../../custom/TitleWithBack";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAllDevices } from "../../../api/services/deviceService";

type TimeSeriesProps = {
  installation_id: string;
};

const InstallationPage: React.FC<TimeSeriesProps> = ({ installation_id }) => {
  const { t, i18n } = useTranslation();

  const [installation, setInstallation] = useState<Installation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [iFrameUrl, setIFrameUrl] = useState<string>("");

  useEffect(() => {
    getAllDevices().then((res) => {
      if (res && res.installations) {
        const tmp = res.installations.filter(
          (i: any) => i.id === installation_id
        );
        if (tmp.length > 0) {
          setInstallation(tmp[0]);
          if (tmp[0].model === "plant") {
            setIFrameUrl(dashboardPlantUrl(tmp[0].id));
          }
          if (tmp[0].model === "pump") {
            setIFrameUrl(dashboardPumpUrl(tmp[0].id));
          }
          if (tmp[0].model === "actuator") {
            setIFrameUrl(dashboardActuatorUrl(tmp[0].id));
          }
        }
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        key="real_time_data"
        title={t("realTimeData")}
        link={installationListToLineChartUrl(
          installation?.subscription_id || ""
        )}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <InstallationSummary selected_installation={installation} />
        </Grid>
        <Grid item xs={12} md={4}>
          <RealTimeSummary selected_installation={installation} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AlarmSummary installation={installation} />
        </Grid>
      </Grid>
      <iframe
        id="grafana_iframe"
        className="responsive-iframe"
        src={iFrameUrl}
        style={{ height: "4000px" }}
      />
    </>
  );
};

export default InstallationPage;
