import React from "react";
import { useTranslation } from "react-i18next";
import { Installation } from "../../../api/requests/installationsService";
import { Card, CardContent, List, ListItemText, Chip } from "@mui/material";

type InstallationSummaryProps = {
  selected_installation: Installation | null;
};

const InstallationSummary: React.FC<InstallationSummaryProps> = ({
  selected_installation,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      style={{ marginBottom: "5%", marginLeft: "5%", marginRight: "5%" }}
    >
      <CardContent sx={{ display: "flex", justifyContent: "space-around" }}>
        <List>
          <ListItemText
            primary={t("name")}
            secondary={selected_installation?.name || ""}
          />
          <ListItemText
            primary={t("status")}
            secondary={
              <Chip
                color="error"
                label={selected_installation?.status || ""}
                size="small"
              />
            }
          />
        </List>
        <List>
          <ListItemText
            primary={t("type")}
            secondary={t(selected_installation?.model || "")}
          />
          <ListItemText
            primary={t("creationDate")}
            secondary={new Date(
              selected_installation?.created_at || ""
            ).toLocaleString()}
          />
        </List>
      </CardContent>
    </Card>
  );
};

export default InstallationSummary;
