import React, { useEffect, useState } from "react";
import logo from "../../../images/logo-nurset.svg";
import { Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

type ResultResetPasswordProps = {};

const ResultResetPassword: React.FC<ResultResetPasswordProps> = () => {
  const { t } = useTranslation();
  document.body.style.backgroundColor = "#4c8c2c";

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
        <div className="image-container">
          <img
            src={logo}
            className={width <= 768 ? "image-responsive" : "image"}
            alt="logo-nurset"
          />
        </div>
        <Alert severity="success" variant="outlined">
          <AlertTitle>{t("resetPasswordResultTitle")}</AlertTitle>
          {t("resetPasswordResultSubTitle")}
        </Alert>
      </div>
    </>
  );
};

export default ResultResetPassword;
