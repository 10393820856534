import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { iconType, linkType, stringType } from "../../../utilities/utilities";
import { IconButton, Tooltip } from "@mui/material";
import IconConfItem from "../../custom/IconConfItem";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DeleteInstallation } from "../../../api/services/installationsService";
import { dateType, tagType } from "../../../costants/costants";

export const devicesListColumns: (
  t: any,
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (t, linkFunction) => [
  {
    label: t("device"),
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={linkType}
          value={value}
          link={() => linkFunction(data.tableData[data.rowIndex].id)}
        />
      ),
    },
  },
  {
    label: t("type"),
    name: "model",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={t(value)} />
      ),
    },
  },
  {
    label: t("utility"),
    name: "customer",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("status"),
    name: "status",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell
          type={tagType}
          value={value}
          color={value === "online" ? "success" : "error"}
        />
      ),
    },
  },
  {
    label: t("lastConnection"),
    name: `last_connection`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value ? value : "---"} />
      ),
    },
  },
];

export const installationsListColumns: (
  t: any,
  rowData: any,
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void,
  admin: boolean
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction,
  admin
) => [
  {
    label: t("device"),
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="details-container">
          <Tooltip title={t("details")}>
            <IconButton
              onClick={() => {
                const tmp: any = rowData[data.rowIndex];
                console.log(tmp);
                const objects: any[] = [
                  {
                    name: tmp.name,
                    type: tmp.type,
                    description: tmp.description,
                    address: tmp.address,
                    city: tmp.city,
                    utility: tmp.customer_name,
                  },
                ];
                SetPopupObject(objects);
              }}
            >
              <InfoOutlinedIcon htmlColor="#455a64" className="details-icon" />
            </IconButton>
          </Tooltip>
          <div className="details">
            <ResponsiveTableCell
              type={linkType}
              value={value}
              link={() => linkFunction(data.tableData[data.rowIndex].id)}
            />
          </div>
        </div>
      ),
    },
  },
  {
    label: t("type"),
    name: "type",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("utility"),
    name: "customer_name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("businessName"),
    name: "customer_info.billing_info.soc_reason",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("status"),
    name: `status`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("expiration"),
    name: "expiry_date",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
];

export const updateInstallationTableColumns: (
  t: any,
  rowData: any,
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void,
  admin: boolean,
  enqueueSnackbar: any
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction,
  admin,
  enqueueSnackbar
) => [
  ...installationsListColumns(t, rowData, SetPopupObject, linkFunction, admin),
  ...adminColumn(admin, t, enqueueSnackbar),
];

const adminColumn = (admin: boolean, t: any, enqueueSnackbar: any) => {
  if (admin) {
    return [
      {
        label: t("delete"),
        name: "id",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: string) => (
            <ResponsiveTableCell
              type={iconType}
              value=""
              icon={
                <IconConfItem
                  icon={<DeleteOutlineOutlinedIcon />}
                  questionLabel={t("deleteInstallationQuestion")}
                  buttonOnConfirm={() => {
                    DeleteInstallation(value).then((res: any) => {
                      if (res && !res.err) {
                        enqueueSnackbar(t("installationDeleteSuccess"), {
                          variant: "success",
                          preventDuplicate: true,
                        });
                        window.location.reload();
                      } else {
                        enqueueSnackbar(
                          res?.err?.message || t("somethingWentWrong"),
                          {
                            variant: "error",
                            preventDuplicate: true,
                          }
                        );
                      }
                    });
                  }}
                />
              }
            />
          ),
        },
      },
    ];
  } else {
    return [];
  }
};
