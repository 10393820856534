import {
  finish,
  installationName,
  level,
  linkType,
  start,
  tag,
} from "../../../costants/costants";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import {
  alarmLabel,
  dateTimeType,
  stringType,
} from "../../../utilities/utilities";
import { Alert } from "@mui/material";

export const alarmColumns: (
  t: any,
  linkFunction: (link: string) => void
) => ResponsiveTableColumnType[] = (t, linkFunction) => [
  {
    label: t("start"),
    name: start.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: any) => (
        <ResponsiveTableCell type={dateTimeType} value={value} />
      ),
    },
  },
  {
    label: t("finish"),
    name: finish.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value) => (
        <ResponsiveTableCell
          type={value !== "0001-01-01T00:00:00Z" ? dateTimeType : stringType}
          value={value !== "0001-01-01T00:00:00Z" ? value : ""}
        />
      ),
    },
  },
  {
    label: t("alarm"),
    name: tag.name,
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => (
        <ResponsiveTableCell type={stringType} value={alarmLabel(value)} />
      ),
    },
  },
  {
    label: t("level"),
    name: level.name,
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => {
        if (value) {
          return (
            <Alert
              variant="filled"
              severity={
                value === "low"
                  ? "info"
                  : value === "warning"
                  ? "warning"
                  : "error"
              }
            >
              {t(value)}
            </Alert>
          );
        } else {
          return <> </>;
        }
      },
    },
  },

  {
    label: "",
    name: "installation_id",
    options: {
      sort: false,
      filter: true,
      display: false,
      customBodyRender: (value) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("device"),
    name: installationName.name,
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value, data) => (
        <ResponsiveTableCell
          type={linkType}
          value={value}
          link={() => linkFunction(data.rowData[0])}
        />
      ),
    },
  },
];
