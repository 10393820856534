import { CircularProgress } from "@mui/material";
import React from "react";

type CustomLoadingProps = {};

const CustomLoading: React.FC<CustomLoadingProps> = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
      <CircularProgress size="8rem" style={{ color: "#fde6c0" }} />
    </div>
  );
};

export default CustomLoading;
