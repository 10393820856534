import { adminMenuItems } from "./adminMenuItems";
import { installerMenuItems } from "./installerMenuItems";
import { customerMenuItems } from "./customerMenuItems";
import React, { useEffect, useState } from "react";
import { GetUserProfile } from "../../api/services/userService";
import { MenuType, SubMenuType } from "../types/types";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  adminRole,
  collapseKey,
  divKey,
  drawerKey,
  installerRole,
  listKey,
  persistentVariant,
  secondaryListKey,
  textKey,
} from "../../costants/costants";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@mui/icons-material";
import { set } from "lockr";

type NavbarProps = {
  SetNavbarIsCollapsed: (collapsed: boolean) => void;
  navbar_collapsed: boolean;
  onlyIcon: boolean;
};

const Navbar: React.FC<NavbarProps> = ({
  SetNavbarIsCollapsed,
  navbar_collapsed,
  onlyIcon,
}) => {
  const { t, i18n } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<string>("");
  const [panelOpen, setPanelOpen] = useState<boolean[]>([false]);
  const [menuItem, setMenuItem] = useState<MenuType[]>([]);
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    GetUserProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        const role: string = res["user"]["roles"][0];
        if (role.includes(installerRole)) {
          setMenuItem(installerMenuItems(t));
        } else {
          if (role.includes(adminRole)) {
            setPanelOpen([false, false]);
            setMenuItem(adminMenuItems(t));
          } else {
            setMenuItem(customerMenuItems(t));
          }
        }
      }
      if (
        res &&
        res.user &&
        res.user &&
        res.user.user_info &&
        res.user.user_info.dorian_info
      ) {
        set("td_status", {
          _pvars: {
            current_page: "home",
            my_profile: {
              ...res.user.user_info.dorian_info,
              uid: res?.user?.uid || "",
            },
          },
        });
      }
    });
  }, [i18n.language]);

  return (
    <>
      <Drawer
        key={drawerKey}
        onClose={() => SetNavbarIsCollapsed(true)}
        open={isMobile ? !navbar_collapsed : true}
        variant={!isMobile ? persistentVariant : undefined}
        sx={{
          borderRight: "4px solid #5c4424",
          fontSize: "14px",
        }}
      >
        <List
          style={{ zIndex: -1, width: !onlyIcon ? "240px" : undefined }}
          key={listKey}
        >
          {menuItem.map((item: MenuType, index: number) => {
            if ((isMobile && index !== 0) || !isMobile) {
              return (
                <div key={divKey(item.key)}>
                  <ListItem
                    button
                    key={item.key}
                    onClick={() => {
                      if (item?.link) {
                        if (typeof item.link === "string") {
                          history.push(item.link);
                        } else {
                          history.push(item.link());
                        }
                      }
                      if (item.elements.length > 0) {
                        let tmp = panelOpen;
                        tmp[index] = !tmp[index];
                        setPanelOpen([...tmp]);
                      } else {
                        setSelectedItem(item.key);
                        SetNavbarIsCollapsed(true);
                      }
                    }}
                    selected={selectedItem === item.key}
                    style={{ padding: "0px" }}
                  >
                    {item.icon && (
                      <Tooltip title={item.label} placement="right">
                        <ListItemIcon>{item.icon}</ListItemIcon>
                      </Tooltip>
                    )}
                    {!onlyIcon && (
                      <>
                        <ListItemText
                          sx={{ fontSize: "14px" }}
                          key={textKey(item.key)}
                          primary={
                            <p style={{ fontSize: "14px", margin: "0px" }}>
                              {item.label}
                            </p>
                          }
                        />
                        {item.elements.length > 0 ? (
                          <ExpandMore htmlColor="#455a64" />
                        ) : null}
                      </>
                    )}
                  </ListItem>
                  {item.elements.length > 0 ? (
                    <Collapse
                      key={collapseKey(item.key)}
                      in={panelOpen[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        key={secondaryListKey(item.key)}
                        component="div"
                        disablePadding
                        style={{ paddingLeft: !onlyIcon ? "20px" : "0px" }}
                      >
                        {item.elements.map((e: SubMenuType) => (
                          <ListItem
                            key={e.key}
                            selected={selectedItem === e.key}
                            button
                            onClick={() => {
                              if (e?.link) {
                                setSelectedItem(e.key);
                                if (typeof e.link === "string") {
                                  history.push(e.link);
                                } else {
                                  history.push(e.link());
                                }
                                SetNavbarIsCollapsed(true);
                              }
                            }}
                            style={{ padding: "0px" }}
                          >
                            {e.icon && (
                              <Tooltip title={e.label} placement="right">
                                <ListItemIcon>{e.icon}</ListItemIcon>
                              </Tooltip>
                            )}
                            {!onlyIcon && (
                              <ListItemText
                                key={textKey(e.key)}
                                primary={
                                  <p
                                    style={{ fontSize: "14px", margin: "0px" }}
                                  >
                                    {e.label}
                                  </p>
                                }
                              />
                            )}
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  ) : null}
                </div>
              );
            }
          })}
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
