import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { linkType, stringType } from "../../../utilities/utilities";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { name } from "../../../costants/costants";

export const tableColumn: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction
) => [
  {
    label: t("name"),
    name: name.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="details-container">
          <Tooltip title={t("details")}>
            <IconButton
              onClick={() => {
                const tmp: any = rowData[data.rowIndex];
                const objects: any[] = [
                  {
                    utility: tmp.customer_name,
                    business_name: tmp.customer_info.billing_info.soc_reason,
                    vat_number: tmp.customer_info.billing_info.piva,
                    address: tmp.address,
                    city: tmp.city,
                    expiry_date: tmp.expiry_date,
                  },
                ];
                SetPopupObject(objects);
              }}
            >
              <InfoOutlinedIcon htmlColor="#455a64" className="details-icon" />
            </IconButton>
          </Tooltip>
          <div className="details">
            <ResponsiveTableCell
              type={linkType}
              value={value}
              link={() => {
                linkFunction((rowData[data.rowIndex] as any).id);
              }}
            />
          </div>
        </div>
      ),
    },
  },
  {
    label: t("type"),
    name: "type",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: "address",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("city"),
    name: "city",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("status"),
    name: "status",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
];
