import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { UserRelation } from "../../../api/requests/userService";
import { iconType, stringType } from "../../../utilities/utilities";
import {
  customerEmailRel,
  customerNameRel,
  installerEmailRel,
  installerNameRel,
} from "../../../costants/costants";
import IconConfItem from "../../custom/IconConfItem";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export const installersListColumns: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  deleteRelation: (record: UserRelation) => void
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  deleteRelation
) => [
  {
    label: t("customer"),
    name: customerNameRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("businessName"),
    name: customerEmailRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("installer"),
    name: installerNameRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("businessName"),
    name: installerEmailRel.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("disassociate"),
    name: "",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={iconType}
          value={""}
          icon={
            <IconConfItem
              icon={<DeleteOutlineOutlinedIcon htmlColor="#455a64" />}
              buttonOnConfirm={() =>
                deleteRelation(data.tableData[data.rowIndex])
              }
            />
          }
        />
      ),
    },
  },
];
