// types string constants
import moment from "moment";
import { debounce } from "debounce";
import { Grid } from "@mui/material";
import FormInput from "../components/custom/input/FormInput";
import FormSelect from "../components/custom/select/FormSelect";
import {
  engCountries,
  itaCountries,
} from "../components/custom/select/countries";
import { formInputKey, selectType, textType } from "../costants/costants";
import i18n from "../i18n";
import { InputType } from "../components/types/types";

export const radioType = "radio";
export const stringType = "string";
export const usageType = "usage";
export const numberType = "number";
export const linkType = "link";
export const tagType = "tag";
export const dateType = "date";
export const dateTimeType = "datetime";
export const iconType = "icon";
export const statusDate = "statusDate";
export const thresholdType = "threshold";
export const numberInput = "number";
export const selectInput = "select";

// Dates formatters
export const italyTimezone = "it-IT";
export const localeDateFormatter = (value: string) => {
  const timestamp = Date.parse(value);
  if (!isNaN(timestamp)) {
    return moment(new Date(value)).format("DD/MM/YYYY");
  } else {
    return "---";
  }
};

export const localeDateTimeFormatter = (value: string) => {
  const timestamp = Date.parse(value);
  if (!isNaN(timestamp)) {
    return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
  } else {
    return "---";
  }
};

export function debounceFunc(func: any) {
  return debounce(func, 2000, true);
}

export const checkValues = (values: Object) => {
  let result = true;
  Object.keys(values).map((k: string) => {
    if (!(values as any)[k]) {
      result = false;
    }
  });
  return result;
};

export const areAllFieldsFilled = (data: Object) => {
  return Object.values(data).every((value) => {
    return value.trim() !== "";
  });
};

export const addField = (
  el: InputType,
  values: any,
  setValues: any,
  error?: boolean,
  all?: boolean
) => {
  return (
    <Grid item xs={12} md={all ? 12 : 6}>
      {el.type !== selectType ? (
        <FormInput
          error={error}
          key={formInputKey(el.key)}
          placeholder={el.label}
          type={el.type ?? textType}
          keyValue={el.key}
          name={el.name}
          rules={el.rules}
          value={(values as any)[el.name]}
          onchange={(e: any) => {
            setValues({ ...values, [el.name]: e.target.value });
          }}
        />
      ) : (
        <FormSelect
          error={error}
          key={formInputKey(el.key)}
          placeholder={el.label}
          keyValue={el.key}
          name={el.name}
          options={
            el.options
              ? el.options
              : i18n.language === "en"
              ? engCountries
              : itaCountries
          }
          rules={el.rules}
          value={(values as any)[el.name]}
          onChange={(e: any) => {
            setValues({ ...values, [el.name]: e.target.value });
          }}
        />
      )}
    </Grid>
  );
};

export const alarmLabel = (tag: string) => {
  if (tag === "batt_lvl_avg") return "Allarme livello tensione batteria basso";
  if (tag === "solar_lvl_avg")
    return "Allarme livello tensione pannello solare basso";
  if (tag === "ev_avg") return "Allarme tempo apertura elettrovalvola alto";
  if (tag === "t_soil1_avg_max") return "Allarme temperatura vaso 1 alta";
  if (tag === "t_soil1_avg_min") return "Allarme temperatura vaso 1 bassa";
  if (tag === "hum_soil1_avg_max") return "Allarme umidità vaso 1 alta";
  if (tag === "hum_soil1_avg_min") return "Allarme umidità vaso 1 bassa";
  if (tag === "ec_soil1_avg_max")
    return "Allarme conducibilità elettrica vaso 1 alta";
  if (tag === "ec_soil1_avg_min")
    return "Allarme conducibilità elettrica vaso 1 bassa";
  if (tag === "t_int_avg_max") return "Allarme temperatura interna alta";
  if (tag === "hum_int_avg_max") return "Allarme umidità interna alta";
  if (tag === "wind_vel_avg") return "Allarme velocità del vento alta";
  if (tag === "p_prefilter_avg") return "Allarme pressione pre filtro alta";
  if (tag === "p_postfilter_avg") return "Allarme pressione post filtro bassa";
  if (tag === "fert_lvl_avg") return "Allarme livello fertilizzante basso";
  if (tag === "water_meter_avg") return "Allarme consumo acqua elevato";
  if (tag === "t_ext_avg_max") return "Allarme temperatura esterna alta";
  if (tag === "t_ext_avg_min") return "Allarme temperatura esterna bassa";
  if (tag === "power_avg") return "Allarme consumo potenza elevato";
  return tag;
};
