import "./summary.css";
import React from "react";
import moment from "moment";
import { Installation } from "../../../../api/requests/installationsService";
import {
  Typography,
  Divider,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type InstallationSummaryProps = {
  selected_installation: Installation | null;
};

const InstallationSummary: React.FC<InstallationSummaryProps> = ({
  selected_installation,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      style={{ minHeight: "290px", maxHeight: "290px", overflow: "scroll" }}
    >
      <CardHeader
        style={{ maxHeight: "4vh" }}
        title={
          <Typography variant="h5" className="cardTitle">
            {t("device")}
          </Typography>
        }
      />
      <CardContent>
        <div key="container_div_1">
          <div className="tag-container">
            <Typography>{t("name")}:</Typography>
            <Typography>{selected_installation?.name || ""}</Typography>
          </div>
        </div>
        <Divider style={{ paddingTop: "8px", paddingBottom: "8px" }} />
        <div key="container_div_2">
          <div className="tag-container">
            <Typography>{t("model")}:</Typography>
            <Typography>{selected_installation?.model || ""}</Typography>
          </div>
          <Divider style={{ paddingTop: "8px", paddingBottom: "8px" }} />
        </div>
        <div key="container_div_3">
          <div className="tag-container">
            <Typography>{t("creationDate")}:</Typography>
            <Typography>
              {moment(selected_installation?.created_at || "").format(
                "DD/MM/YYYY"
              )}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default InstallationSummary;
