import React, { useEffect, useState } from "react";
import ButtonItem from "../../custom/ButtonItem";
import { useHistory } from "react-router";
import { loginPageUrl } from "../costants";
import logo from "../../../images/logo-nurset.svg";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { primaryType } from "../../../costants/costants";

type ResultRecoverPasswordProps = {};

const ResultRecoverPassword: React.FC<ResultRecoverPasswordProps> = () => {
  const { t } = useTranslation();
  document.body.style.backgroundColor = "#4c8c2c";

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const history = useHistory();
  const submit = () => history.push(loginPageUrl);

  return (
    <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
      <div className="image-container">
        <img
          src={logo}
          className={width <= 768 ? "image-responsive" : "image"}
          alt="logo-nurset"
        />
      </div>
      <Alert severity="success" style={{ marginTop: "16px" }}>
        <AlertTitle>{t("changePasswordTitle")}</AlertTitle>
        <Grid flexDirection="column">
          <Grid item>{t("changePasswordSubTitle")}</Grid>
        </Grid>
      </Alert>
      <Grid item style={{ marginTop: "32px" }}>
        <ButtonItem
          buttonType={primaryType}
          label={t("login")}
          buttonOnClick={submit}
        />
      </Grid>
    </div>
  );
};

export default ResultRecoverPassword;
