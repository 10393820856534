import ButtonItem from "../../custom/ButtonItem";
import { inputs } from "./inputs";
import React, { useCallback, useEffect, useState } from "react";
import queryString from "querystring";
import { RouteComponentProps } from "react-router";
import { recoverPassword } from "../../../api/services/loginService";
import { useHistory } from "react-router-dom";
import { recoverPageUrlResult } from "../costants";
import logo from "../../../images/logo-nurset.svg";
import { primaryType, successStatusKey } from "../../../costants/costants";
import { TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const RecoverPassword: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  document.body.style.backgroundColor = "#4c8c2c";

  const [token, setToken] = useState<string | string[]>("");
  const [width, setWidth] = useState<number>(window.innerWidth);
  const history = useHistory();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const params = queryString.parse(props.location.search);
    if (params["?token"]) {
      setToken(params["?token"]);
    } else {
      setToken("");
    }
  }, [props.location.search]);

  const [values, setValues] = useState({ password: "", confirm_password: "" });
  const submit = useCallback(() => {
    recoverPassword({
      password: values.password,
      confirm_password: values.confirm_password,
      token: token,
    })
      .then((response) => {
        if (response.status === successStatusKey) {
          history.push(recoverPageUrlResult);
        } else {
          enqueueSnackbar(t("recoverPasswordError"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((e) => {
        console.log("error:", e);
      });
  }, [token, values]);

  const StyledTextField = {
    "& label": {
      color: "#5c4424",
    },
    "& label.Mui-focused": {
      color: "#5c4424",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5c4424",
      color: "#5c4424",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
      },
      "&:hover fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
      },
      "& .MuiInputBase-input": { borderColor: "#5c4424", color: "#5c4424" },
    },
  };
  return (
    <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
      <div className="image-container">
        <img
          src={logo}
          className={width <= 768 ? "image-responsive" : "image"}
          alt="logo"
        />
      </div>
      <Typography style={{ fontWeight: "bold", color: "#5c4424" }} variant="h6">
        {t("insertNewPassword")}
      </Typography>
      {inputs(t).map((el) => {
        return (
          <TextField
            inputProps={{ "data-testid": el.key }}
            type={el.type}
            size="small"
            id={el.key}
            name={el.name}
            label={el.label}
            onChange={(e) =>
              setValues({ ...values, [el.name]: e.target.value })
            }
            variant="outlined"
            required
            fullWidth
            sx={StyledTextField}
            InputProps={{ sx: { color: "#fde6c0" } }}
            style={{ marginTop: "2%", paddingRight: "2%" }}
          />
        );
      })}
      <div className="recover-btn-container">
        <ButtonItem
          buttonType={primaryType}
          label={t("confirm")}
          buttonOnClick={submit}
        />
      </div>
    </div>
  );
};

export default RecoverPassword;
