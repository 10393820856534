import { Lock } from "@mui/icons-material";
import {
  confirmPassword,
  password,
  passwordType,
} from "../../../costants/costants";
import { InputType } from "../../types/types";

export const inputs: (t: any) => InputType[] = (t: any) => [
  {
    key: password.key,
    name: password.name,
    label: t("password"),
    rules: { required: true, message: t("requiredField") },
    type: passwordType,
    icon: <Lock className="site-form-item-icon" />,
  },
  {
    key: confirmPassword.key,
    name: confirmPassword.name,
    label: t("confirmPassword"),
    rules: { required: true, message: t("requiredField") },
    type: passwordType,
    icon: <Lock className="site-form-item-icon" />,
  },
];
