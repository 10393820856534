import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import {
  GetUser,
  UpdateUser as ApiUpdateUser,
} from "../../../api/services/userService";
import React, { useCallback, useEffect, useState } from "react";
import { User } from "../../../api/requests/userService";
import OperationResult from "../../custom/OperationResult";
import {
  errorStatus,
  installerRole,
  primaryType,
} from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { businessNameFields, installerDataFields } from "./inputs";
import { addField } from "../../../utilities/utilities";

type UpdateInstallerProps = {
  user_id: string;
};

const UpdateInstaller: React.FC<UpdateInstallerProps> = ({ user_id }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState<string>("");
  const goBack = () => setStatus("");

  const [values, setValues] = useState({
    company_phone: "",
    address: "",
    city: "",
    province: "",
    country: "",
    zip_code: "",
    subscription_name: "",
    company_address: "",
    company_city: "",
    company_province: "",
    company_country: "",
    company_zip_code: "",
    company_vat_number: "",
    company_sdi: "",
    pec: "",
    name: "",
    surname: "",
  });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  useEffect(() => {
    GetUser({ user_id: user_id }).then((res: any) => {
      if (res && res.user && res.user.user_info) {
        setValues({
          company_phone: res.user.user_info.phone,
          address: res.user.user_info.address,
          city: res.user.user_info.city,
          province: res.user.user_info.province,
          country: res.user.user_info.country,
          zip_code: res.user.user_info.zip_code,
          subscription_name: res.user.user_info.billing_info.soc_reason,
          company_address: res.user.user_info.billing_info.address,
          company_city: res.user.user_info.billing_info.city,
          company_province: res.user.user_info.billing_info.province,
          company_country: res.user.user_info.billing_info.state,
          company_zip_code: res.user.user_info.billing_info.zip_code,
          company_vat_number: res.user.user_info.billing_info.piva,
          company_sdi: res.user.user_info.billing_info.sdi,
          pec: res.user.user_info.billing_info.pec,
          name: res.user.user_info.legal_owner_info.name,
          surname: res.user.user_info.legal_owner_info.surname,
        });
        setSelectedUser(res.user);
      }
    });
  }, []);

  const handleSubmit = useCallback(() => {
    ApiUpdateUser({
      user_id: selectedUser?.uid || "",
      name: values.name,
      //DATI UTENZA
      user_info: {
        phone: values.company_phone,
        address: values.address,
        city: values.city,
        province: values.province,
        country: values.country,
        zip_code: values.zip_code,
        //DATI FATTURAZIONE
        billing_info: {
          soc_reason: values.subscription_name,
          address: values.company_address,
          city: values.company_city,
          province: values.company_province,
          state: values.company_country,
          zip_code: values.company_zip_code,
          piva: values.company_vat_number,
          sdi: values.company_sdi,
          pec: values.pec,
        },
        //TITOLARE LEGALE
        legal_owner_info: {
          name: values.name,
          surname: values.surname,
        },
      },
    }).then((res) => {
      if (res && res.result && res.result === "Utente aggiornato") {
        setStatus("success");
      } else {
        setStatus(res?.err?.message || "");
      }
    });
  }, [selectedUser, values]);

  if (!selectedUser) {
    return null;
  }

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"update"}
        entity={
          selectedUser.roles.includes(installerRole) ? "installer" : "user"
        }
        error={status}
      />
    );
  }

  return (
    <>
      <TitleWithBack
        title={
          selectedUser.roles.includes(installerRole)
            ? t("editInstaller")
            : t("editCustomer")
        }
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">{t("installerData")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {installerDataFields(t).map((el) =>
                    addField(el, values, setValues)
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="h6">{t("businessName")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {businessNameFields(t).map((el) =>
                    addField(el, values, setValues)
                  )}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ButtonConfItem
                      buttonLabel={t("reset")}
                      buttonOnConfirm={() =>
                        setValues({
                          company_phone: "",
                          address: "",
                          city: "",
                          province: "",
                          country: "",
                          zip_code: "",
                          subscription_name: "",
                          company_address: "",
                          company_city: "",
                          company_province: "",
                          company_country: "",
                          company_zip_code: "",
                          company_vat_number: "",
                          company_sdi: "",
                          pec: "",
                          name: "",
                          surname: "",
                        })
                      }
                      questionLabel={t("question")}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("ok")}
                      buttonOnClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateInstaller;
