import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { dateType, stringType } from "../../../utilities/utilities";
import {
  deactivationLabel,
  activationLabel,
  disabledLabel,
  primaryType,
  tagType,
  status,
} from "../../../costants/costants";
import { Button } from "@mui/material";
import ButtonConfItem from "../../custom/ButtonConfItem";

export const tableColumn: (
  t: any,
  rowData: Object[],
  SetPopupObject: (obj: any) => void,
  manageSubscription: (
    subscription_id: string,
    requested_status: string
  ) => void
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  manageSubscription
) => [
  {
    label: t("installation"),
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("type"),
    name: "type",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("description"),
    name: "description",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: "address",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("city"),
    name: "city",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("expiration"),
    name: "expiry_date",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
  {
    label: t("status"),
    name: "status",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell
          type={tagType}
          value={value === "enabled" ? t("enabled") : t("disabled")}
          color={value === "enabled" ? "success" : "error"}
        />
      ),
    },
  },
  {
    label: t("action"),
    name: status.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        const status: string = value;
        const requestedStatus: string =
          data.tableData[data.rowIndex].requested_status;
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {requestedStatus === activationLabel && status !== "enabled" ? (
              <Button fullWidth variant="outlined">
                {t("activationProgress")}
              </Button>
            ) : requestedStatus === deactivationLabel &&
              status !== "disabled" ? (
              <Button fullWidth variant="outlined">
                {t("deactivationProgress")}
              </Button>
            ) : status === disabledLabel ? (
              <ButtonConfItem
                fullWidth
                buttonType={primaryType}
                buttonLabel={t("requestActivation")}
                buttonOnConfirm={() => {
                  manageSubscription(
                    data.tableData[data.rowIndex].id,
                    activationLabel
                  );
                }}
              />
            ) : (
              <ButtonConfItem
                fullWidth
                buttonType={primaryType}
                buttonLabel={t("requestDeactivation")}
                buttonOnConfirm={() => {
                  manageSubscription(
                    data.tableData[data.rowIndex].id,
                    deactivationLabel
                  );
                }}
              />
            )}
          </div>
        );
      },
    },
  },
];
