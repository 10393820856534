import React, { useEffect, useState } from "react";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import FormInput from "../../custom/input/FormInput";
import {
  Installation,
  JobRequest,
} from "../../../api/requests/installationsService";
import OperationResult from "../../custom/OperationResult";
import { radioType } from "../../../utilities/utilities";
import {
  getInstallation,
  getJob,
  setJob,
} from "../../../api/services/installationsService";
import {
  calibrationLabel,
  divKey,
  errorStatus,
  formInputKey,
  htmlForName,
  labelKey,
  neverDone,
  primaryType,
  signalConfigurationBtnDivKey,
  signalConfigurationDivKey,
  signalConfigurationTitleKey,
  successStatus,
} from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { modelJob } from "./costant";
import { Divider, FormControlLabel, Grid, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

type SignalConfigurationProps = {
  installation_id: string;
};

const SignalConfiguration: React.FC<SignalConfigurationProps> = ({
  installation_id,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [installation, setInstallation] = useState<Installation | null>(null);
  const [status, setStatus] = useState<string>("");
  const [initialJob, setInitialJob] = useState({
    config: {
      pwr_ratio: "", //consumo potenza
      pwr_rbias: "", //consumo potenza
      pwr_threshold: "", //consumo potenza
      spd_en: "", //velocita volano
      spd_min: "", //velocita volano
      spd_max: "", //velocita volano
      drag_en: "", //velocita trascinamento
      am_en: "", //automatico manuale
    },
    installation_id: "",
    last_set_status: neverDone,
  });
  const [disabled, setDisabled] = useState<boolean[]>([
    true,
    false,
    false,
    false,
  ]);
  useEffect(() => {
    getJob(installation_id, calibrationLabel).then((res: any) => {
      if (res && res.calibration) {
        setInitialJob(res.calibration);
      }
      getInstallation({ id: installation_id }).then((res: any) => {
        if (res && res.installation) {
          setInstallation(res.installation);
        }
        setLoading(false);
      });
    });
  }, [installation_id]);

  const goBack = () => setStatus("");

  const [values, setValues] = useState({ room_temperature: "" });
  const submitJob = () => {
    const request: JobRequest = {
      config: {
        temp_en: values.room_temperature,
        /*
                        pos_en: values[positioningTilt.name],
                        gps_en: values[gpsPosition.name],
                        batt_en: values[batteryVoltage.name],
                        solar_en: values[solarPanelVoltage.name],
                        cw_en: values[clearWaterLevel.name],
                        cw_max: Number(values[clearWaterLevelMax.name]),
                        cw_min: Number(values[clearWaterLevelMin.name]),
        s       dw_en: values[darkWaterLevel.name],
                        dw_max: Number(values[darkWaterLevelMax.name]),
                        dw_min: Number(values[darkWaterLevelMin.name]),
                        door1_en: values[`first_${doorOpenStatus.name}`],
                        pir1_en: values[`first_${interiorLightsStatus.name}`],
                        led1_en: values[`first_${ledFlushStatus.name}`],
                        flush1_en: values[`first_${flushButton.name}`],
                        sink1_en: values[`first_${handWashButton.name}`],
                        door2_en: values[`second_${doorOpenStatus.name}`],
                        pir2_en: values[`second_${interiorLightsStatus.name}`],
                        led2_en: values[`second_${ledFlushStatus.name}`],
                        flush2_en: values[`second_${flushButton.name}`],
                        sink2_en: values[`second_${handWashButton.name}`],
                        */
      },
    };
    setJob(installation_id, calibrationLabel, request).then((res) => {
      if (res && !res.err) {
        setStatus("success");
      } else {
        setStatus(res?.err?.message || "");
      }
    });
  };

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"create"}
        entity={"signal"}
        error={status}
      />
    );
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        title={t("signalConfiguration")}
        key={signalConfigurationTitleKey}
      />
      <div
        className="my-signalconfiguration-container my-signalconfiguration-container-responsive"
        key={signalConfigurationDivKey}
      >
        <Grid container>
          {modelJob(installation?.model || "", initialJob?.config || {}).map(
            (item: any, index: number) => {
              if (item.divider) {
                return <Divider>{item.divider}</Divider>;
              } else {
                if (item.type === radioType) {
                  return (
                    <Grid item xs={12} md={8} key={index}>
                      <div className="form__group field" key={divKey(item.key)}>
                        <label
                          key={labelKey(item.key)}
                          htmlFor={htmlForName}
                          className="form__label"
                        >
                          {item.label}
                        </label>
                        <FormControlLabel
                          name={item.name}
                          control={<Switch defaultChecked={item.value} />}
                          label={
                            item.value ? (
                              <h3 style={{ color: "#fde6c0" }}> Enabled </h3>
                            ) : (
                              <h3 style={{ color: "#fde6c0" }}> Disabled </h3>
                            )
                          }
                          onChange={(e: any) => {
                            setValues({
                              ...values,
                              [item.name]: e.target.value,
                            });
                            if (item.disabled) {
                              let tmp_disabled = disabled;
                              tmp_disabled[item.disabled] = e;
                              setDisabled([...tmp_disabled]);
                            }
                          }}
                        />
                      </div>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} md={8} key={index}>
                      <FormInput
                        key={formInputKey(item.key)}
                        name={item.name}
                        placeholder={item.label}
                        type={item.type}
                        value={item.value}
                        keyValue={item.key}
                        rules={
                          item.disabled
                            ? !disabled[item.disabled]
                              ? undefined
                              : item.rules
                            : undefined
                        }
                        disabled={
                          item.disabled ? !disabled[item.disabled] : undefined
                        }
                      />
                    </Grid>
                  );
                }
              }
            }
          )}
        </Grid>
        <div className="btn-container" key={signalConfigurationBtnDivKey}>
          <ButtonConfItem
            buttonLabel={t("reset")}
            buttonOnConfirm={() => {
              setValues({ room_temperature: "" });
            }}
            questionLabel={t("question")}
          />
          <ButtonItem
            buttonType={primaryType}
            label={t("ok")}
            buttonOnClick={submitJob}
          />
        </div>
      </div>
    </>
  );
};

export default SignalConfiguration;
