import ButtonItem from "../../custom/ButtonItem";
import { inputs } from "./inputs";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { resetPassword } from "../../../api/services/loginService";
import { loginPageUrl, resetPasswordPageUrlResult } from "../costants";
import logo from "../../../images/logo-nurset.svg";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { primaryType } from "../../../costants/costants";

type ResetPanelProps = {};

const ResetPassword: React.FC<ResetPanelProps> = () => {
  const { t } = useTranslation();
  document.body.style.backgroundColor = "#4c8c2c";

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [email, setEmail] = useState<string>("");

  const history = useHistory();
  const submit = () => {
    resetPassword({ email: email }).then((res) => {
      if (res && !res.err) {
        history.push(resetPasswordPageUrlResult);
      }
    });
  };

  const StyledTextField = {
    "& label": {
      color: "#5c4424",
    },
    "& label.Mui-focused": {
      color: "#5c4424",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5c4424",
      color: "#5c4424",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
      },
      "&:hover fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
      },
      "& .MuiInputBase-input": { borderColor: "#5c4424", color: "#5c4424" },
    },
  };

  return (
    <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
      <div className="image-container">
        <img
          src={logo}
          className={width <= 768 ? "image-responsive" : "image"}
          alt="logo-nurset"
        />
      </div>
      {inputs(t).map((el) => {
        return (
          <TextField
            inputProps={{ "data-testid": el.key }}
            type={el.type}
            size="small"
            id={el.key}
            name={el.name}
            label={el.label}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            required
            fullWidth
            sx={StyledTextField}
            style={{ marginTop: "32px" }}
          />
        );
      })}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "82px",
        }}
      >
        <ButtonItem
          buttonType={primaryType}
          label={t("reset")}
          buttonOnClick={submit}
        />
        <Link className="login-form-forgot" to={loginPageUrl}>
          {t("backToLogin")}
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
