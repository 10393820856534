import ButtonItem from "../../custom/ButtonItem";
import { inputs } from "./inputs";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { login } from "../../../api/services/loginService";
import { LoginRequest } from "../../../api/requests/loginService";
import { routes } from "../costants";
import logo from "../../../images/logo-nurset.svg";
import { primaryType, successStatusKey } from "../../../costants/costants";
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const LoginPanel: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  document.body.style.backgroundColor = "#4c8c2c";

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const setChange = (e: any, index: number) => {
    if (index === 0) {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const checkLogin = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    login({ email: email, password: password } as LoginRequest)
      .then((res: any) => {
        if (res && res.status && res.status === successStatusKey) {
          history.push("/");
        } else {
          setError(true);
          enqueueSnackbar(res?.error?.message || t("incorrectCredentials"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((e) => {
        setError(true);
        enqueueSnackbar(t("incorrectCredentials"), {
          variant: "error",
          preventDuplicate: true,
        });
        console.log(e);
      });
  };

  const StyledTextField = {
    "& label": {
      color: "#5c4424",
    },
    "& label.Mui-focused": {
      color: "#5c4424",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5c4424",
      color: "#5c4424",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
      },
      "&:hover fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5c4424",
        color: "#5c4424",
      },
      "& .MuiInputBase-input": { borderColor: "#5c4424", color: "#5c4424" },
    },
  };

  return (
    <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
      <div className="image-container">
        <img
          src={logo}
          className={width <= 768 ? "image-responsive" : "image"}
          alt="logo-nurset"
        />
      </div>
      <form
        onSubmit={checkLogin}
        style={{
          height: "40%",
          display: "flex",
          marginTop: "32px",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {inputs(t).map((el, index) => {
          return (
            <TextField
              inputProps={{ "data-testid": el.key }}
              error={error}
              type={el.type}
              size="small"
              id={el.key}
              name={el.name}
              label={el.label}
              onChange={(e) => {
                setChange(e, index);
                setError(false);
              }}
              variant="outlined"
              required
              sx={StyledTextField}
              fullWidth
            />
          );
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ButtonItem
            label={t("login")}
            buttonType={primaryType}
            buttonOnClick={checkLogin}
          />
          <Link className="login-form-forgot" to={routes.recoverStart}>
            {t("resetPassword")}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginPanel;
