import { changeStatus } from "../../../api/services/subscriptionService";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { tableColumn } from "./tableColumn";
import { ChangeStatusRequest } from "../../../api/requests/subscription";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetAllInstallations } from "../../../api/services/installationsService";

type SubscriptionManagementProps = {
  SetPopupObject: (obj: any) => void;
};

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({
  SetPopupObject,
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState<Object[]>([]);
  const manageSubscription = (
    subscription_id: string,
    requested_status: string
  ) => {
    const request: ChangeStatusRequest = {
      subscription_id: subscription_id,
      requested_status: requested_status,
    };
    changeStatus(request).then((res) => {
      if (res && res.subscription) {
        window.location.reload();
      }
    });
  };

  return (
    <div style={{ paddingBottom: "5%" }}>
      <ResponsiveTable
        columns={tableColumn(t, data, SetPopupObject, manageSubscription)}
        getPageAndSortedData={GetAllInstallations}
        defaultSort="name"
        responseDataName="subscriptions"
        setData={setData}
      />
    </div>
  );
};

export default SubscriptionManagement;
