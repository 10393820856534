import { installersListColumns } from "./inputs";
import React, { useState } from "react";
import { GetInstallers } from "../../../api/services/userService";
import CustomTitle from "../../custom/CustomTitle";
import { useHistory } from "react-router";
import { updateInstallerPageUrl } from "../costants";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { installersKey, name } from "../../../costants/costants";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

type InstallerTableProps = {
  SetPopupObject: (obj: any) => void;
};

const InstallersListTable: React.FC<InstallerTableProps> = ({
  SetPopupObject,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<Object[]>([]);
  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) => {
    history.push(updateInstallerPageUrl(value));
  };

  return (
    <>
      <CustomTitle title={t("editInstaller")} />
      <ResponsiveTable
        columns={installersListColumns(
          t,
          data,
          SetPopupObject,
          linkFunction,
          enqueueSnackbar
        )}
        getPageAndSortedData={GetInstallers}
        defaultSort={name.name}
        responseDataName={installersKey}
        setData={setData}
      />
    </>
  );
};

export default InstallersListTable;
