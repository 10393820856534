import { installationFields } from "./inputs";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import React, { useEffect, useState } from "react";
import { UpdateInstallation as updateInstallationApi } from "../../../api/services/installationsService";
import OperationResult from "../../custom/OperationResult";
import {
  errorStatus,
  formInputKey,
  primaryType,
  updateInstallationTitleKey,
} from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { addField, checkValues } from "../../../utilities/utilities";
import DeviceCard from "./DeviceCard";
import { useHistory } from "react-router";
import { deviceConnectionConfigurationUrl } from "../costants";
import { getAllDevices } from "../../../api/services/deviceService";
import { getSubscription } from "../../../api/services/subscriptionService";
import FormSelect from "../../custom/select/FormSelect";
import CustomLoading from "../../custom/CustomLoading";

type UpdateInstallationProps = {
  installationId: string;
};

const UpdateInstallation: React.FC<UpdateInstallationProps> = ({
  installationId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [status, setStatus] = useState<string>("");
  const goBack = () => setStatus("");

  const [values, setValues] = useState({
    name: "",
    description: "",
    address: "",
    city: "",
  });

  const [irrigation, setIrrigation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);
  useEffect(() => {
    getSubscription({ subscription_id: installationId }).then((res: any) => {
      if (res && res.subscription && res.subscription) {
        setValues({
          name: res.subscription?.name || "",
          description: res.subscription?.description || "",
          address: res.subscription?.address || "",
          city: res.subscription?.city || "",
        });
      }
    });
    getAllDevices().then((res) => {
      if (res && res.installations) {
        setDevices(res.installations);
        const tmp: any[] = res.installations.filter(
          (d: any) => d.subscription_id === installationId
        );
        const plant: boolean =
          tmp.filter((d: any) => d.model === "plant").length > 0;
        const actuator: boolean =
          tmp.filter((d: any) => d.model === "actuator").length > 0;
        setIrrigation(plant && actuator);
      }
      setLoading(false);
    });
  }, []);

  const submit = () => {
    if (checkValues(values)) {
      updateInstallationApi({
        id: installationId,
        name: values.name,
        description: values.description,
        address: values.address,
        city: values.city,
      }).then((res) => {
        if (res && !res.err) {
          setStatus("success");
        } else {
          setStatus(res?.err?.message || t("somethingWentWrong"));
        }
      });
    }
  };

  const [search, setSearch] = useState<string>("all");

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation="update"
        entity="installation"
        error={status}
      />
    );
  }
  return (
    <>
      <TitleWithBack title={t("editDevice")} key={updateInstallationTitleKey} />
      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Grid item xs={12} md={8} style={{ marginBottom: "32px" }}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item container spacing={2}>
                  {installationFields(t).map((el: any) =>
                    addField(el, values, setValues)
                  )}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ButtonConfItem
                      buttonLabel={t("reset")}
                      buttonOnConfirm={() => {
                        setValues({
                          name: "",
                          description: "",
                          address: "",
                          city: "",
                        });
                      }}
                      questionLabel={t("question")}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("ok")}
                      buttonOnClick={submit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/*
        TBD: da implementare la parte dei dispositivi
        */}
        <Grid item container xs={12} md={12} spacing={2}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="h6">{t("devices")}</Typography>
            </Grid>
            <Grid item container spacing={2} xs={9} justifyContent="flex-end">
              <Grid item xs={4}>
                <FormSelect
                  key={formInputKey("type")}
                  placeholder={t("type")}
                  keyValue="type"
                  name="type"
                  options={[
                    { all: t("all") },
                    { plant: t("plant") },
                    { actuator: t("actuator") },
                    { pump: t("pump") },
                  ]}
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </Grid>
              <Grid item>
                <ButtonItem
                  buttonType={primaryType}
                  label={t("add")}
                  buttonOnClick={() =>
                    history.push(
                      deviceConnectionConfigurationUrl(installationId)
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {loading ? (
            <Grid item xs={12}>
              <CustomLoading />
            </Grid>
          ) : (
            devices
              .filter((d) => d.subscription_id === installationId)
              .filter((d) => {
                if (search === "all") {
                  return true;
                }
                if (search === "plant") {
                  return d.model === "plant";
                }
                if (search === "actuator") {
                  return d.model === "actuator";
                }
                if (search === "pump") {
                  return d.model === "pump";
                }
                return false;
              })
              .map((d: any) => {
                return (
                  <Grid item xs={12} md={4}>
                    <DeviceCard
                      device={d}
                      installationId={d.id}
                      irrigation={irrigation}
                    />
                  </Grid>
                );
              })
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateInstallation;
