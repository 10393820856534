import React, { useEffect, useState } from "react";
import { GetUserProfile } from "../../../api/services/userService";
import { useHistory } from "react-router-dom";
import { roles } from "../costants";
import SubscriptionManagement from "../subscriptionSManagement/SubscriptionManagement";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";

type ProfileProps = {
  SetPopupObject: (objects: Object[]) => void;
};

const Profile: React.FC<ProfileProps> = ({ SetPopupObject }) => {
  const { t } = useTranslation();

  const [profileItem, setProfileItem] = useState<any>({
    role: "",
    user_info: {
      email: "---",
      address: "---",
      city: "---",
      province: "---",
      country: "---",
      phone: "---",
    },
    billing_info: {
      name: "---",
      address: "---",
      city: "---",
      province: "---",
      country: "---",
      vat: "---",
    },
  });

  const history = useHistory();
  useEffect(() => {
    GetUserProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        setProfileItem({
          role: res.user.roles[0],
          user_info: {
            email: res.user?.email || "---",
            address: res.user?.user_info?.address || "---",
            city: res.user?.user_info?.city || "---",
            province: res.user?.user_info?.province || "---",
            country: res.user?.user_info?.country || "---",
            phone: res.user?.user_info?.phone || "---",
          },
          billing_info: {
            name: res.user?.user_info?.billing_info?.soc_reason || "---",
            address: res.user?.user_info?.billing_info?.address || "---",
            city: res.user?.user_info?.billing_info?.city || "---",
            province: res.user?.user_info?.billing_info?.province || "---",
            country: res.user?.user_info?.billing_info?.state || "---",
            vat: res.user?.user_info?.billing_info?.piva || "---",
          },
        });
      } else {
        history.push("/");
      }
    });
  }, []);

  return (
    <>
      <div className="profileDescriptionContainer">
        <Card
          variant="outlined"
          className={"profileDescription profileDescriptionResponsive"}
        >
          <CardHeader title={t("profile")} />
          <CardContent sx={{ display: "flex", justifyContent: "space-around" }}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} md={5}>
                <Typography variant="h6">{t("userData")}</Typography>
                <List>
                  <ListItem
                    secondaryAction={
                      <Typography variant="subtitle2">
                        {profileItem.user_info.email}
                      </Typography>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {t("email")}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <Typography variant="subtitle2">
                        {profileItem.user_info.address}
                      </Typography>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {t("address")}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <Typography variant="subtitle2">
                        {profileItem.user_info.city}
                      </Typography>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">{t("city")}</Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <Typography variant="subtitle2">
                        {profileItem.user_info.province}
                      </Typography>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {t("province")}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <Typography variant="subtitle2">
                        {profileItem.user_info.country}
                      </Typography>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {t("country")}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <Typography variant="subtitle2">
                        {profileItem.user_info.phone}
                      </Typography>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {t("phone")}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={12} md={5}>
                <Typography variant="h6">{t("businessName")}</Typography>
                <List disablePadding>
                  <List>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {profileItem.billing_info.name}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("name")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {profileItem.billing_info.address}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("address")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {profileItem.billing_info.city}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("city")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {profileItem.billing_info.province}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("province")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {profileItem.billing_info.country}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("country")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {profileItem.billing_info.vat}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("vat")}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      {String(profileItem.role) === String(roles.customer) ? (
        <>
          <Typography variant="subtitle1" style={{ textAlign: "center" }}>
            {t("installationsManagement")}
          </Typography>
          <SubscriptionManagement SetPopupObject={SetPopupObject} />
          <Typography variant="subtitle1" style={{ textAlign: "center" }}>
            {t("irrigationNotification")}
          </Typography>
          <Notifications />
        </>
      ) : null}
    </>
  );
};

export default Profile;
