import { InstallationsServiceUrl } from "../constants";
import { CreateDeviceRequest } from "../requests/deviceService";
import { methodGet, methodPost, verticalFetch } from "./httpRequests";

export const createDevice = (r: CreateDeviceRequest) => {
  return verticalFetch(
    methodPost,
    InstallationsServiceUrl,
    JSON.stringify({ ...r, phone_operator: "iot.secure" })
  );
};

export const getAllDevices = () => {
  return verticalFetch(methodGet, InstallationsServiceUrl);
};

export const getIrrigationValues = (device_id: string) => {
  return verticalFetch(
    methodGet,
    `${InstallationsServiceUrl}/irrigation/getIrrigationParameters/${device_id}`
  );
};
