import { MenuType } from "../types/types";
import {
  alarmsPageUrl,
  campaignPageUrl,
  irrigationConfigurationCustomerUrl,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
} from "../pages/costants";
import {
  alarmsKey,
  campaignKey,
  customerKey,
  dataHistoryKey,
  dataRealTimeKey,
  historyKey,
  installerRole,
} from "../../costants/costants";
import realTimeData from "../pages/homePages/img/graph.png";
import alarms from "../pages/homePages/img/alert.png";
import dataHistory from "../pages/homePages/img/fleet.png";
import campaignNotebook from "../pages/homePages/img/book.png";
import configurator from "../pages/homePages/img/inst.png";

export const customerMenuItems: (t: any) => MenuType[] = (t: any) => [
  // TODO: remove first element, it is necessary because the first element is not shown
  {
    key: "",
    label: "",
    elements: [],
    link: "",
  },
  {
    key: customerKey,
    label: t("realTimeData"),
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
    icon: (
      <img src={realTimeData} alt={dataRealTimeKey} width={25} height={25} />
    ),
  },
  {
    key: historyKey,
    label: t("dataHistory"),
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
    icon: <img src={dataHistory} alt={dataHistoryKey} width={25} height={25} />,
  },
  {
    key: alarmsKey,
    label: t("alarms"),
    link: alarmsPageUrl,
    elements: [],
    icon: <img src={alarms} alt={alarmsKey} width={25} height={25} />,
  },
  {
    key: campaignKey,
    label: t("campaignNotebook"),
    link: campaignPageUrl,
    elements: [],
    icon: (
      <img src={campaignNotebook} alt={campaignKey} width={25} height={25} />
    ),
  },
  {
    key: installerRole,
    label: t("irrigation"),
    elements: [],
    link: irrigationConfigurationCustomerUrl,
    icon: <img src={configurator} alt={installerRole} width={25} height={25} />,
  },
];
