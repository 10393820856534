import { InputType } from "../../types/types";
import { email, password, passwordType } from "../../../costants/costants";
import { Lock, Person } from "@mui/icons-material";

export const inputs: (t: any) => InputType[] = (t: any) => [
  {
    key: email.key,
    name: email.name,
    label: t("email"),
    icon: <Person className="site-form-item-icon" />,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: password.key,
    name: password.name,
    label: t("password"),
    type: passwordType,
    icon: <Lock className="site-form-item-icon" />,
    rules: { required: true, message: t("requiredField") },
  },
];
