import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Alert,
} from "@mui/material";
import { Installation } from "../../../../api/requests/installationsService";
import { GetAlarms } from "../../../../api/services/installationsService";
import {
  alarmLabel,
  localeDateTimeFormatter,
} from "../../../../utilities/utilities";
import { useHistory } from "react-router";
import { alarmsPageUrl } from "../../costants";
import { WarningAmberOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type AlarmSummaryProps = {
  installation: Installation | null;
};

type Alarm = {
  installation_id: string;
  tag: string;
  level: string;
  info: {};
  start: string;
  finish: string;
};

const AlarmSummary: React.FC<AlarmSummaryProps> = ({ installation }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [alarms, setAlarms] = useState<Alarm[]>([]);
  useEffect(() => {
    const d = new Date();
    const start = new Date(new Date().setDate(d.getDate() - 1)).toISOString();
    const end = d.toISOString();
    if (installation) {
      GetAlarms(installation.id, start, end).then((res) => {
        if (res && res.alarms) {
          setAlarms(res.alarms);
        } else {
          setAlarms([]);
        }
      });
    }
  }, [installation]);

  const switchAlarm = (a: Alarm) => {
    switch (a.tag.toLocaleLowerCase()) {
      case "batteria":
        return {
          message: `${t("battery")} ${t("from")} ${localeDateTimeFormatter(
            a.start
          )}`,
          type: "warning" as "warning",
        };
      case "primo tagliando":
        return {
          message: `${t("firstService")} ${t("from")} ${localeDateTimeFormatter(
            a.start
          )}`,
          type: "warning" as "warning",
        };
      case "temperatura alta":
        return {
          message: `${t("highTemperature")} ${t(
            "from"
          )} ${localeDateTimeFormatter(a.start)}`,
          type: "warning" as "warning",
        };
      case "temperatura bassa":
        return {
          message: `${t("lowTemperature")} ${t(
            "from"
          )} ${localeDateTimeFormatter(a.start)}`,
          type: "warning" as "warning",
        };
      default:
        return {
          message: `${String(a.tag)} ${t("from")} ${localeDateTimeFormatter(
            a.start
          )}`,
          type: a.level,
        };
    }
  };

  return (
    <Card
      variant="outlined"
      style={{ minHeight: "290px", maxHeight: "290px", overflow: "scroll" }}
    >
      <CardHeader
        style={{ maxHeight: "4vh" }}
        avatar={
          <WarningAmberOutlined
            style={{ cursor: "pointer", marginTop: "20px" }}
            onClick={() => {
              history.push(alarmsPageUrl);
            }}
          />
        }
        title={
          <Typography variant="h5" className="cardTitle">
            {t("last24Alarms")}
          </Typography>
        }
      />
      <CardContent>
        <div key="container_div_3">
          {alarms.map((a: Alarm, i: number) => {
            const alarmResult = alarmLabel(a.tag.toLocaleLowerCase());
            if (alarmResult) {
              return (
                <div key={i}>
                  <Alert severity="warning">{`${alarmResult} ${t(
                    "from"
                  )} ${localeDateTimeFormatter(a.start)} ${
                    a.finish ? t("to") : ""
                  } ${
                    a.finish ? localeDateTimeFormatter(a.finish) : ""
                  }`}</Alert>
                </div>
              );
            }
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default AlarmSummary;
