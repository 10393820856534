import { MenuType, SubMenuType } from "../types/types";
import {
  adminPanelIconsUrl,
  alarmsPageUrl,
  campaignPageUrl,
  createInstallerPageUrl,
  installersAssociationsUrl,
  installersListPageUrl,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  subscriptionsListToUpdateUrl,
} from "../pages/costants";
import {
  adminRole,
  alarmsKey,
  campaignKey,
  createInstallerKey,
  dataHistoryKey,
  dataRealTimeKey,
  installerAssociationsKey,
  updateInstallerKey,
  updateSubscriptionKey,
} from "../../costants/costants";
import realTimeData from "../pages/homePages/img/graph.png";
import alarms from "../pages/homePages/img/alert.png";
import dataHistory from "../pages/homePages/img/fleet.png";
import campaignNotebook from "../pages/homePages/img/book.png";
import admin from "../pages/homePages/img/admin.png";
import { ManageAccounts, Person, Sync, ToggleOn } from "@mui/icons-material";

const adminItem: (t: any) => SubMenuType[] = (t: any) => [
  {
    key: createInstallerKey,
    label: t("createInstaller"),
    link: createInstallerPageUrl,
    elements: [],
    icon: <Person style={{ color: "#5c4424" }} />,
  },
  {
    key: updateInstallerKey,
    label: t("editInstaller"),
    link: installersListPageUrl,
    elements: [],
    icon: <ManageAccounts style={{ color: "#5c4424" }} />,
  },
  {
    key: installerAssociationsKey,
    label: t("installersAssociations"),
    link: installersAssociationsUrl,
    elements: [],
    icon: <Sync style={{ color: "#5c4424" }} />,
  },
  {
    key: updateSubscriptionKey,
    label: t("installationsManagement"),
    link: subscriptionsListToUpdateUrl,
    elements: [],
    icon: <ToggleOn style={{ color: "#5c4424" }} />,
  },
];

export const adminMenuItems: (t: any) => MenuType[] = (t: any) => [
  // TODO: remove first element, it is necessary because the first element is not shown
  {
    key: "",
    label: "",
    elements: [],
    link: "",
  },
  {
    key: dataRealTimeKey,
    label: t("realTimeData"),
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
    icon: (
      <img src={realTimeData} alt={dataRealTimeKey} width={25} height={25} />
    ),
  },
  {
    key: dataHistoryKey,
    label: t("dataHistory"),
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
    icon: <img src={dataHistory} alt={dataHistoryKey} width={25} height={25} />,
  },
  {
    key: alarmsKey,
    label: t("alarms"),
    link: alarmsPageUrl,
    elements: [],
    icon: <img src={alarms} alt={alarmsKey} width={25} height={25} />,
  },
  {
    key: campaignKey,
    label: t("campaignNotebook"),
    link: campaignPageUrl,
    elements: [],
    icon: (
      <img src={campaignNotebook} alt={campaignKey} width={25} height={25} />
    ),
  },
  {
    key: adminRole,
    label: t("adminPanel"),
    link: adminPanelIconsUrl,
    elements: adminItem(t),
    icon: <img src={admin} alt={adminRole} width={25} height={25} />,
  },
];
