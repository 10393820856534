import { BrowserRouter, Switch, Route } from "react-router-dom";
import { routes } from "./components/pages/costants";
import RecoverPassword from "./components/pages/recoverPassword/RecoverPassword";
import ResultResetPassword from "./components/pages/resetPassword/Result";
import ResultRecoverPassword from "./components/pages/recoverPassword/Result";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginPanel from "./components/pages/form_login/LoginPanel";
import MainPage from "./components/pages/mainPage";
import ResetPassword from "./components/pages/resetPassword/resetPassword";
import { CssBaseline } from "@mui/material/";
import { SnackbarProvider } from "notistack";

declare global {
  interface Window {
    _env_: any;
    api: any;
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: "#5c442412",
      paper: "white",
    },
    mode: "light",
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: "#5c4424",
          color: "#5c4424",
          fontFamily: "Montserrat, sans-serif",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#5c4424",
          fontFamily: "Montserrat, sans-serif",
        },
        focused: {
          color: "#5c4424",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#5c4424",
          fontFamily: "Montserrat, sans-serif",
        },
        focused: {
          color: "#5c4424",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundImage: "none",
          color: "#455a64",
          fontFamily: "Montserrat, sans-serif",
        },
        rounded: {
          //  border: "solid #5c4424",
          borderRadius: 5,
          boxShadow: "none",
          backgroundImage: "none",
          fontFamily: "Montserrat, sans-serif",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: { border: "none", fontFamily: "Montserrat, sans-serif" },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: { color: "#5c4424", fontFamily: "Montserrat, sans-serif" },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: "#5c4424",
          color: "#5c4424",
          fontFamily: "Montserrat, sans-serif",
        },
        focused: {
          borderColor: "#5c4424",
          color: "#5c4424",
          fontFamily: "Montserrat, sans-serif",
        },
        colorSecondary: "#5c4424",
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#455a64",
          fontFamily: "Montserrat, sans-serif",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          color: "#455a64",
          fontFamily: "Montserrat, sans-serif",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#455a64",
          fontFamily: "Montserrat, sans-serif",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: "none",
          fontFamily: "Montserrat, sans-serif",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        footer: {
          border: "none",
          fontFamily: "Montserrat, sans-serif",
        },
        head: {
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: "Montserrat, sans-serif",
        },
        root: {
          fontSize: "20px",
          fontFamily: "Montserrat, sans-serif",
        },
        stickyHeader: {
          fontWeight: "bold",
          fontFamily: "Montserrat, sans-serif",
        },
      },
      variants: [
        {
          props: { variant: "head" },
          style: {
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
          },
        },
      ],
    },
  },
});

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route exact path={routes.login} component={LoginPanel} />
            <Route exact path={routes.recoverStart} component={ResetPassword} />
            <Route exact path={routes.recoverEnd} component={RecoverPassword} />
            <Route
              exact
              path={routes.resetResult}
              component={ResultResetPassword}
            />
            <Route
              exact
              path={routes.recoverResult}
              component={ResultRecoverPassword}
            />
            <Route component={MainPage} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
