import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../custom/table/responsiveTable";
import { linkType, stringType } from "../../../utilities/utilities";
import { address, city, name, userInfo } from "../../../costants/costants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";

export const installersListColumns: (
  t: any,
  rowData: any,
  SetPopupObject: (obj: any) => void,
  linkFunction: (value: string) => void,
  enqueueSnackbar: any
) => ResponsiveTableColumnType[] = (
  t,
  rowData,
  SetPopupObject,
  linkFunction,
  enqueueSnackbar
) => [
  {
    label: "",
    name: "",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return (
          <div className="details-container">
            <Tooltip title={t("details")}>
              <IconButton
                onClick={() => {
                  SetPopupObject([
                    {
                      name: rowData[data.rowIndex]?.name || "",
                      email: rowData[data.rowIndex]?.email || "",
                      role: rowData[data.rowIndex]?.roles || "",
                      phone: rowData[data.rowIndex]?.user_info?.phone || "",
                      address: rowData[data.rowIndex]?.user_info?.address || "",
                      city: rowData[data.rowIndex]?.user_info?.city || "",
                      province:
                        rowData[data.rowIndex]?.user_info?.province || "",
                      country: rowData[data.rowIndex]?.user_info?.country || "",
                      zip_code:
                        rowData[data.rowIndex]?.user_info?.zip_code || "",
                      business_name:
                        rowData[data.rowIndex]?.user_info?.billing_info
                          ?.soc_reason || "",
                      vat:
                        rowData[data.rowIndex]?.user_info?.billing_info?.piva ||
                        "",
                      sdi:
                        rowData[data.rowIndex]?.user_info?.billing_info?.sdi ||
                        "",
                      pec:
                        rowData[data.rowIndex]?.user_info?.billing_info?.pec ||
                        "",
                      created_at: rowData[data.rowIndex]?.created_at || "",
                    },
                  ]);
                }}
              >
                <InfoOutlinedIcon htmlColor="#455a64" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  },
  {
    label: t("installer"),
    name: name.name,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return (
          <ResponsiveTableCell
            type={linkType}
            value={value}
            link={() => linkFunction(data.tableData[data.rowIndex].uid)}
          />
        );
      },
    },
  },
  {
    label: t("role"),
    name: "roles",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string[]) => (
        <ResponsiveTableCell type={stringType} value={value.join(", ")} />
      ),
    },
  },
  {
    label: t("businessName"),
    name: `${userInfo}.billing_info.soc_reason`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("address"),
    name: `${userInfo}.${address.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("city"),
    name: `${userInfo}.${city.name}`,
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
];
