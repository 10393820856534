import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { dccTitleKey, primaryType } from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { binaryFetch, methodGet } from "../../../api/services/httpRequests";
import { BackendUrl } from "../../../api/constants";
import { ESPLoader } from "../../../misc/esp-web-flasher/esp_loader";
import { Error, UsbOff } from "@mui/icons-material";
import ButtonItem from "../../custom/ButtonItem";
import { testingUrl } from "../costants";

type LoadTestProps = {
  installation_id: string;
};

const LoadTest: React.FC<LoadTestProps> = ({ installation_id }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const partitions = {
    bootloader: new ArrayBuffer(0), //os/bootloader.bin
    partitions: new ArrayBuffer(0), //os/partitions.bin
    zerynth: new ArrayBuffer(0), //os/zerynth.bin
    firmware: new ArrayBuffer(0), //firmware/firmware.bin
    otalog: new ArrayBuffer(0), //os/otalog.bin
    fs: new ArrayBuffer(0), //resources/fs.bin
  };

  const [devicePort, setDevicePort] = useState<SerialPort | null>(null);
  const [usbDisconnected, setUsbDisconnected] = useState<boolean>(false);
  const [flashPercentage, setFlashPercentage] = useState<number>(0);
  const [processError, setProcessError] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<any[]>([
    {
      label: t("checkingUsbConnection"),
      status: "pending",
    },
    {
      label: t("settingFirmwarePreferences"),
      status: "tbd",
    },
    {
      label: t("updatingTheFirmware"),
      status: "tbd",
    },
  ]);

  //STEP 1
  const handleDeviceSelect = () => {
    navigator.serial
      .requestPort({ filters: [{ usbVendorId: 0x1a86, usbProductId: 0x7523 }] })
      .then((port) => {
        console.log("port selected:", port);
        setDevicePort(port);
        const tmp = [...steps];
        steps[0].status = "success";
        steps[1].status = "pending";
        setActiveStep((prev) => prev + 1);
        setSteps([...tmp]);
        //start step 2
        handleFirmwarePreferences(port);
      })
      .catch(() => {
        setProcessError(true);
        console.log("no serial port selected");
      });
  };

  //STEP 2
  const openPort = async (port: SerialPort, baudRate: number, retries = 3) => {
    if (retries > 0) {
      await port
        .open({ baudRate: baudRate })
        .then(() => {
          console.log("serial port opened successfully");
          navigator.serial.onconnect = () => {
            console.log("connected");
          };
          navigator.serial.ondisconnect = () => {
            setUsbDisconnected(true);
            console.log("disconnected");
            setSteps([
              {
                label: t("checkingUsbConnection"),
                status: "pending",
              },
              {
                label: t("settingFirmwarePreferences"),
                status: "tbd",
              },
              {
                label: t("updatingTheFirmware"),
                status: "tbd",
              },
            ]);
            setActiveStep(0);
            console.log("disconnected");
          };
          return true;
        })
        .catch(async (e: any) => {
          console.log("error during port opening:", e);
          console.log("trying to close the port and reopen it again");
          await port.close();
          await openPort(port, baudRate, retries - 1);
          return false;
        });
    } else {
      setProcessError(true);
      console.log("retries finished. failed to open the port.");
    }
  };

  const getPartitions = async () => {
    try {
      for (const k of Object.keys(partitions)) {
        if (k !== "fs") {
          // @ts-ignore
          partitions[k] = await binaryFetch(
            methodGet,
            `${BackendUrl}/firmwares/config_plant/file/${k}` //scaricare un file bin alla volta
          );
        }
      }
    } catch (e) {
      setProcessError(true);
      console.log(e);
      devicePort?.close();
    }
  };

  const flash = async (devicePort: any) => {
    console.log("before get partitions");
    await getPartitions();
    console.log("after get partitions");
    try {
      console.log("DP", devicePort);
      if (devicePort) {
        //start step 3
        const tmp = [...steps];
        steps[1].status = "success";
        steps[2].status = "pending";
        setActiveStep((prev) => prev + 1);
        setSteps([...tmp]);
        await openPort(devicePort, 115200);
        const loader = new ESPLoader(devicePort, {
          log: (...args) => console.log(...args),
          debug: (...args) => console.log(...args),
          error: (...args) => console.log(...args),
        });
        console.log("loader initializer", loader);
        await loader.initialize();
        console.log("loader runstub");
        const espStub = await loader.runStub();
        console.log("setbaudrate");
        await espStub.setBaudrate(921600);
        console.log("PARTITIONS", partitions);
        const ps = [
          {
            name: "bootloader",
            data: partitions.bootloader,
            offset: 0x1000,
          },
          {
            name: "partitions",
            data: partitions.partitions,
            offset: 0x9000,
          },
          {
            name: "zerynth",
            data: partitions.zerynth,
            offset: 0x10000,
          },
          {
            name: "firmware",
            data: partitions.firmware,
            offset: 0x210000,
          },
          {
            name: "otalog",
            data: partitions.otalog,
            offset: 0x910000,
          },
          {
            name: "fs",
            data: partitions.fs,
            offset: 0x920000,
          },
        ];

        for (const p of ps) {
          const i = ps.indexOf(p);
          await espStub.flashData(
            p.data,
            (bytesWritten, totalBytes) => {
              setFlashPercentage(
                i * (100 / ps.length) +
                  (100 / ps.length) * (bytesWritten / totalBytes)
              );
            },
            p.offset
          );
        }

        console.log("stub disconnect");
        await espStub.hardReset();
        await espStub.disconnect();
        await devicePort.close();
        console.log("device flashed successfully");

        return true;
      } else {
        setProcessError(true);
        console.log("NO DEVICE PORT");
      }
    } catch (e) {
      if (devicePort) {
        devicePort?.close();
      }
      setProcessError(true);
      console.log("failed to flash firmware", e);
      return false;
    }
    return true;
  };

  const handleFirmwarePreferences = (port: any) => {
    flash(port).then((res) => {
      if (res) {
        //FINISH CONFIGURATOR PROCEDURE
        handleFinish(port);
      } else {
        setProcessError(true);
      }
    });
  };

  //STEP 3
  const handleFinish = async (port: any) => {
    console.log("FINISH");
    port?.close();
    const tmp = [...steps];
    steps[2].status = "success";
    setSteps([...tmp]);
    setActiveStep((prev) => prev + 1);
    history.push(testingUrl(installation_id));
  };

  //START CONFIGURATOR PROCEDURE
  useEffect(() => {
    localStorage.setItem("hasTestReloaded", "false");
    handleDeviceSelect();
  }, []);

  return (
    <>
      <TitleWithBack title={t("testing")} key={dccTitleKey} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} style={{ marginBottom: "64px" }}>
          <Card variant="outlined">
            <CardContent>
              {usbDisconnected ? (
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="h6">{t("disconnectedUsb")}</Typography>
                  </Grid>
                  <Grid item>
                    <UsbOff style={{ fontSize: "128px" }} />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("retry")}
                      buttonOnClick={() => window.location.reload()}
                    />
                  </Grid>
                </Grid>
              ) : processError ? (
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="h6">
                      {t("configurationError")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Error
                      style={{
                        fontSize: "128px",
                        color: "red",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("retry")}
                      buttonOnClick={() => window.location.reload()}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1} textAlign="center">
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {steps[activeStep] ? steps[activeStep].label : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {t("loadTestsDescription")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LinearProgress color="success" />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default LoadTest;
