import ResponsiveTable, {
  ResponsiveTableCell,
} from "../../custom/table/responsiveTable";
import { useTranslation } from "react-i18next";
import { getIrrigation } from "../../../api/services/installationsService";
import { dateType, stringType } from "../../../costants/costants";

const Notifications: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{ paddingBottom: "5%" }}>
      <ResponsiveTable
        columns={[
          {
            label: t("devActuator"),
            name: "dev_actuator",
            options: {
              sort: true,
              filter: false,
              customBodyRender: (value: string) => (
                <ResponsiveTableCell type={stringType} value={value} />
              ),
            },
          },
          {
            label: t("devPlant"),
            name: "dev_plant",
            options: {
              sort: true,
              filter: false,
              customBodyRender: (value: string) => (
                <ResponsiveTableCell type={stringType} value={value} />
              ),
            },
          },
          {
            label: t("channel"),
            name: "channel",
            options: {
              sort: true,
              filter: false,
              customBodyRender: (value: string) => (
                <ResponsiveTableCell type={stringType} value={value} />
              ),
            },
          },
          {
            label: t("startDate"),
            name: "start_irrigation",
            options: {
              sort: true,
              filter: false,
              customBodyRender: (value: string) => {
                if (value !== "0001-01-01T00:00:00Z") {
                  return <ResponsiveTableCell type={dateType} value={value} />;
                } else {
                  return <></>;
                }
              },
            },
          },
          {
            label: t("duration"),
            name: "duration",
            options: {
              sort: true,
              filter: false,
              customBodyRender: (value: string) => {
                if (value !== "0001-01-01T00:00:00Z") {
                  return <ResponsiveTableCell type={dateType} value={value} />;
                } else {
                  return <></>;
                }
              },
            },
          },
        ]}
        getPageAndSortedData={getIrrigation}
        defaultSort="-start_time"
        responseDataName="notifications"
      />
    </div>
  );
};

export default Notifications;

/*
type IrrigationNotification struct {
    Id              string          `json:"id"`
    DevActuator     string          `json:"dev_actuator"`
    DevPlant        string          `json:"dev_plant"`
    Channel         int             `json:"channel"`
    LastPattern     json.RawMessage `json:"last_pattern"`
    Startirrigation int             `json:"start_irrigation"`
    Duration        int             `json:"duration"`
    CreatedAt       time.Time       `json:"created_at"`
    DeletedAt       *time.Time      `json:"deleted_at"`
}
*/
