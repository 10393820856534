import React, { useState } from "react";
import { updateInstallationTableColumns } from "./inputs";
import { GetAllInstallations } from "../../../api/services/installationsService";
import CustomTitle from "../../custom/CustomTitle";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { useHistory } from "react-router";
import { updateInstallationUrl } from "../costants";
import { company } from "../../../costants/costants";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

type InstallationListPageProps = {
  SetPopupObject: (obj: any) => void;
  admin: boolean;
};

const InstallationsListPage: React.FC<InstallationListPageProps> = ({
  SetPopupObject,
  admin,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<Object[]>([]);
  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) =>
    history.push(updateInstallationUrl(value));

  return (
    <>
      <CustomTitle title={t("editDevice")} />
      <ResponsiveTable
        columns={updateInstallationTableColumns(
          t,
          data,
          SetPopupObject,
          linkFunction,
          admin,
          enqueueSnackbar
        )}
        getPageAndSortedData={GetAllInstallations}
        defaultSort="name"
        responseDataName="subscriptions"
        setData={setData}
      />
    </>
  );
};

export default InstallationsListPage;
