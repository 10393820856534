import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import "./qdc.css";
import { Grid } from "@mui/material";
import FormSelect from "../../custom/select/FormSelect";
import { GetCustomers } from "../../../api/services/userService";
import { set, get, rm } from "lockr";
import { useHistory } from "react-router";
import { campaignPageUrl } from "../costants";
//NON CAMBIARE ASSOLUTAMENTE QUESTA PAGINA PERCHE ALTRIMENTI DORIAN SMETTE DI FIUNZIONARE
type CampaignNotebookProps = {};

const CampaignNotebook: React.FC<CampaignNotebookProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [customer, setCustomer] = useState<string>("");
  const [customers, setCustomers] = useState<any[]>([]);
  const [subOpt, setSubOpt] = useState<any[]>([]);
  useEffect(() => {
    GetCustomers("-1", "", "").then((res: any) => {
      let tmp: any[] = [];
      if (res && res.customers) {
        console.log("RES", res);
        res.customers.map((u: any) => {
          tmp = [
            ...tmp,
            {
              [u.token]: `${u.name} (${u.email})`,
            },
          ];
        });
        setCustomers(res.customers);
        setSubOpt([...tmp]);
        if (get("td_token")) {
          setCustomer(get("td_token"));
        }
      }
    });
    return () => {
      rm("td_token");
      rm("td_status");
    };
  }, []);

  return (
    <>
      <CustomTitle title={t("campaignNotebook")} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSelect
            placeholder={t("customer")}
            keyValue={t("customer")}
            name={t("customer")}
            value={customer}
            onChange={(e: any) => {
              setCustomer(e.target.value);
              set("td_token", e.target.value);
              const tmp = get("td_status");
              set("td_status", {
                ...tmp,
                _pvars: {
                  ...tmp?._pvars,
                  my_profile: {
                    ...tmp?._pvars?.my_profile,
                  },
                  customer_uid: customers.find(
                    (c: any) => c.token === e.target.value
                  ).uid,
                  installation_uid: "",
                  device_uid: "",
                  current_customer: {
                    ...customers.find((c: any) => c.token === e.target.value),
                  },
                },
              });
              if (window.location.pathname !== campaignPageUrl) {
                history.push(campaignPageUrl);
                window.location.reload();
              } else {
                window.location.reload();
              }
            }}
            options={subOpt}
          />
        </Grid>
        {get("td_token") && get("td_status") && (
          <Grid item xs={12}>
            <div id="app"></div>
            <link href="/ss3/bundle.css" rel="stylesheet" />
          </Grid>
        )}
      </Grid>
    </>
    // <>
    //   <link href="/ss3/bundle.css" rel="stylesheet" />
    //   <CustomTitle title={t("campaignNotebook")} />
    //
    //   <div id="app">CIAOCIAOCIAO</div>
    //   <script src="/ss3/bundle.js"></script>
    // </>
  );
};
export default CampaignNotebook;
