import { MenuType, SubMenuType } from "../types/types";
import {
  alarmsPageUrl,
  campaignPageUrl,
  createInstallationUrl,
  createSubscriptionPageUrl,
  installationsListToUpdateUrl,
  installerPanelIconsUrl,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  subscriptionsListToUpdateUrl,
} from "../pages/costants";
import {
  alarmsKey,
  campaignKey,
  createInstallationKey,
  createSubscriptionKey,
  dataRealTimeKey,
  historyKey,
  installerRole,
  updateInstallationKey,
  updateSubscriptionKey,
} from "../../costants/costants";
import realTimeData from "../pages/homePages/img/graph.png";
import alarms from "../pages/homePages/img/alert.png";
import dataHistory from "../pages/homePages/img/fleet.png";
import campaignNotebook from "../pages/homePages/img/book.png";
import configurator from "../pages/homePages/img/inst.png";
import { Build, ManageAccounts, Person, Settings } from "@mui/icons-material";

const installer: (t: any) => SubMenuType[] = (t: any) => [
  {
    key: createSubscriptionKey,
    label: t("createFleet"),
    link: createSubscriptionPageUrl,
    elements: [],
    icon: <Person style={{ color: "#5c4424" }} />,
  },
  {
    key: updateSubscriptionKey,
    label: t("editFleet"),
    link: subscriptionsListToUpdateUrl,
    elements: [],
    icon: <ManageAccounts style={{ color: "#5c4424" }} />,
  },
  {
    key: createInstallationKey,
    label: t("createDevice"),
    link: createInstallationUrl,
    elements: [],
    icon: <Settings style={{ color: "#5c4424" }} />,
  },
  {
    key: updateInstallationKey,
    label: t("editDevice"),
    link: installationsListToUpdateUrl,
    elements: [],
    icon: <Build style={{ color: "#5c4424" }} />,
  },
];

export const installerMenuItems: (t: any) => MenuType[] = (t: any) => [
  // TODO: remove first element, it is necessary because the first element is not shown
  {
    key: "",
    label: "",
    elements: [],
    link: "",
  },
  {
    key: dataRealTimeKey,
    label: t("realTimeData"),
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
    icon: (
      <img src={realTimeData} alt={dataRealTimeKey} width={25} height={25} />
    ),
  },
  {
    key: historyKey,
    label: t("dataHistory"),
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
    icon: <img src={dataHistory} alt={historyKey} width={25} height={25} />,
  },
  {
    key: alarmsKey,
    label: t("alarms"),
    link: alarmsPageUrl,
    elements: [],
    icon: <img src={alarms} alt={alarmsKey} width={25} height={25} />,
  },
  {
    key: campaignKey,
    label: t("campaignNotebook"),
    link: campaignPageUrl,
    elements: [],
    icon: (
      <img src={campaignNotebook} alt={campaignKey} width={25} height={25} />
    ),
  },
  {
    key: installerRole,
    label: t("configurator"),
    elements: installer(t),
    link: installerPanelIconsUrl,
    icon: <img src={configurator} alt={installerRole} width={25} height={25} />,
  },
];
