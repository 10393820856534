import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

type FormSelectProps = {
  placeholder: string;
  keyValue: string;
  options: any[];
  name?: string;
  value?: string | number;
  rules?: { required: boolean; message: string };
  onChange?: any;
  reverse?: boolean;
  error?: boolean;
  defaultValue?: string;
};

const FormSelect: React.FC<FormSelectProps> = ({
  placeholder,
  keyValue,
  options,
  name,
  value,
  rules,
  onChange,
  reverse,
  error,
  defaultValue,
}) => {
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      required={rules ? rules.required : false}
      error={error && !value ? error : false}
    >
      <InputLabel required={rules ? rules.required : false} id="label">
        {placeholder}
      </InputLabel>
      <Select
        inputProps={{ "data-testid": keyValue }}
        labelId="label"
        id={keyValue}
        name={name}
        value={value}
        onChange={onChange ? onChange : undefined}
        label="Fleet"
        defaultValue={defaultValue ? defaultValue : undefined}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }}
      >
        {options.map((el, index) => {
          const keys = Object.keys(el);
          return (
            <MenuItem key={index} value={reverse ? el[keys[0]] : keys[0]}>
              {reverse ? keys[0] : el[keys[0]]}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText error>
        {error && rules && !value ? rules.message : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default FormSelect;
