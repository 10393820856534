import React, { useCallback, useEffect, useState } from "react";
import { Installation } from "../../../api/requests/installationsService";
import ButtonConfItem from "../../custom/ButtonConfItem";
import ButtonItem from "../../custom/ButtonItem";
import moment from "moment";
import { GetExportInfoRequest } from "../../../api/requests/timeSeries";
import { getExportInfo } from "../../../api/services/timeSeries";
import {
  dataHistoryTitleKey,
  downloadError,
  primaryType,
} from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import InstallationSummary from "../../summary/installationSummary/InstallationSummary";
import { useSnackbar } from "notistack";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAllDevices } from "../../../api/services/deviceService";

type DataHistoryPanelProps = {
  installation_id: string;
};

const DataHistoryPanel: React.FC<DataHistoryPanelProps> = ({
  installation_id,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getAllDevices().then((res) => {
      if (res && res.installations) {
        const tmp = res.installations.filter(
          (i: any) => i.id === installation_id
        );
        if (tmp.length > 0) setSelectedInstallation(tmp[0]);
      }
    });
  }, []);

  const downloadFile = useCallback((url: string) => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = url;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }, []);

  const dateFormatter = (date: Date) => moment(date).format("YYYY-MM-DD");

  const [values, setValues] = useState({ start_date: "", end_date: "" });
  const handleSubmit = useCallback(() => {
    const first = new Date(values.start_date);
    const second = new Date(values.end_date);
    let differenceInTime = second.getTime() - first.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);
    if (differenceInDays <= 6) {
      const info: GetExportInfoRequest = {
        tag: "d",
        start: dateFormatter(new Date(values.start_date)),
        end: dateFormatter(new Date(values.end_date)),
        entity_id: installation_id,
      };
      getExportInfo(info)
        .then((resp) => {
          if (resp && resp.url) {
            downloadFile(resp.url);
          } else if (resp && resp.err) {
            enqueueSnackbar(resp?.err?.message || downloadError, {
              variant: "error",
              preventDuplicate: true,
            });
          }
        })
        .catch((e) => console.log("error: ", e));
    } else {
      enqueueSnackbar(t("sevenDaysError"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [values]);

  return (
    <>
      <TitleWithBack title={t("dataHistory")} key={dataHistoryTitleKey} />
      <Grid
        container
        spacing={2}
        flexDirection="column"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <InstallationSummary selected_installation={selectedInstallation} />
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            style={{ marginBottom: "5%", marginLeft: "5%", marginRight: "5%" }}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="space-around"
                style={{ marginTop: "16px" }}
              >
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    label={t("startDate")}
                    type="date"
                    fullWidth
                    value={moment(values.start_date).format("YYYY-MM-DD")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e: any) => {
                      setValues({ ...values, start_date: e.target.value });
                    }}
                    inputProps={{
                      "&::-webkit-calendar-picker-indicator": {
                        display: "none",
                        "-webkit-appearance": "none",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label={t("endDate")}
                    type="date"
                    value={moment(values.end_date).format("YYYY-MM-DD")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e: any) => {
                      setValues({ ...values, end_date: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              <div className="btn-container">
                <ButtonConfItem
                  buttonLabel={t("reset")}
                  buttonOnConfirm={() => {
                    setValues({ start_date: "", end_date: "" });
                  }}
                  questionLabel={t("question")}
                />
                <ButtonItem
                  buttonType={primaryType}
                  label={t("ok")}
                  buttonOnClick={handleSubmit}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DataHistoryPanel;
