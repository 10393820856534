import React, { useEffect, useState } from "react";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { useHistory } from "react-router";
import { devicesListColumns } from "./inputs";
import TitleWithBack from "../../custom/TitleWithBack";
import { useTranslation } from "react-i18next";
import { getAllDevices } from "../../../api/services/deviceService";
import CustomLoading from "../../custom/CustomLoading";

type InstallationsListProps = {
  SetPopupObject: (obj: any) => void;
  subscription_id: string;
  redirectUrl: (id: string) => string;
};

const InstallationsListSubscriptionSummary: React.FC<
  InstallationsListProps
> = ({ SetPopupObject, subscription_id, redirectUrl }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const redirectFun: (value: string) => void = (value: string) => {
    history.push(redirectUrl(value));
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);
  useEffect(() => {
    getAllDevices().then((res) => {
      if (res && res.installations) {
        setDevices(res.installations);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <>
        <TitleWithBack title={t("devices")} />
        <CustomLoading />
      </>
    );
  }

  return (
    <>
      <TitleWithBack title={t("devices")} />
      <ResponsiveTable
        columns={devicesListColumns(t, redirectFun)}
        data={devices.filter((d) => d.subscription_id === subscription_id)}
        /*
        getPageAndSortedData={getSubscriptionInstallations}
        defaultSort={name.name}
        responseDataName={installationsKey}
        id={subscription_id}
        */
      />
    </>
  );
};

export default InstallationsListSubscriptionSummary;
