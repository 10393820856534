import { Chip } from "@mui/material";
import React from "react";

type CustomTagProps = {
  color: "info" | "success" | "error";
  text: string;
};

const CustomTag: React.FC<CustomTagProps> = ({ color, text }) => {
  return (
    <Chip
      label={text}
      color={color}
      className="custom-tag"
      style={{ color: "white" }}
    />
  );
};

export default CustomTag;
