import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  debounceFunc,
  localeDateFormatter,
} from "../../../utilities/utilities";

type DetailsProps = {
  ClosePopup: () => void;
  object: Object[];
  open: boolean;
};

const Details: React.FC<DetailsProps> = ({ ClosePopup, object, open }) => {
  const { t } = useTranslation();

  const checkType = (value: any) => {
    if (!isNaN(new Date(value).getDate()) && String(value).endsWith("Z")) {
      return localeDateFormatter(value);
    } else {
      return String(value);
    }
  };

  const labelFormatter = (label: string) => {
    if (label.includes("_")) {
      switch (label) {
        case "expiry_date":
          return t("expiration");
        case "zip_code":
          return t("zip");
        case "vat_number":
          return t("vat");
        case "created_at":
          return t("creationDate");
        case "last_connection":
          return t("lastConnection");
        case "last_disconnection":
          return t("lastDisconnection");
        case "user_info":
          return t("userData");
        case "business_name":
          return t("businessName");
        default:
          console.log("L ", label);
          return label;
      }
    } else {
      return t(label);
    }
    //return label.charAt(0).toUpperCase() + label.slice(1).split("_").join(" ")
  };

  return (
    <Dialog
      data-testid="details"
      onClose={ClosePopup}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title">{t("details")}</DialogTitle>
      <DialogContent>
        {object.map((obj: Object) => {
          return (
            <TableContainer>
              <Table
                style={{ borderCollapse: "separate" }}
                size="small"
                aria-label="simple table"
                title={
                  obj.hasOwnProperty("detail_title")
                    ? (obj as any).detail_title
                    : undefined
                }
              >
                <TableBody>
                  {Object.keys(obj).map((k: string) => {
                    if (
                      !k.toLowerCase().includes("id") &&
                      !k.toLowerCase().includes("requested_status") &&
                      !k.toLowerCase().includes("detail_title")
                    ) {
                      if (typeof (obj as any)[k] !== "string") {
                        if (
                          (obj as any)[k] &&
                          Object.keys((obj as any)[k]).length > 0
                        ) {
                          if (Array.isArray((obj as any)[k])) {
                            return (
                              <TableRow key={k}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ borderBottom: "none" }}
                                >
                                  {labelFormatter(k)}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ borderBottom: "none" }}
                                >
                                  {((obj as any)[k] as any[]).join(", ")}
                                </TableCell>
                              </TableRow>
                            );
                          } else {
                            return (
                              <TableRow style={{ marginTop: "4%" }} key={k}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  width="100%"
                                  style={{ borderBottom: "none" }}
                                >
                                  {labelFormatter(k)}
                                  {Object.keys((obj as any)[k]).map((k2) => {
                                    return (
                                      <TableRow key={k2}>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{ borderBottom: "none" }}
                                        >
                                          {labelFormatter(k2)}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{ borderBottom: "none" }}
                                        >
                                          {checkType((obj as any)[k][k2])}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        } else {
                          return (
                            <TableRow key={k}>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ borderBottom: "none" }}
                              >
                                {labelFormatter(k)}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderBottom: "none" }}
                              >
                                ---
                              </TableCell>
                            </TableRow>
                          );
                        }
                      } else {
                        return (
                          <TableRow key={k}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ borderBottom: "none" }}
                            >
                              {labelFormatter(k)}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ borderBottom: "none" }}
                            >
                              {checkType((obj as any)[k])}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="close"
          color="inherit"
          onClick={debounceFunc(ClosePopup)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Details;
