import React from "react";
import { useHistory } from "react-router-dom";
import { subscriptionsListToInstallationPageUrl } from "../pages/costants";
import { Divider, IconButton, Typography } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useTranslation } from "react-i18next";

type TitleBackProps = {
  title: string;
  link?: string;
  onClickProp?: () => void;
  disabled?: boolean;
};

const TitleWithBack: React.FC<TitleBackProps> = ({
  title,
  link,
  onClickProp,
  disabled,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div className="titleContainer titleContainerResponsive titleContainerHeigth">
        <IconButton
          size="large"
          onClick={
            !disabled
              ? onClickProp
                ? onClickProp
                : () => {
                    if (link) {
                      history.push(link);
                    } else {
                      if (title === t("realTimeData") && link) {
                        history.push(link);
                      } else {
                        if (title === t("devicesList")) {
                          history.push(subscriptionsListToInstallationPageUrl);
                        } else {
                          history.goBack();
                        }
                      }
                    }
                  }
              : () => {}
          }
        >
          <ArrowCircleLeftIcon
            className="back-button"
            htmlColor="#5c4424"
            fontSize="small"
          />
        </IconButton>
        <Typography
          style={{ fontWeight: "bold" }}
          className="titleBack"
          variant="h6"
        >
          {title}
        </Typography>
      </div>
      <Divider className="divider" />
    </>
  );
};

export default TitleWithBack;
