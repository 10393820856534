import React, { useCallback, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import {
  accountDataFields,
  billingDatafields,
  fleetDatafields,
  ownerDataFields,
} from "./inputs";
import { engCountries, itaCountries } from "../../custom/select/countries";
import OperationResult from "../../custom/OperationResult";
import { errorStatus, primaryType } from "../../../costants/costants";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { addField, checkValues } from "../../../utilities/utilities";
import { useTranslation } from "react-i18next";
import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import { AddUser } from "../../../api/services/userService";
import { roles } from "../costants";

type CreateCustomerProps = {
  role: string;
  email: string;
  uid: string;
};

const CreateCustomer: React.FC<CreateCustomerProps> = ({
  role,
  email,
  uid,
}) => {
  const { t, i18n } = useTranslation();

  const [status, setStatus] = useState<string>("");
  const goBack = () => setStatus("");

  const [error, setError] = useState<boolean>(false);
  const [values, setValues] = useState<any>({
    customer_name: "",
    email: "",
    password: "",
    report_email: "",
    company_phone: "",
    address: "",
    city: "",
    province: "",
    country: "",
    zip_code: "",
    subscription_name: "",
    company_address: "",
    company_city: "",
    company_province: "",
    company_country: "",
    company_zip_code: "",
    company_vat_number: "",
    company_sdi: "",
    pec: "",
    name: "",
    surname: "",
    birthdate: "",
    customer_city: "",
  });

  const handleSubmit = useCallback(() => {
    if (checkValues(values)) {
      AddUser({
        //DATI ACCOUNT
        name: values.customer_name,
        email: values.email,
        password: values.password,
        confirm_password: values.password,
        role: roles.customer,
        secondary_email: values.report_email,
        //DATI UTENZA
        user_info: {
          phone: values.company_phone,
          address: values.address,
          city: values.city,
          province: values.province,
          country: values.country,
          zip_code: values.zip_code,
          //DATI FATTURAZIONE
          billing_info: {
            soc_reason: values.subscription_name,
            address: values.company_address,
            city: values.company_city,
            province: values.company_province,
            state: values.company_country,
            zip_code: values.company_zip_code,
            piva: values.company_vat_number,
            sdi: values.company_sdi,
            pec: values.pec,
          },
          //TITOLARE LEGALE
          legal_owner_info: {
            name: values.name,
            surname: values.surname,
            birth_date: values.birthdate,
            city: values.customer_city,
          },
        },
      })
        .then((res: any) => {
          if (res && res.user) {
            setStatus("success");
          } else {
            setStatus(res?.err?.message || "");
          }
        })
        .catch((e) => {
          setStatus(e);
          console.log(e);
        });
    } else {
      console.log("ERRORE");
      setError(true);
    }
  }, [values]);

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"create"}
        entity={"subscription"}
        error={status}
      />
    );
  }
  console.log("LOAD");
  return (
    <>
      <CustomTitle title={t("createFleet")} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">{t("fleetData")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {fleetDatafields(
                    t,
                    i18n.language === "en" ? engCountries : itaCountries
                  ).map((el) => addField(el, values, setValues, error))}
                </Grid>
                <Grid item>
                  <Typography variant="h6">{t("billingData")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {billingDatafields(
                    t,
                    i18n.language === "en" ? engCountries : itaCountries
                  ).map((el) => addField(el, values, setValues, error))}
                </Grid>
                <Grid item>
                  <Typography variant="h6">{t("legalOwner")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {ownerDataFields(t).map((el) =>
                    addField(el, values, setValues, error)
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="h6">{t("accountData")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {accountDataFields(t).map((el) =>
                    addField(el, values, setValues, error)
                  )}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ButtonConfItem
                      buttonLabel={t("reset")}
                      buttonOnConfirm={() =>
                        setValues({
                          customer_name: "",
                          email: "",
                          password: "",
                          report_email: "",
                          company_phone: "",
                          address: "",
                          city: "",
                          province: "",
                          country: "",
                          zip_code: "",
                          subscription_name: "",
                          company_address: "",
                          company_city: "",
                          company_province: "",
                          company_country: "",
                          company_zip_code: "",
                          company_vat_number: "",
                          company_sdi: "",
                          pec: "",
                          name: "",
                          surname: "",
                          birthdate: "",
                          customer_city: "",
                        })
                      }
                      questionLabel={t("question")}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("ok")}
                      buttonOnClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateCustomer;
