import "./summary.css";
import React, { useCallback, useEffect, useState } from "react";
import { Installation } from "../../../../api/requests/installationsService";
import CustomTag from "../../../custom/CustomTag";
import { getInstallation } from "../../../../api/services/installationsService";
import {
  Typography,
  Divider,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

type RealTimeSummaryProps = {
  selected_installation: Installation | null;
};

const RealTimeSummary: React.FC<RealTimeSummaryProps> = ({
  selected_installation,
}) => {
  const { t } = useTranslation();

  const [installation, setInstallation] = useState<Installation | null>(
    selected_installation
  );

  const updateRtInfo = useCallback(() => {
    if (selected_installation) {
      getInstallation({ id: selected_installation.id }).then((res: any) => {
        if (res && res.installation) {
          setInstallation(res.installation);
        }
      });
    }
  }, [selected_installation]);

  useEffect(() => {
    updateRtInfo();
    const intervalId = setInterval(() => {
      updateRtInfo();
    }, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, [selected_installation]);

  return (
    <Card
      variant="outlined"
      style={{ minHeight: "290px", maxHeight: "290px", overflow: "scroll" }}
    >
      <CardHeader
        style={{ maxHeight: "4vh" }}
        title={
          <Typography variant="h5" className="cardTitle">
            {t("realTimeInfo")}
          </Typography>
        }
      />
      <CardContent>
        <div key={`container_div_2`}>
          <div className="tag-container">
            <Typography>{t("status")}:</Typography>
            {installation && installation.status === "online" ? (
              <CustomTag color="success" text={t("online")} />
            ) : (
              <CustomTag color="error" text={t("offline")} />
            )}
          </div>
          <Divider style={{ paddingTop: "8px", paddingBottom: "8px" }} />
        </div>
        <div key={`container_div_3`}>
          <div className="tag-container">
            <Typography style={{ textTransform: "none" }}>
              {t("lastConnection")}:
            </Typography>
            <div>
              {selected_installation?.last_connection
                ? moment(selected_installation.last_connection).format(
                    "HH:mm:ss DD/MM/YYYY"
                  )
                : "---"}
            </div>
          </div>
          <Divider style={{ paddingTop: "8px", paddingBottom: "8px" }} />
        </div>
        <div key={`container_div_4`}>
          <div className="tag-container">
            <Typography style={{ textTransform: "none" }}>
              {t("lastDisconnection")}:
            </Typography>
            <div>
              {selected_installation?.last_disconnection
                ? moment(selected_installation.last_disconnection).format(
                    "HH:mm:ss DD/MM/YYYY "
                  )
                : "---"}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default RealTimeSummary;
