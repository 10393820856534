import {
  GetSubscriptionInstallationsUrl,
  UpdateSubscriptionExpDateUrl,
  UpdateSubscriptionInfoUrl,
  UpdateSubscriptionStatusUrl,
  SubscriptionsUrl,
  GetSubscriptionUrl,
  rowsPerTablePage,
  changeStatusUrl,
} from "../constants";
import {
  ChangeStatusRequest,
  CreateSubscriptionRequest,
  GetSubscriptionRequest,
  UpdateSubscriptionExpiryRequest,
  UpdateSubscriptionInfoRequest,
  UpdateSubscriptionStatusRequest,
} from "../requests/subscription";

import {
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";

//Get all subscriptions
export const getAllSubscriptions = (
  page: string,
  sort: string,
  search: string
) => {
  return verticalFetch(
    methodGet,
    `${SubscriptionsUrl}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

//Create a new subscription
export const addSubscription = (r: CreateSubscriptionRequest) => {
  return verticalFetch(methodPost, SubscriptionsUrl, JSON.stringify(r));
};

//Get a subscription
export const getSubscription = (r: GetSubscriptionRequest) => {
  return verticalFetch(methodGet, GetSubscriptionUrl(r.subscription_id));
};

//Update subscription information
export const updateSubscriptionInformation = (
  subscription_id: string,
  r: any
) => {
  return verticalFetch(
    methodPut,
    UpdateSubscriptionInfoUrl(subscription_id),
    JSON.stringify(r)
  );
};

//Update subscription status
export const updateSubscriptionStatus = (
  r: UpdateSubscriptionStatusRequest
) => {
  return verticalFetch(
    methodPut,
    UpdateSubscriptionStatusUrl(r.subscription_id),
    JSON.stringify(r)
  );
};

//Update expiration date of a subscription
export const updateSubscriptionExpDate = (
  r: UpdateSubscriptionExpiryRequest
) => {
  return verticalFetch(
    methodPut,
    UpdateSubscriptionExpDateUrl(r.subscription_id),
    JSON.stringify(r)
  );
};

//Get the installations of a subscription
export const getSubscriptionInstallations = (
  subscription_id: string,
  page: string,
  sort: string,
  search: string
) => {
  return verticalFetch(
    methodGet,
    GetSubscriptionInstallationsUrl(subscription_id, page, sort, search)
  );
};

//Richiesta attivazione\disattivazione abbonamento
export const changeStatus = (request: ChangeStatusRequest) => {
  return verticalFetch(
    methodPost,
    changeStatusUrl(request.subscription_id),
    JSON.stringify(request)
  );
};

//Delete subscription
export const DeleteSubscription = (subscriptionId: string) => {
  return verticalFetch(
    methodDelete,
    `${SubscriptionsUrl}`,
    JSON.stringify({ subscription_id: subscriptionId })
  );
};
