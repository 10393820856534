export type Alarm = {
  name: any;
  label: string;
  field: {
    label: string;
    name: string;
    type: string;
    value: boolean | number | string;
  }[];
};

export const actuator = (t: any) => [
  {
    label: "Allarme temperatura interna alta",
    field: [
      {
        label: t("status"),
        name: "t_int_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "t_int_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "t_int_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "t_int_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme umidità interna alta",
    field: [
      {
        label: t("status"),
        name: "hum_int_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "hum_int_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "hum_int_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "hum_int_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
];

export const plant = (t: any) => [
  {
    label: "Allarme livello tensione batteria basso",
    field: [
      {
        label: t("status"),
        name: "batt_lvl_avg_min_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "batt_lvl_avg_min_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("lowerThreshold"),
        name: "batt_lvl_avg_min_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "batt_lvl_avg_min_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme livello tensione pannello solare basso",
    field: [
      {
        label: t("status"),
        name: "solar_lvl_avg_min_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "solar_lvl_avg_min_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("lowerThreshold"),
        name: "solar_lvl_avg_min_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "solar_lvl_avg_min_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme tempo apertura elettrovalvola alto",
    field: [
      {
        label: t("status"),
        name: "ev_avg_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "ev_avg_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("duration"),
        name: "ev_avg_min_threshold",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme temperatura vaso 1 alta",
    field: [
      {
        label: t("status"),
        name: "t_soil1_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "t_soil1_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "t_soil1_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "t_soil1_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme temperatura vaso 1 bassa",
    field: [
      {
        label: t("status"),
        name: "t_soil1_avg_min_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "t_soil1_avg_min_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("lowerThreshold"),
        name: "t_soil1_avg_min_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "t_soil1_avg_min_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme umidità vaso 1 alta",
    field: [
      {
        label: t("status"),
        name: "hum_soil1_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "hum_soil1_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "hum_soil1_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "hum_soil1_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme umidità vaso 1 bassa",
    field: [
      {
        label: t("status"),
        name: "hum_soil1_avg_min_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "hum_soil1_avg_min_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("lowerThreshold"),
        name: "hum_soil1_avg_min_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "hum_soil1_avg_min_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme conducibilità elettrica vaso 1 alta",
    field: [
      {
        label: t("status"),
        name: "ec_soil1_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "ec_soil1_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "ec_soil1_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "ec_soil1_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme conducibilità elettrica vaso 1 bassa",
    field: [
      {
        label: t("status"),
        name: "ec_soil1_avg_min_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "ec_soil1_avg_min_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("lowerThreshold"),
        name: "ec_soil1_avg_min_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "ec_soil1_avg_min_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme temperatura interna alta",
    field: [
      {
        label: t("status"),
        name: "t_int_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "t_int_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "t_int_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "t_int_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme umidità interna alta",
    field: [
      {
        label: t("status"),
        name: "hum_int_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "hum_int_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "hum_int_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "hum_int_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
];

export const pump = (t: any) => [
  {
    label: "Allarme velocità del vento alta",
    field: [
      {
        label: t("status"),
        name: "wind_vel_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "wind_vel_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "wind_vel_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "wind_vel_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme pressione pre filtro alta",
    field: [
      {
        label: t("status"),
        name: "p_prefilter_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "p_prefilter_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "p_prefilter_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "p_prefilter_avg_max_threshold",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme pressione post filtro bassa",
    field: [
      {
        label: t("status"),
        name: "p_postfilter_avg_min_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "p_postfilter_avg_min_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("lowerThreshold"),
        name: "p_postfilter_avg_min_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "p_postfilter_avg_min_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme livello fertilizzante basso",
    field: [
      {
        label: t("status"),
        name: "fert_lvl_avg_min_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "fert_lvl_avg_min_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("lowerThreshold"),
        name: "fert_lvl_avg_min_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "fert_lvl_avg_min_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme consumo acqua elevato",
    field: [
      {
        label: t("status"),
        name: "water_meter_avg_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "water_meter_avg_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "water_meter_avg_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "water_meter_avg_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme temperatura esterna alta",
    field: [
      {
        label: t("status"),
        name: "t_ext_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "t_ext_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "t_ext_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "t_ext_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme temperatura esterna bassa",
    field: [
      {
        label: t("status"),
        name: "t_ext_avg_min_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "t_ext_avg_min_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("lowerThreshold"),
        name: "t_ext_avg_min_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "t_ext_avg_min_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme consumo potenza elevato",
    field: [
      {
        label: t("status"),
        name: "power_avg_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "power_avg_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "power_avg_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "power_avg_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme temperatura interna alta",
    field: [
      {
        label: t("status"),
        name: "t_int_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "t_int_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "t_int_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "t_int_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
  {
    label: "Allarme umidità interna alta",
    field: [
      {
        label: t("status"),
        name: "hum_int_avg_max_status",
        type: "switch",
        value: false,
      },
      {
        label: t("level"),
        name: "hum_int_avg_max_level",
        type: "select",
        value: "",
        options: [
          { low: t("low") },
          { warning: t("warning") },
          { critical: t("critical") },
        ],
      },
      {
        label: t("upperThreshold"),
        name: "hum_int_avg_max_threshold",
        type: "number",
        value: "",
      },
      {
        label: t("duration"),
        name: "hum_int_avg_max_timelimit",
        type: "number",
        value: "",
        endAdornment: t("minutes"),
      },
    ],
  },
];
