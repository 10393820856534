import React from "react";
import { debounceFunc } from "../../utilities/utilities";
import { Button } from "@mui/material";

type ButtonProps = {
  buttonType?: "text" | "contained" | "outlined";
  label: string;
  buttonOnClick: any;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
};

const ButtonItem: React.FC<ButtonProps> = ({
  buttonType,
  label,
  buttonOnClick,
  disabled,
  size,
  fullWidth,
}) => {
  const styles = {
    fontFamily: "Montserrat, sans-serif",
    "&.MuiButton-root": {
      border: "2px #5c4424 solid",
    },
    "&.MuiButton-outlined": {
      color: "#5c4424",
    },
    "&.MuiButton-contained": {
      color: "white",
    },
  };

  return (
    <Button
      fullWidth={fullWidth ? fullWidth : undefined}
      size={size ? size : undefined}
      itemType="submit"
      variant={buttonType ?? "outlined"}
      id={label}
      data-testid={label}
      disabled={disabled ? disabled : undefined}
      onClick={debounceFunc(buttonOnClick)}
      type="submit"
      style={{
        backgroundColor: buttonType !== "outlined" ? "#5c4424" : undefined,
      }}
      sx={styles}
    >
      {label}
    </Button>
  );
};

export default ButtonItem;
