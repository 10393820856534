import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { textType } from "../../../costants/costants";
import { useTranslation } from "react-i18next";

type FormInputProps = {
  placeholder: string;
  type: string;
  keyValue: string;
  name?: string;
  value?: string | number | boolean;
  rules?: { required: boolean; message: string };
  disabled?: boolean;
  onchange?: any;
  error?: boolean;
};

const FormInput: React.FC<FormInputProps> = ({
  placeholder,
  type,
  keyValue,
  name,
  value,
  rules,
  disabled,
  onchange,
  error,
}) => {
  const { t } = useTranslation();
  const [emailCheck, setEmailCheck] = useState<boolean>(false);
  const [passwordCheck, setPasswordCheck] = useState<boolean>(false);
  useEffect(() => {
    //EMAIL
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!value || re.test(String(value))) {
      setEmailCheck(false);
    } else {
      setEmailCheck(true);
    }
    //PASSWORD
    if (
      !value ||
      (/.*\d.*/.test(String(value)) &&
        /.*[A-Z].*/.test(String(value)) &&
        /.*[a-z].*/.test(String(value)) &&
        /.*[~!].*/.test(String(value)))
    ) {
      setPasswordCheck(false);
    } else {
      setPasswordCheck(true);
    }
  }, [value]);

  return (
    <TextField
      inputProps={{ "data-testid": keyValue }}
      error={
        error && !value
          ? error
          : name?.includes("email") || name?.includes("pec")
          ? emailCheck
          : name?.includes("password") && value
          ? passwordCheck
          : false
      }
      size="small"
      id={keyValue}
      name={name}
      label={placeholder}
      value={value}
      type={type ?? textType}
      onChange={onchange ? onchange : undefined}
      variant="outlined"
      required={rules ? rules.required : false}
      fullWidth
      helperText={
        error && rules && !value
          ? rules.message
          : (name?.includes("email") || name?.includes("pec")) && emailCheck
          ? t("emailError")
          : name?.includes("password") && passwordCheck && value
          ? t("passwordError")
          : undefined
      }
    />
  );
};

export default FormInput;
