import {
  address,
  city,
  companyAddress,
  companyCity,
  companyCountry,
  companyPhone,
  companyProvince,
  companySdi,
  companyVat,
  companyZip,
  country,
  email,
  name,
  numberType,
  password,
  passwordType,
  pec,
  phone,
  province,
  sdi,
  selectType,
  subscriptonName,
  surname,
  vat,
  zip,
} from "../../../costants/costants";
import { InputType } from "../../types/types";

export const installerDataFields: (t: any) => InputType[] = (t: any) => [
  {
    key: name.key,
    name: name.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: surname.key,
    name: surname.name,
    label: t("surname"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: address.key,
    name: address.name,
    label: t("address"),
    path: address.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: city.key,
    name: city.name,
    label: t("city"),
    path: city.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: province.key,
    name: province.name,
    label: t("province"),
    path: province.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: zip.key,
    name: zip.name,
    label: t("zip"),
    path: zip.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: country.key,
    name: country.name,
    label: t("country"),
    path: country.name,
    rules: { required: true, message: t("requiredField") },
    type: selectType,
  },
];

export const businessNameFields: (t: any) => InputType[] = (t: any) => [
  {
    key: subscriptonName.key,
    name: subscriptonName.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyPhone.key,
    name: companyPhone.name,
    label: t("phone"),
    path: phone.name,
    rules: { required: true, message: t("requiredField") },
    type: numberType,
  },
  {
    key: companyAddress.key,
    name: companyAddress.name,
    label: t("address"),
    path: address.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyCity.key,
    name: companyCity.name,
    label: t("city"),
    path: city.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyProvince.key,
    name: companyProvince.name,
    label: t("province"),
    path: province.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyZip.key,
    name: companyZip.name,
    label: t("zip"),
    path: zip.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companyCountry.key,
    name: companyCountry.name,
    label: t("country"),
    path: country.name,
    rules: { required: true, message: t("requiredField") },
    type: selectType,
  },

  {
    key: companyVat.key,
    name: companyVat.name,
    label: t("vat"),
    path: vat.name,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: companySdi.key,
    name: companySdi.name,
    path: sdi.name,
    label: t("sdi"),
  },
  {
    key: pec.key,
    name: pec.name,
    label: t("pec"),
    path: pec.name,
  },
];

export const accountDataFields: (t: any) => InputType[] = (t: any) => [
  {
    key: email.key,
    name: email.name,
    label: t("email"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: password.key,
    name: password.name,
    label: t("password"),
    rules: { required: true, message: t("requiredField") },
    type: passwordType,
  },
];
