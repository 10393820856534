import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  dataHistoryPanelUrl,
  installationListToDataHistoryUrl,
  installationListToLineChartUrl,
  installationPageUrl,
  installationsListToLogsUrl,
  installationsListToWifiUrl,
  installationWifiUrl,
  logsUrl,
  routes,
} from "./costants";
import Profile from "./profile/Profile";
import { GetUserProfile } from "../../api/services/userService";
import SignalConfiguration from "./signalConfiguration/signalConfiguration";
import { useMediaQuery } from "@mui/material";
import { useStyles } from "./muiStyle";
import AdminPanel from "./homePages/adminPanel";
import ConfiguratorPanel from "./homePages/configuratorPanel";
import SignalConfigurationTable from "./signalConfiguration/signalConfigurationTable";
import { useTheme } from "@mui/material/styles";
import HomePage from "./homePages/HomePage";
import CreateInstaller from "./createInstaller/createInstaller";
import CreateCustomer from "./createCustomer/createCustomer";
import AlarmTable from "./alarms/AlarmTable";
import AddInstallation from "./addInstallation/AddInstallation";
import InstallerCustomerRelationsPage from "./tab_admin_subscription/InstallerCustomerRelationsPage";
import Navbar from "../navbar/Navbar";
import DeviceConnectionConfiguration from "./deviceConnectionConfiguration/DeviceConnectionConfiguration";
import DataHistoryPanel from "./dataExportDownload/DataHistoryPanel";
import UpdateSubscription from "./updateSubscription/UpdateSubscription";
import UpdateInstallation from "./updateInstallation/UpdateInstallation";
import InstallationPage from "./installation/InstallationPage";
import UpdateUser from "./updateUser/UpdateUser";
import InstallationsListSubscriptionSummary from "./installationsList/InstallationsListSubscriptionSummary";
import SubscriptionsListTable from "./subscriptionsList/SubscriptionsListTable";
import HeaderItem from "../header/Header";
import InstallersListTable from "./installersList/InstallersListTable";
import UsersListTable from "./usersList/UsersListTable";
import InstallationsListPage from "./installationsList/InstallationsListPage";
import Details from "./Details/Details";
//import SelectSubscription from "./select_subscription/SelectSubscription";
import AlarmConfiguration from "./alarmConfiguration/AlarmConfiguration";
import UpdateAlarmConfiguration from "./alarmConfiguration/UpdateAlarm";
import UpdateInstaller from "./updateInstaller/UpdateInstaller";
import CampaignNotebook from "./campaignNotebook/CampaignNotebook";
import Calibration from "./calibration/Calibration";
import InstallationsListToUpdate from "./updateSubscription/InstallationsListToUpdate";
import CreateDevice from "./createDevice/CreateDevice";
import LoadTest from "./technicalTesting/LoadTest";
import TechnicalTesting from "./technicalTesting/TechnicalTesting";
import SuccesConfiguration from "./deviceConnectionConfiguration/SuccessConfiguration";
import Irrigation from "./irrigation/Irrigation";
import CustomerIrrigation from "./customerIrrigation/CustomerIrrigation";

const MainPage: React.FC = () => {
  const history = useHistory();
  document.body.style.backgroundColor = "#f4f6f9";

  const [role, setRole] = useState<string>("");
  const [uid, setUid] = useState<string>("");
  const [navbarCollapsed, setNavbarCollapsed] = useState<boolean>(true);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [popubObject, setPopupObject] = useState<Object[]>([]);
  const handleClose = () => setIsPopupOpen(false);
  const handleOpen = (objects: Object[]) => {
    setPopupObject(objects);
    setIsPopupOpen(true);
  };

  const InstallationsListToData = (props: any) => {
    const { id } = props.match.params;
    return (
      <InstallationsListSubscriptionSummary
        subscription_id={id}
        redirectUrl={installationPageUrl}
        SetPopupObject={handleOpen}
      />
    );
  };

  const InstallationsListToHistory = (props: any) => {
    const { id } = props.match.params;
    return (
      <InstallationsListSubscriptionSummary
        subscription_id={id}
        redirectUrl={dataHistoryPanelUrl}
        SetPopupObject={handleOpen}
      />
    );
  };

  const InstallationsListToWifi = (props: any) => {
    const { id } = props.match.params;
    return (
      <InstallationsListSubscriptionSummary
        subscription_id={id}
        redirectUrl={installationWifiUrl}
        SetPopupObject={handleOpen}
      />
    );
  };

  const InstallationsListToLogs = (props: any) => {
    const { id } = props.match.params;
    return (
      <InstallationsListSubscriptionSummary
        subscription_id={id}
        redirectUrl={logsUrl}
        SetPopupObject={handleOpen}
      />
    );
  };

  const InstallationPageView = (props: any) => {
    const { id } = props.match.params;
    return <InstallationPage installation_id={id} />;
  };

  const UpdateInstallationView = (props: any) => {
    const { id } = props.match.params;
    return <UpdateInstallation installationId={id} />;
  };

  const UpdateSubscriptionView = (props: any) => {
    const { id } = props.match.params;
    return <UpdateSubscription installationId={id} role={role} />;
  };

  /*
  const SubListToUpdateView = () => {
    return <SelectSubscription SetPopupObject={handleOpen} role={role} />;
  };
  */

  const SubListToInstallationView = () => {
    return (
      <SubscriptionsListTable
        link={installationListToLineChartUrl}
        SetPopupObject={handleOpen}
      />
    );
  };

  const SubListToDataHistory = () => {
    return (
      <SubscriptionsListTable
        link={installationListToDataHistoryUrl}
        SetPopupObject={handleOpen}
      />
    );
  };

  const SubListToWifi = () => {
    return (
      <SubscriptionsListTable
        link={installationsListToWifiUrl}
        SetPopupObject={handleOpen}
      />
    );
  };

  const SubListToLogs = () => {
    return (
      <SubscriptionsListTable
        link={installationsListToLogsUrl}
        SetPopupObject={handleOpen}
      />
    );
  };

  const DataHistoryView = (props: any) => {
    const { id } = props.match.params;
    return <DataHistoryPanel installation_id={id} />;
  };

  const UpdateUserView = (props: any) => {
    const { id } = props.match.params;
    return <UpdateUser user_id={id} />;
  };

  const UpdateInstallerView = (props: any) => {
    const { id } = props.match.params;
    return <UpdateInstaller user_id={id} />;
  };

  const SignalConfigurationView = (props: any) => {
    const { id } = props.match.params;
    return <SignalConfigurationTable installation_id={id} />;
  };

  const AlarmConfigurationView = (props: any) => {
    const { id } = props.match.params;
    return <AlarmConfiguration installation_id={id} />;
  };

  const IrrigationConfigurationView = (props: any) => {
    const { id } = props.match.params;
    return <Irrigation deviceId={id} />;
  };

  const IrrigationConfigurationCustomerView = (props: any) => {
    return <CustomerIrrigation />;
  };

  const TestingConfigurationView = (props: any) => {
    const { id } = props.match.params;
    return <LoadTest installation_id={id} />;
  };

  const TestingView = (props: any) => {
    const { id } = props.match.params;
    return <TechnicalTesting installation_id={id} />;
  };

  const CalibrationView = (props: any) => {
    const { id } = props.match.params;
    return <Calibration installation_id={id} />;
  };

  const UpdateSignalConfigurationView = (props: any) => {
    const { id } = props.match.params;
    return <SignalConfiguration installation_id={id} />;
  };

  const DeviceConnectionConfigurationView = (props: any) => {
    const { id } = props.match.params;
    return <CreateDevice installation_id={id} update={false} />;
  };

  const UpdateDeviceConnectionConfigurationView = (props: any) => {
    const { id } = props.match.params;
    return (
      <DeviceConnectionConfiguration installation_id={id} update={false} />
    );
  };

  const ConfigurationSuccessView = (props: any) => {
    const { id } = props.match.params;
    return <SuccesConfiguration installation_id={id} />;
  };

  const ProfileView = () => {
    return <Profile SetPopupObject={handleOpen} />;
  };

  const InstallersListTableView = () => {
    return <InstallersListTable SetPopupObject={handleOpen} />;
  };

  const InstallationsListPageView = () => {
    return (
      <InstallationsListPage
        SetPopupObject={handleOpen}
        admin={role.toLocaleLowerCase() === "admin"}
      />
    );
  };

  const InstallerCustomerRelationsPageView = () => {
    return <InstallerCustomerRelationsPage SetPopupObject={handleOpen} />;
  };

  const UsersListTableView = () => {
    return <UsersListTable role={role} SetPopupObject={handleOpen} />;
  };

  const InstallationsListToUpdateView = () => {
    return <InstallationsListToUpdate role={role} />;
  };

  const UpdateAlarmConfigurationView = (props: any) => {
    const { id } = props.match.params;
    const { name } = props.match.params;
    return <UpdateAlarmConfiguration installation_id={id} alarm_id={name} />;
  };

  const [email, setEmail] = useState<string>("");
  useEffect(() => {
    GetUserProfile().then((res: any) => {
      if (!res || !res.user) {
        history.push("/login");
      } else {
        if (res.user.email) {
          setEmail(res.user.email);
        }
        if (res.user.uid) {
          setUid(res.user.uid);
        }
        setRole(res.user.roles[0]);
      }
    });
  }, []);

  const HomeView = () => {
    return <HomePage role={role} />;
  };

  const ConfiguratorPanelView = () => {
    return <ConfiguratorPanel role={role} />;
  };

  const CreateCustomerView = () => {
    return <CreateCustomer role={role} email={email} uid={uid} />;
  };

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [onlyIcon, setOnlyIcon] = useState<boolean>(false);

  return (
    <>
      <Navbar
        onlyIcon={onlyIcon}
        SetNavbarIsCollapsed={setNavbarCollapsed}
        navbar_collapsed={navbarCollapsed}
      />
      <HeaderItem
        onlyIcon={onlyIcon}
        setOnlyIcon={setOnlyIcon}
        role={role}
        email={email}
        SetNavbarIsCollapsed={setNavbarCollapsed}
        navbar_collapsed={navbarCollapsed}
      />
      <div
        className={isMobile ? clsx(classes.content) : clsx(classes.content)}
        style={{ paddingLeft: !onlyIcon ? "280px" : "80px" }}
      >
        <Switch>
          <Route exact path={routes.mainPage} component={HomeView} />
          <Route exact path={routes.profile} component={ProfileView} />
          <Route exact path={routes.calibration} component={CalibrationView} />
          <Route exact path={routes.adminPanel} component={AdminPanel} />
          <Route
            exact
            path={routes.configuratorPanel}
            component={ConfiguratorPanelView}
          />
          <Route exact path={routes.addInstaller} component={CreateInstaller} />
          <Route exact path={routes.addCustomer} component={CreateCustomer} />
          <Route
            exact
            path={routes.installationPage}
            component={InstallationPageView}
          />
          <Route
            exact
            path={routes.customersList}
            component={UsersListTableView}
          />
          <Route exact path={routes.updateUser} component={UpdateUserView} />
          <Route
            exact
            path={routes.updateInstaller}
            component={UpdateInstallerView}
          />
          <Route
            exact
            path={routes.installersList}
            component={InstallersListTableView}
          />
          <Route
            exact
            path={routes.installationsListToLineChart}
            component={InstallationsListToData}
          />
          <Route
            exact
            path={routes.installationsListToDataHistory}
            component={InstallationsListToHistory}
          />
          <Route
            exact
            path={routes.addSubscription}
            component={CreateCustomerView}
          />
          <Route
            exact
            path={routes.subscriptionsListToUpdate}
            component={InstallationsListToUpdateView}
          />
          <Route
            exact
            path={routes.updateSubscription}
            component={UpdateSubscriptionView}
          />
          <Route
            exact
            path={routes.subscriptionsListToInstallationPage}
            component={SubListToInstallationView}
          />
          <Route
            exact
            path={routes.subscriptionsListToDataHistory}
            component={SubListToDataHistory}
          />
          <Route
            exact
            path={routes.installersAssociations}
            component={InstallerCustomerRelationsPageView}
          />
          <Route
            exact
            path={routes.addInstallation}
            component={AddInstallation}
          />
          <Route
            exact
            path={routes.installationsListToUpdate}
            component={InstallationsListPageView}
          />
          <Route
            exact
            path={routes.updateInstallation}
            component={UpdateInstallationView}
          />
          <Route exact path={routes.alarms} component={AlarmTable} />
          <Route
            exact
            path={routes.campaignPage}
            component={
              /*NON CAMBIARE ASSOLUTAMENTE SENNO DORIAN SMETTE DI FUNZIONARE*/
              /*role === "customer" ? CampaignNotebookUser :*/ CampaignNotebook
            }
          />
          <Route
            exact
            path={routes.dataHistoryPanel}
            component={DataHistoryView}
          />
          <Route
            exact
            path={routes.deviceConnectionConfiguration}
            component={DeviceConnectionConfigurationView}
          />
          <Route
            exact
            path={routes.updateDeviceConnectionConfiguration}
            component={UpdateDeviceConnectionConfigurationView}
          />
          <Route
            exact
            path={routes.successConfiguration}
            component={ConfigurationSuccessView}
          />
          <Route
            exact
            path={routes.signalConfiguration}
            component={SignalConfigurationView}
          />
          <Route
            exact
            path={routes.updateSignalConfiguration}
            component={UpdateSignalConfigurationView}
          />
          <Route
            exact
            path={routes.alarmConfiguration}
            component={AlarmConfigurationView}
          />
          <Route
            exact
            path={routes.irrigationConfiguration}
            component={IrrigationConfigurationView}
          />
          <Route
            exact
            path={routes.irrigationConfigurationCustomer}
            component={IrrigationConfigurationCustomerView}
          />
          <Route
            exact
            path={routes.testConfiguration}
            component={TestingConfigurationView}
          />
          <Route exact path={routes.testing} component={TestingView} />
          <Route
            exact
            path={routes.updateAlarmConfiguration}
            component={UpdateAlarmConfigurationView}
          />
          <Route
            exact
            path={routes.subscriptionsListToWifi}
            component={SubListToWifi}
          />
          <Route
            exact
            path={routes.installationsListToWifi}
            component={InstallationsListToWifi}
          />
          <Route
            exact
            path={routes.subscriptionsListToLogs}
            component={SubListToLogs}
          />
          <Route
            exact
            path={routes.installationsListToLogs}
            component={InstallationsListToLogs}
          />
        </Switch>
        <Details
          ClosePopup={handleClose}
          object={popubObject}
          open={isPopupOpen}
        />
      </div>
    </>
  );
};

export default MainPage;
