import { GetProfileUrl, rowsPerTablePage, UsersServiceUrl } from "../constants";
import {
  AddUserRequest,
  UsersRelationRequest,
  GetUserRequest,
  UpdateUserRequest,
} from "../requests/userService";
import {
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";

//Create a new user => OKK
export const AddUser = (r: AddUserRequest) => {
  return verticalFetch(methodPost, UsersServiceUrl, JSON.stringify(r));
};

//Get a user => OKK
export const GetUser = (r: GetUserRequest) => {
  return verticalFetch(methodGet, `${UsersServiceUrl}/${r.user_id}`);
};

//Update a user entity
export const UpdateUser = (r: UpdateUserRequest) => {
  return verticalFetch(
    methodPut,
    `${UsersServiceUrl}/${r.user_id}`,
    JSON.stringify(r)
  );
};

//Create relation between user and customer
export const AddUsersRelation = (r: UsersRelationRequest) => {
  return verticalFetch(
    methodPost,
    `${UsersServiceUrl}/${r.installer_id}/customers/${r.customer_id}`
  );
};

//Delete relation between user and customer
export const DeleteUsersRelation = (r: UsersRelationRequest) => {
  return verticalFetch(
    methodDelete,
    `${UsersServiceUrl}/${r.installer_id}/customers/${r.customer_id}`
  );
};

//Get the list of all installers
export const GetInstallers = (page: string, sort: string, search: string) => {
  return verticalFetch(
    methodGet,
    `${UsersServiceUrl}/installers?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

//Get the list of customers associated with an installer
export const GetCustomers = (page: string, sort: string, search: string) => {
  return verticalFetch(
    methodGet,
    `${UsersServiceUrl}/customers?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

//Get the list of all relationsa between installers and customers
export const GetRelInstallersCustomer = () => {
  return verticalFetch(methodGet, `${UsersServiceUrl}/installers/relations`);
};

//Get user information
export const GetUserProfile = () => {
  return verticalFetch(methodGet, GetProfileUrl);
};

//Delete user
export const DeleteUser = (userId: string) => {
  return verticalFetch(
    methodDelete,
    `${UsersServiceUrl}`,
    JSON.stringify({ user_id: userId })
  );
};
