import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import React, { useEffect, useState } from "react";
import {
  getSubscription,
  updateSubscriptionExpDate,
  updateSubscriptionStatus,
} from "../../../api/services/subscriptionService";
import OperationResult from "../../custom/OperationResult";
import {
  errorStatus,
  formInputKey,
  primaryType,
  textType,
  updateSubscriptionTitleKey,
} from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  List,
  ListItemText,
  CardHeader,
  Typography,
  ListItem,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomLoading from "../../custom/CustomLoading";
import { billingDatafields, fleetDatafields, ownerDataFields } from "./inputs";
import { engCountries, itaCountries } from "../../custom/select/countries";
import FormInput from "../../custom/input/FormInput";
import { GetCustomers, UpdateUser } from "../../../api/services/userService";

type UpdateSubscriptionProps = {
  installationId: string;
  role: string;
};

const UpdateSubscription: React.FC<UpdateSubscriptionProps> = ({
  role,
  installationId,
}) => {
  const { t, i18n } = useTranslation();

  const [subscription, setSubscription] = useState({
    name: "",
    soc_reason: "",
    address: "",
    city: "",
    status: "",
    expiry_date: "",
  });
  const [status, setStatus] = useState<string>("");

  const goBack = () => setStatus("");

  const [values, setValues] = useState<any>({});
  const [subLoading, setSubLoading] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    getSubscription({ subscription_id: installationId }).then((res: any) => {
      if (res && res.subscription) {
        setSubscription({
          name: res.subscription.name,
          soc_reason: res.subscription.customer_info.billing_info.soc_reason,
          status: res.subscription.status,
          address: res.subscription.address,
          city: res.subscription.city,
          expiry_date: res.subscription.expiry_date,
        });
        setValues(res.subscription);
      }
      setSubLoading(false);
    });
  }, []);

  useEffect(() => {
    GetCustomers("-1", "", "").then((res: any) => {
      if (res && res.customers) {
        (res.customers as any[]).forEach((el) => {
          if (el.uid === installationId) {
            setValues({ ...el });
          }
        });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmitExpDate = () => {
    updateSubscriptionExpDate({
      subscription_id: installationId,
      exp_date: new Date(subscription.expiry_date).toISOString(),
    }).then((res) => {
      if (res && !res.err) {
        setStatus("success");
      } else {
        setStatus(res?.err?.message || "");
      }
    });
  };

  const handleSubmitStatus = () => {
    updateSubscriptionStatus({
      subscription_id: installationId,
      status: subscription.status,
    }).then((res) => {
      if (res && !res.err) {
        setStatus("success");
      } else {
        setStatus(res?.err?.message || "");
      }
    });
  };

  const handleSubmit = () => {
    if (values) {
      UpdateUser({ ...values, user_id: installationId }).then((res) => {
        if (res && !res.err) {
          setStatus("success");
        } else {
          setStatus(res?.err?.message || "");
        }
      });
    }
  };

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"update"}
        entity={"subscription"}
        error={status}
      />
    );
  }

  if (loading || subLoading) {
    return <CustomLoading />;
  }

  if (role === "installer") {
    return (
      <>
        <TitleWithBack
          title={t("editFleet")}
          key={updateSubscriptionTitleKey}
        />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
            <Card variant="outlined">
              <CardContent>
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="h6">{t("fleetData")}</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    {fleetDatafields(
                      t,
                      i18n.language === "en" ? engCountries : itaCountries
                    ).map((el) => (
                      <Grid item xs={12} md={6}>
                        <FormInput
                          key={formInputKey(el.key)}
                          placeholder={el.label}
                          type={el.type ?? textType}
                          keyValue={el.key}
                          name={el.name}
                          rules={el.rules}
                          value={
                            el.name === "subscription_name"
                              ? values.name
                              : (values as any)["user_info"][
                                  el?.path || el.name
                                ]
                          }
                          onchange={(e: any) => {
                            if (el.name === "subscription_name") {
                              setValues({
                                ...values,
                                name: e.target.value,
                              });
                            } else {
                              setValues({
                                ...values,
                                user_info: {
                                  ...values.user_info,
                                  [el?.path || el.name]: e.target.value,
                                },
                              });
                            }
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">{t("billingData")}</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    {billingDatafields(
                      t,
                      i18n.language === "en" ? engCountries : itaCountries
                    ).map((el) => (
                      <Grid item xs={12} md={6}>
                        <FormInput
                          key={formInputKey(el.key)}
                          placeholder={el.label}
                          type={el.type ?? textType}
                          keyValue={el.key}
                          name={el.name}
                          rules={el.rules}
                          value={
                            (values as any)["user_info"]["billing_info"][
                              el?.path || el.name
                            ]
                          }
                          onchange={(e: any) => {
                            setValues({
                              ...values,
                              user_info: {
                                ...values.user_info,
                                billing_info: {
                                  ...values.user_info.billing_info,
                                  [el?.path || el.name]: e.target.value,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">{t("legalOwner")}</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    {ownerDataFields(t).map((el) => (
                      <Grid item xs={12} md={6}>
                        <FormInput
                          key={formInputKey(el.key)}
                          placeholder={el.label}
                          type={el.type ?? textType}
                          keyValue={el.key}
                          name={el.name}
                          rules={el.rules}
                          value={
                            (values as any)["user_info"]["legal_owner_info"][
                              el?.path || el.name
                            ]
                          }
                          onchange={(e: any) => {
                            setValues({
                              ...values,
                              user_info: {
                                ...values.user_info,
                                legal_owner_info: {
                                  ...values.user_info.legal_owner_info,
                                  [el?.path || el.name]: e.target.value,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <ButtonConfItem
                        buttonLabel={t("reset")}
                        buttonOnConfirm={() => setValues({})}
                        questionLabel={t("question")}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonItem
                        buttonType={primaryType}
                        label={t("ok")}
                        buttonOnClick={handleSubmit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <TitleWithBack
          title={t("installationsManagement")}
          key={updateSubscriptionTitleKey}
        />
        <Grid container flexDirection="column" spacing={2}>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item xs={12} md={10}>
              <Card variant="outlined">
                <CardContent>
                  <List disablePadding dense>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {subscription.name}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("installation")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {subscription.soc_reason}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("businessName")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {subscription.address}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("address")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle2">
                          {subscription.city}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {t("city")}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card variant="outlined">
                <CardHeader title={t("editExpiration")} />
                <CardContent>
                  <Grid container flexDirection="column" spacing={4}>
                    <Grid item>
                      <TextField
                        size="small"
                        label={t("expiration")}
                        type="date"
                        fullWidth
                        value={moment(subscription.expiry_date).format(
                          "YYYY-MM-DD"
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e: any) =>
                          setSubscription({
                            ...subscription,
                            expiry_date: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <ButtonConfItem
                          buttonLabel={t("reset")}
                          buttonOnConfirm={() => {
                            setSubscription({
                              name: "",
                              soc_reason: "",
                              address: "",
                              city: "",
                              expiry_date: "",
                              status: "",
                            });
                          }}
                          questionLabel={t("question")}
                        />
                      </Grid>
                      <Grid item>
                        <ButtonItem
                          buttonType={primaryType}
                          label={t("ok")}
                          buttonOnClick={handleSubmitExpDate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card variant="outlined">
                <CardHeader title={t("enableDisableUtility")} />
                <CardContent>
                  <Grid container flexDirection="column" spacing={4}>
                    <Grid item>
                      <RadioGroup
                        row
                        value={subscription.status}
                        onChange={(e: any) =>
                          setSubscription({
                            ...subscription,
                            status: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="enabled"
                          control={<Radio />}
                          label={t("enabled")}
                        />
                        <FormControlLabel
                          value="disabled"
                          control={<Radio />}
                          label={t("disabled")}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <ButtonConfItem
                          buttonLabel={t("reset")}
                          buttonOnConfirm={() => {
                            setSubscription({
                              name: "",
                              soc_reason: "",
                              address: "",
                              city: "",
                              expiry_date: "",
                              status: "",
                            });
                          }}
                          questionLabel={t("question")}
                        />
                      </Grid>
                      <Grid item>
                        <ButtonConfItem
                          buttonType={primaryType}
                          buttonLabel={t("ok")}
                          buttonOnConfirm={handleSubmitStatus}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default UpdateSubscription;
