import React from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useHistory } from "react-router-dom";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { updateSubscriptionUrl } from "../costants";
import { useTranslation } from "react-i18next";
import { columns, installationsColumns } from "./inputs";
import { GetCustomers } from "../../../api/services/userService";
import { GetAllInstallations } from "../../../api/services/installationsService";

type InstallationsListToUpdateProps = {
  role: string;
};

const InstallationsListToUpdate: React.FC<InstallationsListToUpdateProps> = ({
  role,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const linkFunction: (value: string) => void = (value: string) => {
    history.push(updateSubscriptionUrl(value));
  };

  return (
    <>
      <CustomTitle title={t("utilityManagement")} />
      <ResponsiveTable
        columns={
          role === "admin"
            ? installationsColumns(t, linkFunction)
            : columns(t, linkFunction)
        }
        getPageAndSortedData={
          role === "admin" ? GetAllInstallations : GetCustomers
        }
        defaultSort="name"
        responseDataName={role === "admin" ? "subscriptions" : "customers"}
      />
    </>
  );
};

export default InstallationsListToUpdate;
