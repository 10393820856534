import { name, selectType } from "../../../costants/costants";

export const configurationInputs = (t: any) => [
  {
    key: name.key,
    name: name.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: "type",
    name: "type",
    label: t("type"),
    type: selectType,
    options: [
      { plant: t("plant") },
      { pump: t("pump") },
      { actuator: t("actuator") },
    ],
    rules: { required: true, message: t("requiredField") },
  },
];
