import React, { useCallback, useEffect, useState } from "react";
import {
  AddUsersRelation,
  DeleteUsersRelation,
  GetCustomers,
  GetInstallers,
  GetRelInstallersCustomer,
} from "../../../api/services/userService";
import {
  User,
  UserRelation,
  UsersRelationRequest,
} from "../../../api/requests/userService";
import CustomTitle from "../../custom/CustomTitle";
import ResponsiveTable from "../../custom/table/responsiveTable";
import { installersListColumns } from "./inputs";
import { customerSelectRel, primaryType } from "../../../costants/costants";
import { debounceFunc } from "../../../utilities/utilities";
import { useSnackbar } from "notistack";
import ButtonItem from "../../custom/ButtonItem";
import FormSelect from "../../custom/select/FormSelect";
import { Card, CardContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

type InstallerCustomerRelationsPageProps = {
  SetPopupObject: (obj: any) => void;
};

const InstallerCustomerRelationsPage: React.FC<
  InstallerCustomerRelationsPageProps
> = ({ SetPopupObject }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [relInstallers, setRelInstallers] = useState<UserRelation[]>([]);
  const [instOpt, setInstOpt] = useState<any[]>([]);
  const [custOpt, setCustOpt] = useState<any[]>([]);

  useEffect(() => {
    GetInstallers("-1", "", "").then((res: any) => {
      if (res && res.installers) {
        let tmp: any[] = [];
        res.installers.map((i: User) => (tmp = [...tmp, { [i.uid]: i.email }]));
        setInstOpt([...tmp]);
      }
    });
    GetCustomers("-1", "", "").then((res: any) => {
      if (res && res.customers) {
        let tmp: any[] = [];
        res.customers.map((i: User) => (tmp = [...tmp, { [i.uid]: i.email }]));
        setCustOpt([...tmp]);
      }
    });
    GetRelInstallersCustomer().then((res: any) => {
      if (res && res.relations) {
        setRelInstallers(res.relations);
      }
    });
  }, []);

  const [values, setValues] = useState({ installer: "", user: "" });
  const addRelation = useCallback(() => {
    AddUsersRelation({
      installer_id: values.installer,
      customer_id: values.user,
    } as UsersRelationRequest).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("associateUserToCustomerSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar(res?.err?.message || t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  }, [values]);

  const deleteRelation = (record: UserRelation) => {
    DeleteUsersRelation({
      installer_id: record.installer_id,
      customer_id: record.customer_id,
    } as UsersRelationRequest).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("dissociateUserToCustomerSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar(res?.err?.message || t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  };

  return (
    <>
      <CustomTitle title={t("installersAssociations")} />
      <Grid container flexDirection="column" spacing={4}>
        <Grid item>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      key="installer"
                      keyValue="installer"
                      placeholder={t("selectInstaller")}
                      rules={{ required: true, message: t("requiredField") }}
                      name="installer"
                      options={instOpt}
                      onChange={(e: any) => {
                        setValues({ ...values, installer: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      key={customerSelectRel.name}
                      keyValue={customerSelectRel.name}
                      placeholder={t("selectCustomer")}
                      rules={{
                        required: true,
                        message: customerSelectRel.rules,
                      }}
                      name={customerSelectRel.name}
                      options={custOpt}
                      onChange={(e: any) => {
                        setValues({ ...values, user: e.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <ButtonItem
                    label={t("associateInstaller")}
                    buttonType={primaryType}
                    buttonOnClick={debounceFunc(() => addRelation())}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <ResponsiveTable
            columns={installersListColumns(
              t,
              relInstallers,
              SetPopupObject,
              deleteRelation
            )}
            data={relInstallers}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InstallerCustomerRelationsPage;
