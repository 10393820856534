import { selectType } from "../../../costants/costants";

export const sendChannels = {
  ztc_check_discover: "ztc_check_discover",
  firmware_download: "firmware_download",
  ztc_change_connection: "ztc_change_connection",
  ztc_discover: "ztc_discover",
  ztc_phys_id: "ztc_phys_id",
  ztc_provision_prepare: "ztc_provision_prepare",
  ztc_provision_command: "ztc_provision_command",
  ztc_burn: "ztc_burn",
  ztc_erase: "ztc_device_erase",
  zdm_sim_active: "zdm_sim_active",
  technical_testing: "technical_testing",
};

export const receiveChannels = {
  ztc_check_discover_result: "ztc_check_discover_result",
  firmware_download_result: "firmware_download_result",
  ztc_change_connection_result: "ztc_change_connection_result",
  ztc_discover_result: "ztc_discover_result",
  ztc_phys_id_result: "ztc_phys_id_result",
  ztc_provision_prepare_result: "ztc_provision_prepare_result",
  ztc_provision_command_result: "ztc_provision_command_result",
  ztc_burn_result: "ztc_burn_result",
  ztc_erase_result: "ztc_device_erase_result",
  zdm_sim_active_result: "zdm_sim_active_result",
  technical_testing_result: "technical_testing_result",
};

export const select = {
  label: "Connection type",
  name: "connection_type",
  placeholder: "Connection type",
  options: [
    //{wifi: "WiFi"},
    { gsm: "gsm" },
  ],
};

export const wifi = [
  {
    label: "Network name",
    name: "sid",
    requiredLabel: "Required field!",
    placeholder: "Network name",
    type: "text",
  },
  {
    label: "Password",
    name: "password",
    requiredLabel: "Required field!",
    placeholder: "Password",
    type: "password",
  },
];

export const configurationInputs = (t: any) => [
  {
    key: "phone",
    name: "phone",
    label: t("operator"),
    type: selectType,
    rules: { required: true, message: t("requiredField") },
    options: [
      { "mobile.vodafone.it": "Vodafone" },
      { "ibox.tim.it": "TIM" },
      { "internet.wind": "Wind" },
      { TM: "Things Mobile" },
      { "iot.secure": "Zerynth Sim" },
    ],
  },
];

export const gsm = {
  label: "Operatore telefonico",
  requiredLabel: "Required field!",
  name: "operator",
  options: [
    { "mobile.vodafone.it": "Vodafone" },
    { "ibox.tim.it": "TIM" },
    { "internet.wind": "Wind" },
    { TM: "Things Mobile" },
    { "iot.secure": "Zerynth Sim" },
  ],
};

export const resultStatus = {
  success: "success",
  error: "error",
};

export const resultTitle = {
  success: "The operation was completed successfully",
  error: "The operation was not successful",
};

export const configJson = (type: string) => {
  if (type === "plant") {
    return `{"params": { "wtd": 120000, "acq_freq": 5000, "pub_freq": 1000, "agent_timeout": 1500, "store_period": 60, "info_period": 1800, "exc_period": 120, "dn_chunk_size": 512, "dn_wtd": 240000, "offline_thr": 5, "resolve_thr": 5}}`;
  }
  if (type === "pump") {
    return `{"params": {"wtd": 120000, "acq_freq": 5000, "pub_freq": 1000, "agent_timeout": 1500, "store_period": 60, "info_period": 1800, "exc_period": 120, "dn_chunk_size": 512, "dn_wtd": 240000, "offline_thr": 5, "resolve_thr": 5}}`;
  }
  if (type === "actuator") {
    return `{"params": { "wtd": 120000, "acq_freq": 5000, "pub_freq": 1000, "agent_timeout": 1500, "store_period": 60, "info_period": 1800, "exc_period": 120, "dn_chunk_size": 512, "dn_wtd": 240000, "offline_thr": 5, "resolve_thr": 5}}`;
  }
  return `{}`;
};

export const paramsJson = (type: string) => {
  if (type === "plant") {
    return `{
      "sm3" : [
          {"t":0,"h":0,"ec":0},
          {"t":0,"h":0,"ec":0},
          {"t":0,"h":0,"ec":0},
          {"t":0,"h":0,"ec":0}
      ],
      "bat" : 0,
      "sol" : 0,
      "ev_in" : 1,
      "bme": {"t":0,"h":0,"p":0}
  }`;
  }
  if (type === "pump") {
    return `{
      "w_dir" : {"min":0, "max":10, "off": 0},
      "w_spd" : {"min":0, "max":10, "off": 0},
      "p_pre" : {"min":0, "max":10, "off": 0},
      "p_pos" : {"min":0, "max":10, "off": 0},
      "fert"  : {"min":0, "max":10, "off": 0},
      "t_ext" : 0,
      "curr" : 3000,
  
      "bme": {"t":0,"h":0,"p":0}
  }`;
  }
  if (type === "actuator") {
    return `{
      "ev_11":[[]],
      "ev_12":[[]],
      "ev_13":[[]],
      "ev_14":[[]],
      "ev_15":[[]],
      "ev_16":[[]],
      "ev_17":[[]],
      "ev_18":[[]],
  
      "bme": {"t":0,"h":0,"p":0}
  }`;
  }
  return `{}`;
};
