import { logout } from "../../api/services/loginService";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { routes } from "../pages/costants";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import {
  AppBar,
  Avatar,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useStyles } from "../pages/muiStyle";
import clsx from "clsx";
import {
  fixedPosition,
  iconColor,
  iconEdge,
  iconLabel,
  logo as logoKey,
  menuId,
  successStatusKey,
} from "../../costants/costants";
import nursetLogo from "../../images/logo-nurset.svg";
import { useTranslation } from "react-i18next";
import { AccountCircleOutlined } from "@mui/icons-material";
import { rm } from "lockr";

type HeaderItemProps = {
  SetNavbarIsCollapsed: (collapsed: boolean) => void;
  navbar_collapsed: boolean;
  role: string;
  email: string;
  onlyIcon: boolean;
  setOnlyIcon: (onlyIcon: boolean) => void;
};

const HeaderItem: React.FC<HeaderItemProps> = ({
  SetNavbarIsCollapsed,
  navbar_collapsed,
  role,
  email,
  onlyIcon,
  setOnlyIcon,
}) => {
  const { t, i18n } = useTranslation();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [heigth, setHeigth] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeigth(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const logOut = () =>
    logout().then((res) => {
      if (res !== undefined && res.status === successStatusKey) {
        rm("td_token");
        rm("td_status");
        history.push(res.location);
      }
    });

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const menuStyles = {
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: "lightblue",
      },
    },
  };

  return (
    <AppBar
      position={fixedPosition}
      className={clsx(classes.appBar)}
      style={{ backgroundColor: "white" }}
    >
      <Toolbar className="header" style={{ paddingLeft: "0px" }}>
        {width > 768 && heigth > 414 ? (
          <>
            <IconButton
              edge={iconEdge}
              onClick={() => setOnlyIcon(!onlyIcon)}
              style={{ marginRight: "8px", marginLeft: "2px" }}
            >
              <MenuIcon htmlColor="#5c4424" />
            </IconButton>
            <img
              key={logoKey}
              className="logo"
              alt={logoKey}
              src={nursetLogo}
              onClick={() => {
                history.push(routes.mainPage);
                SetNavbarIsCollapsed(true);
              }}
            />
          </>
        ) : (
          <IconButton
            color={iconColor}
            aria-label={iconLabel}
            edge={iconEdge}
            onClick={() => {
              SetNavbarIsCollapsed(!navbar_collapsed);
            }}
            size="large"
          >
            <MenuIcon htmlColor="#fde6c0" />
          </IconButton>
        )}
        <section className={classes.rightToolbar}>
          <IconButton size="large" onClick={(e: any) => openMenu(e)}>
            <AccountCircleOutlined style={{ color: "#5c4424" }} />
          </IconButton>
        </section>

        <Menu
          id={menuId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          <MenuItem>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Avatar style={{ color: "white" }}>
                  {email ? email[0].toUpperCase() : "A"}
                </Avatar>
              </Grid>
              <Grid item xs={10} container alignItems="center">
                <Grid item xs={12}>
                  {email}
                </Grid>
                <Grid item xs={12}>
                  {role}
                </Grid>
              </Grid>
            </Grid>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ height: "40px" }}
            onClick={() => {
              i18n.language === "en"
                ? i18n.changeLanguage("it")
                : i18n.changeLanguage("en");
            }}
            sx={{ menuStyles }}
          >
            <Grid container alignItems="flex-start">
              <Grid item xs={2}>
                {i18n.language === "en" ? "🇮🇹" : "🇬🇧"}
              </Grid>
              <Grid item xs={10}>
                {i18n.language === "en" ? "Italiano" : "English"}
              </Grid>
            </Grid>
          </MenuItem>

          <MenuItem
            style={{ height: "40px" }}
            onClick={() => {
              history.push(routes.profile);
            }}
          >
            <Grid container alignItems="flex-start">
              <Grid item xs={2}>
                <PersonOutlineOutlinedIcon />
              </Grid>
              <Grid item xs={10}>
                {t("profile")}
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem style={{ height: "40px" }} onClick={logOut}>
            <Grid container alignItems="flex-start">
              <Grid item xs={2}>
                <PowerSettingsNewOutlinedIcon />
              </Grid>
              <Grid item xs={10}>
                {t("logout")}
              </Grid>
            </Grid>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderItem;
