import {
  address,
  city,
  description,
  name,
  selectType,
  subscription,
} from "../../../costants/costants";
import { InputType } from "../../types/types";

export const installationFields: (t: any, subOpt: any[]) => InputType[] = (
  t: any,
  subOpt: any[]
) => [
  {
    key: subscription.key,
    name: subscription.name,
    label: t("selectFleet"),
    type: selectType,
    options: subOpt,
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: name.key,
    name: name.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: "type",
    name: "type",
    label: t("type"),
    type: selectType,
    options: [{ Produzione: "Produzione" }, { Showroom: "Showroom" }],
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: description.key,
    name: description.name,
    label: t("description"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: address.key,
    name: address.name,
    label: t("address"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: city.key,
    name: city.name,
    label: t("city"),
    rules: { required: true, message: t("requiredField") },
  },
];
