import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { dccTitleKey, primaryType } from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { useTranslation } from "react-i18next";
import ButtonConfItem from "../../custom/ButtonConfItem";
import ButtonItem from "../../custom/ButtonItem";
import { addField } from "../../../utilities/utilities";
import { configJson, configurationInputs, paramsJson } from "./costant";
import { ESPLoader } from "../../../misc/esp-web-flasher/esp_loader";
import {
  GetCloudToken,
  getInstallation,
  mklfs,
} from "../../../api/services/installationsService";
import { BackendUrl } from "../../../api/constants";
import { binaryFetch, methodGet } from "../../../api/services/httpRequests";
import { Error, UsbOff } from "@mui/icons-material";
import { useHistory } from "react-router";
import { successConfigurationUrl } from "../costants";

type DeviceConnectionConfigurationProps = {
  installation_id: string;
  update: boolean;
};

const DeviceConnectionConfiguration: React.FC<
  DeviceConnectionConfigurationProps
> = ({ installation_id, update }) => {
  const { t } = useTranslation();
  const [inst, setInst] = useState<any>(null);

  const partitions = {
    bootloader: new ArrayBuffer(0), //os/bootloader.bin
    partitions: new ArrayBuffer(0), //os/partitions.bin
    zerynth: new ArrayBuffer(0), //os/zerynth.bin
    firmware: new ArrayBuffer(0), //firmware/firmware.bin
    otalog: new ArrayBuffer(0), //os/otalog.bin
    fs: new ArrayBuffer(0), //resources/fs.bin
  };

  const [devicePort, setDevicePort] = useState<SerialPort | null>(null);
  const [usbDisconnected, setUsbDisconnected] = useState<boolean>(false);

  const [flashPercentage, setFlashPercentage] = useState<number>(0);

  const [values, setValues] = useState({
    name: "",
    type: "",
    phone: "",
  });

  const [inProgress, setInProgress] = useState<boolean>(
    update ? update : false
  );
  const handleSubmit = () => {
    getInstallation({ id: installation_id }).then((res: any) => {
      if (res && res.installation && res.installation.id) {
        setInst(res.installation);
        setInProgress(true);
      }
    });
  };

  function stringToBuffer(binaryString: string): ArrayBuffer | null {
    try {
      // Decodifica la stringa binaria in base64 in un byte array
      const byteCharacters = atob(binaryString);

      // Crea un array di byte
      const byteArray = new Uint8Array(byteCharacters.length);

      // Riempie l'array di byte con i valori decodificati
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }

      // Crea un ArrayBuffer basato sull'array di byte
      const buffer = byteArray.buffer;

      return buffer;
    } catch (error) {
      console.error(
        "Errore durante la conversione della stringa in ArrayBuffer:",
        error
      );
      return null;
    }
  }

  const [processError, setProcessError] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<any[]>([
    {
      label: t("checkingUsbConnection"),
      status: "pending",
    },
    {
      label: t("settingFirmwarePreferences"),
      status: "tbd",
    },
    {
      label: t("updatingTheFirmware"),
      status: "tbd",
    },
  ]);

  //STEP 1
  const handleDeviceSelect = () => {
    navigator.serial
      .requestPort({ filters: [{ usbVendorId: 0x1a86, usbProductId: 0x7523 }] })
      .then((port) => {
        console.log("port selected:", port);
        setDevicePort(port);
        const tmp = [...steps];
        steps[0].status = "success";
        steps[1].status = "pending";
        setActiveStep((prev) => prev + 1);
        setSteps([...tmp]);
        //start step 2
        handleFirmwarePreferences(port);
      })
      .catch(() => {
        setProcessError(true);
        console.log("no serial port selected");
      });
  };

  const checkingUsbConnection = () => {
    handleDeviceSelect();
  };

  //STEP 2
  const openPort = async (port: SerialPort, baudRate: number, retries = 3) => {
    if (retries > 0) {
      await port
        .open({ baudRate: baudRate })
        .then(() => {
          console.log("serial port opened successfully");
          navigator.serial.onconnect = () => {
            console.log("connected");
          };

          navigator.serial.ondisconnect = () => {
            setUsbDisconnected(true);
            console.log("disconnected");
            setSteps([
              {
                label: t("checkingUsbConnection"),
                status: "pending",
              },
              {
                label: t("settingFirmwarePreferences"),
                status: "tbd",
              },
              {
                label: t("updatingTheFirmware"),
                status: "tbd",
              },
            ]);
            setActiveStep(0);
            console.log("disconnected");
          };
          return true;
        })
        .catch(async (e: any) => {
          console.log("error during port opening:", e);
          console.log("trying to close the port and reopen it again");
          await port.close();
          await openPort(port, baudRate, retries - 1);
          return false;
        });
    } else {
      setProcessError(true);
      console.log("retries finished. failed to open the port.");
    }
  };

  const getPartitions = async () => {
    try {
      for (const k of Object.keys(partitions)) {
        if (k !== "fs") {
          // @ts-ignore
          partitions[k] = await binaryFetch(
            methodGet,
            `${BackendUrl}/firmwares/${inst.model}/file/${k}` //scaricare un file bin alla volta
          );
        }
      }

      const res = await GetCloudToken(inst.id);
      console.log("CLOUD TOKEN", res);

      if (
        res &&
        res.credential &&
        res.credential.devinfo &&
        res.credential.devinfo.device_id &&
        res.credential.prvkey
      ) {
        console.log("before mklfsService");
        const resp = await mklfs({
          net_json: `{"IFCS": [{"ifc_name": "cellular","ifc_params": {"apn": "${values.phone}"}}]}`,
          auth_json: `{"devid": "${
            res?.credential?.devinfo?.device_id || inst.id
          }", "token": "${res?.credential?.prvkey || ""}" }`,
          zfs_config: configJson(inst.model),
          zfs_params: paramsJson(inst.model),
        });

        if (!resp) {
          setProcessError(true);
          console.log("failed to mklfs");
        }
        console.log("RESP", resp);
        partitions["fs"] = stringToBuffer(resp.output_file) as ArrayBuffer;
        console.log("after mklfsService");
      } else {
        setProcessError(true);
        console.log(res);
        devicePort?.close();
      }
    } catch (e) {
      setProcessError(true);
      console.log(e);
      devicePort?.close();
    }
  };

  const flash = async (devicePort: any) => {
    console.log("before get partitions");
    await getPartitions();
    console.log("after get partitions");
    try {
      console.log("DP", devicePort);
      if (devicePort) {
        //start step 3
        const tmp = [...steps];
        steps[1].status = "success";
        steps[2].status = "pending";
        setActiveStep((prev) => prev + 1);
        setSteps([...tmp]);
        await openPort(devicePort, 115200);

        const loader = new ESPLoader(devicePort, {
          log: (...args) => console.log(...args),
          debug: (...args) => console.log(...args),
          error: (...args) => console.log(...args),
        });
        console.log("loader initializer", loader);
        await loader.initialize();
        console.log("loader runstub");
        const espStub = await loader.runStub();
        console.log("setbaudrate");
        await espStub.setBaudrate(921600);
        console.log("PARTITIONS", partitions);
        const ps = [
          {
            name: "bootloader",
            data: partitions.bootloader,
            offset: 0x1000,
          },
          {
            name: "partitions",
            data: partitions.partitions,
            offset: 0x9000,
          },
          {
            name: "zerynth",
            data: partitions.zerynth,
            offset: 0x10000,
          },
          {
            name: "firmware",
            data: partitions.firmware,
            offset: 0x210000,
          },
          {
            name: "otalog",
            data: partitions.otalog,
            offset: 0x910000,
          },
          {
            name: "fs",
            data: partitions.fs,
            offset: 0x920000,
          },
        ];

        for (const p of ps) {
          const i = ps.indexOf(p);
          await espStub.flashData(
            p.data,
            (bytesWritten, totalBytes) => {
              setFlashPercentage(
                i * (100 / ps.length) +
                  (100 / ps.length) * (bytesWritten / totalBytes)
              );
            },
            p.offset
          );
        }

        console.log("stub disconnect");
        await espStub.hardReset();
        await espStub.disconnect();
        await devicePort.close();
        console.log("device flashed successfully");
        return true;
      } else {
        setProcessError(true);
        console.log("NO DEVICE PORT");
      }
    } catch (e) {
      if (devicePort) {
        devicePort?.close();
      }
      setProcessError(true);
      console.log("failed to flash firmware", e);
      return false;
    }
    return true;
  };

  const handleFirmwarePreferences = (port: any) => {
    flash(port).then((res) => {
      if (res) {
        //FINISH CONFIGURATOR PROCEDURE
        handleFinish(port);
      } else {
        setProcessError(true);
      }
    });
  };

  //STEP 3
  const handleFinish = async (port: any) => {
    console.log("FINISH");
    const tmp = [...steps];
    steps[2].status = "success";
    setSteps([...tmp]);
    setActiveStep((prev) => prev + 1);
    history.push(successConfigurationUrl(installation_id));
  };

  //START CONFIGURATOR PROCEDURE
  useEffect(() => {
    localStorage.setItem("hasReloaded", "false");
    if (inProgress) {
      checkingUsbConnection();
    }
  }, [inProgress]);

  const history = useHistory();

  if (inProgress) {
    return (
      <>
        <TitleWithBack title={t("configuration")} key={dccTitleKey} />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
            <Card variant="outlined">
              <CardContent>
                {usbDisconnected ? (
                  <Grid
                    container
                    flexDirection="column"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="h6">
                        {t("disconnectedUsb")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <UsbOff style={{ fontSize: "128px" }} />
                    </Grid>
                    <Grid item>
                      <ButtonItem
                        buttonType={primaryType}
                        label={t("retry")}
                        buttonOnClick={() => window.location.reload()}
                      />
                    </Grid>
                  </Grid>
                ) : processError ? (
                  <Grid
                    container
                    flexDirection="column"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="h6">
                        {t("configurationError")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Error
                        style={{
                          fontSize: "128px",
                          color: "red",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonItem
                        buttonType={primaryType}
                        label={t("retry")}
                        buttonOnClick={() => window.location.reload()}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    flexDirection="column"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                          <Step key={step.label}>
                            <StepLabel>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                  <Typography variant="h6">
                                    {step.label}
                                  </Typography>
                                </Grid>
                                {step.status === "pending" && index === 2 && (
                                  <Grid item>
                                    {flashPercentage.toFixed(2)}%
                                  </Grid>
                                )}
                                {step.status === "pending" && (
                                  <Grid item>
                                    <CircularProgress size={25} />
                                  </Grid>
                                )}
                              </Grid>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <TitleWithBack title={t("configuration")} key={dccTitleKey} />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
            <Card variant="outlined">
              <CardContent>
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item container spacing={2}>
                    {configurationInputs(t).map((el: any) =>
                      addField(el, values, setValues, undefined, true)
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <ButtonConfItem
                        buttonLabel={t("reset")}
                        buttonOnConfirm={() => {
                          setValues({
                            name: "",
                            type: "",
                            phone: "",
                          });
                        }}
                        questionLabel={t("question")}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonItem
                        buttonType={primaryType}
                        label={t("ok")}
                        buttonOnClick={handleSubmit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
};
export default DeviceConnectionConfiguration;
