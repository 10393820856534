import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  getAllDevices,
  getIrrigationValues,
} from "../../../api/services/deviceService";
import TitleWithBack from "../../custom/TitleWithBack";
import ButtonConfItem from "../../custom/ButtonConfItem";
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import ButtonItem from "../../custom/ButtonItem";
import { primaryType } from "../../../costants/costants";
import { addField } from "../../../utilities/utilities";
import { irrigationFields } from "./inputs";
import { useSnackbar } from "notistack";
import { createIrrigation } from "../../../api/services/installationsService";
import CustomLoading from "../../custom/CustomLoading";
import FormSelect from "../../custom/select/FormSelect";

type CustomerIrrigationProps = {};

const CustomerIrrigation: React.FC<CustomerIrrigationProps> = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [deviceId, setDeviceId] = useState<string>("");
  const [devices, setDevices] = useState<any[]>([]);
  useEffect(() => {
    getAllDevices().then((res) => {
      if (res && res.installations) {
        setDevices(
          res.installations.filter((d: any) => d.model === "actuator")
        );
      }
      setLoading(false);
    });
  }, []);

  const [s1, setS1] = useState({
    plant: "",
    irrigation_duration: "",
    variable: "",
    min_variable_value: "",
    min_irrigation_interval: "",
    min_time_below_threshold: "",
  });
  const [s2, setS2] = useState({
    plant: "",
    irrigation_duration: "",
    variable: "",
    min_variable_value: "",
    min_irrigation_interval: "",
    min_time_below_threshold: "",
  });
  const [s3, setS3] = useState({
    plant: "",
    irrigation_duration: "",
    variable: "",
    min_variable_value: "",
    min_irrigation_interval: "",
    min_time_below_threshold: "",
  });
  const [s4, setS4] = useState({
    plant: "",
    irrigation_duration: "",
    variable: "",
    min_variable_value: "",
    min_irrigation_interval: "",
    min_time_below_threshold: "",
  });
  const [s5, setS5] = useState({
    plant: "",
    irrigation_duration: "",
    variable: "",
    min_variable_value: "",
    min_irrigation_interval: "",
    min_time_below_threshold: "",
  });
  const [s6, setS6] = useState({
    plant: "",
    irrigation_duration: "",
    variable: "",
    min_variable_value: "",
    min_irrigation_interval: "",
    min_time_below_threshold: "",
  });
  const [s7, setS7] = useState({
    plant: "",
    irrigation_duration: "",
    variable: "",
    min_variable_value: "",
    min_irrigation_interval: "",
    min_time_below_threshold: "",
  });
  const [s8, setS8] = useState({
    plant: "",
    irrigation_duration: "",
    variable: "",
    min_variable_value: "",
    min_irrigation_interval: "",
    min_time_below_threshold: "",
  });

  const [irrigationLoading, setIrrigationLoading] = useState<boolean>(false);
  useEffect(() => {
    if (deviceId) {
      //setIrrigationLoading(true);
      getIrrigationValues(deviceId).then((res) => {
        if (res && res.data) {
          res.data.map((d: any) => {
            if (d && d.channel_id && d.channel_id === "1") {
              console.log("D", d);
              setS1({
                plant: d?.dev_plant || "",
                irrigation_duration:
                  d?.configurations?.duration &&
                  d?.configurations?.duration !== -1
                    ? d?.configurations?.duration || ""
                    : "",
                variable:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? "electircal_conductivity"
                      : "humidity"
                    : "",
                min_variable_value:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? d.configurations.min_ec
                      : d.configurations.min_hum
                    : "",
                min_irrigation_interval:
                  d?.configurations?.min_irrigation_interval &&
                  d?.configurations?.min_irrigation_interval !== -1
                    ? d?.configurations?.min_irrigation_interval || ""
                    : "",
                min_time_below_threshold:
                  d?.configurations?.under_threshold_time &&
                  d?.configurations?.under_threshold_time !== -1
                    ? d?.configurations?.under_threshold_time || ""
                    : "",
              });
            }
            if (d && d.channel_id && d.channel_id === "2") {
              setS2({
                plant: d?.dev_plant || "",
                irrigation_duration:
                  d?.configurations?.duration &&
                  d?.configurations?.duration !== -1
                    ? d?.configurations?.duration || ""
                    : "",
                variable:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? "electircal_conductivity"
                      : "humidity"
                    : "",
                min_variable_value:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? d.configurations.min_ec
                      : d.configurations.min_hum
                    : "",
                min_irrigation_interval:
                  d?.configurations?.min_irrigation_interval &&
                  d?.configurations?.min_irrigation_interval !== -1
                    ? d?.configurations?.min_irrigation_interval || ""
                    : "",
                min_time_below_threshold:
                  d?.configurations?.under_threshold_time &&
                  d?.configurations?.under_threshold_time !== -1
                    ? d?.configurations?.under_threshold_time || ""
                    : "",
              });
            }
            if (d && d.channel_id && d.channel_id === "3") {
              setS3({
                plant: d?.dev_plant || "",
                irrigation_duration:
                  d?.configurations?.duration &&
                  d?.configurations?.duration !== -1
                    ? d?.configurations?.duration || ""
                    : "",
                variable:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? "electircal_conductivity"
                      : "humidity"
                    : "",
                min_variable_value:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? d.configurations.min_ec
                      : d.configurations.min_hum
                    : "",
                min_irrigation_interval:
                  d?.configurations?.min_irrigation_interval &&
                  d?.configurations?.min_irrigation_interval !== -1
                    ? d?.configurations?.min_irrigation_interval || ""
                    : "",
                min_time_below_threshold:
                  d?.configurations?.under_threshold_time &&
                  d?.configurations?.under_threshold_time !== -1
                    ? d?.configurations?.under_threshold_time || ""
                    : "",
              });
            }
            if (d && d.channel_id && d.channel_id === "4") {
              setS4({
                plant: d?.dev_plant || "",
                irrigation_duration:
                  d?.configurations?.duration &&
                  d?.configurations?.duration !== -1
                    ? d?.configurations?.duration || ""
                    : "",
                variable:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? "electircal_conductivity"
                      : "humidity"
                    : "",
                min_variable_value:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? d.configurations.min_ec
                      : d.configurations.min_hum
                    : "",
                min_irrigation_interval:
                  d?.configurations?.min_irrigation_interval &&
                  d?.configurations?.min_irrigation_interval !== -1
                    ? d?.configurations?.min_irrigation_interval || ""
                    : "",
                min_time_below_threshold:
                  d?.configurations?.under_threshold_time &&
                  d?.configurations?.under_threshold_time !== -1
                    ? d?.configurations?.under_threshold_time || ""
                    : "",
              });
            }
            if (d && d.channel_id && d.channel_id === "5") {
              setS5({
                plant: d?.dev_plant || "",
                irrigation_duration:
                  d?.configurations?.duration &&
                  d?.configurations?.duration !== -1
                    ? d?.configurations?.duration || ""
                    : "",
                variable:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? "electircal_conductivity"
                      : "humidity"
                    : "",
                min_variable_value:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? d.configurations.min_ec
                      : d.configurations.min_hum
                    : "",
                min_irrigation_interval:
                  d?.configurations?.min_irrigation_interval &&
                  d?.configurations?.min_irrigation_interval !== -1
                    ? d?.configurations?.min_irrigation_interval || ""
                    : "",
                min_time_below_threshold:
                  d?.configurations?.under_threshold_time &&
                  d?.configurations?.under_threshold_time !== -1
                    ? d?.configurations?.under_threshold_time || ""
                    : "",
              });
            }
            if (d && d.channel_id && d.channel_id === "6") {
              setS6({
                plant: d?.dev_plant || "",
                irrigation_duration:
                  d?.configurations?.duration &&
                  d?.configurations?.duration !== -1
                    ? d?.configurations?.duration || ""
                    : "",
                variable:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? "electircal_conductivity"
                      : "humidity"
                    : "",
                min_variable_value:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? d.configurations.min_ec
                      : d.configurations.min_hum
                    : "",
                min_irrigation_interval:
                  d?.configurations?.min_irrigation_interval &&
                  d?.configurations?.min_irrigation_interval !== -1
                    ? d?.configurations?.min_irrigation_interval || ""
                    : "",
                min_time_below_threshold:
                  d?.configurations?.under_threshold_time &&
                  d?.configurations?.under_threshold_time !== -1
                    ? d?.configurations?.under_threshold_time || ""
                    : "",
              });
            }
            if (d && d.channel_id && d.channel_id === "7") {
              setS7({
                plant: d?.dev_plant || "",
                irrigation_duration:
                  d?.configurations?.duration &&
                  d?.configurations?.duration !== -1
                    ? d?.configurations?.duration || ""
                    : "",
                variable:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? "electircal_conductivity"
                      : "humidity"
                    : "",
                min_variable_value:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? d.configurations.min_ec
                      : d.configurations.min_hum
                    : "",
                min_irrigation_interval:
                  d?.configurations?.min_irrigation_interval &&
                  d?.configurations?.min_irrigation_interval !== -1
                    ? d?.configurations?.min_irrigation_interval || ""
                    : "",
                min_time_below_threshold:
                  d?.configurations?.under_threshold_time &&
                  d?.configurations?.under_threshold_time !== -1
                    ? d?.configurations?.under_threshold_time || ""
                    : "",
              });
            }
            if (d && d.channel_id && d.channel_id === "8") {
              setS8({
                plant: d?.dev_plant || "",
                irrigation_duration:
                  d?.configurations?.duration &&
                  d?.configurations?.duration !== -1
                    ? d?.configurations?.duration || ""
                    : "",
                variable:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? "electircal_conductivity"
                      : "humidity"
                    : "",
                min_variable_value:
                  d.configurations &&
                  d.configurations.min_ec &&
                  d.configurations.min_hum &&
                  (d.configurations.min_ec !== -1 ||
                    d.configurations.min_hum !== -1)
                    ? d.configurations.min_ec !== -1
                      ? d.configurations.min_ec
                      : d.configurations.min_hum
                    : "",
                min_irrigation_interval:
                  d?.configurations?.min_irrigation_interval &&
                  d?.configurations?.min_irrigation_interval !== -1
                    ? d?.configurations?.min_irrigation_interval || ""
                    : "",
                min_time_below_threshold:
                  d?.configurations?.under_threshold_time &&
                  d?.configurations?.under_threshold_time !== -1
                    ? d?.configurations?.under_threshold_time || ""
                    : "",
              });
            }
          });
        }
        setIrrigationLoading(false);
      });
    }
  }, [deviceId]);

  const [loadingPlants, setLoadingPlants] = useState<boolean>(true);
  const [plants, setPlants] = useState<any[]>([]);
  useEffect(() => {
    getAllDevices().then((res) => {
      if (res && res.installations) {
        const tmp: any[] = [];
        res.installations.map((i: any) => {
          if (i.model === "plant") {
            tmp.push({ [i.id]: i.name });
          }
        });
        setPlants([...tmp]);
      }
      setLoadingPlants(false);
    });
  }, []);

  const handleSubmit = () => {
    createIrrigation(deviceId, {
      installation_id: deviceId,
      irrigation_config: [
        {
          plant_device_id: s1.plant,
          actuator_channel: "1",
          threesholds: {
            min_hum:
              s1.variable === "humidity" ? Number(s1.min_variable_value) : -1,
            min_ec:
              s1.variable === "electircal_conductivity"
                ? Number(s1.min_variable_value)
                : -1,
            under_threshold_time: s1.min_time_below_threshold
              ? Number(s1.min_time_below_threshold)
              : -1,
            duration: s1.irrigation_duration
              ? Number(s1.irrigation_duration)
              : -1,
            min_irrigation_interval: s1.min_irrigation_interval
              ? Number(s1.min_irrigation_interval)
              : -1,
          },
        },
        {
          plant_device_id: s2.plant,
          actuator_channel: "2",
          threesholds: {
            min_hum:
              s2.variable === "humidity" ? Number(s2.min_variable_value) : -1,
            min_ec:
              s2.variable === "electircal_conductivity"
                ? Number(s2.min_variable_value)
                : -1,
            under_threshold_time: s2.min_time_below_threshold
              ? Number(s2.min_time_below_threshold)
              : -1,
            duration: s2.irrigation_duration
              ? Number(s2.irrigation_duration)
              : -1,
            min_irrigation_interval: s2.min_irrigation_interval
              ? Number(s2.min_irrigation_interval)
              : -1,
          },
        },
        {
          plant_device_id: s3.plant,
          actuator_channel: "3",
          threesholds: {
            min_hum:
              s3.variable === "humidity" ? Number(s3.min_variable_value) : -1,
            min_ec:
              s3.variable === "electircal_conductivity"
                ? Number(s3.min_variable_value)
                : -1,
            under_threshold_time: s3.min_time_below_threshold
              ? Number(s3.min_time_below_threshold)
              : -1,
            duration: s3.irrigation_duration
              ? Number(s3.irrigation_duration)
              : -1,
            min_irrigation_interval: s3.min_irrigation_interval
              ? Number(s3.min_irrigation_interval)
              : -1,
          },
        },
        {
          plant_device_id: s4.plant,
          actuator_channel: "4",
          threesholds: {
            min_hum:
              s4.variable === "humidity" ? Number(s4.min_variable_value) : -1,
            min_ec:
              s4.variable === "electircal_conductivity"
                ? Number(s4.min_variable_value)
                : -1,
            under_threshold_time: s4.min_time_below_threshold
              ? Number(s4.min_time_below_threshold)
              : -1,
            duration: s4.irrigation_duration
              ? Number(s4.irrigation_duration)
              : -1,
            min_irrigation_interval: s4.min_irrigation_interval
              ? Number(s4.min_irrigation_interval)
              : -1,
          },
        },
        {
          plant_device_id: s5.plant,
          actuator_channel: "5",
          threesholds: {
            min_hum:
              s5.variable === "humidity" ? Number(s5.min_variable_value) : -1,
            min_ec:
              s5.variable === "electircal_conductivity"
                ? Number(s5.min_variable_value)
                : -1,
            under_threshold_time: s5.min_time_below_threshold
              ? Number(s5.min_time_below_threshold)
              : -1,
            duration: s5.irrigation_duration
              ? Number(s5.irrigation_duration)
              : -1,
            min_irrigation_interval: s5.min_irrigation_interval
              ? Number(s5.min_irrigation_interval)
              : -1,
          },
        },
        {
          plant_device_id: s6.plant,
          actuator_channel: "6",
          threesholds: {
            min_hum:
              s6.variable === "humidity" ? Number(s6.min_variable_value) : -1,
            min_ec:
              s6.variable === "electircal_conductivity"
                ? Number(s6.min_variable_value)
                : -1,
            under_threshold_time: s6.min_time_below_threshold
              ? Number(s6.min_time_below_threshold)
              : -1,
            duration: s6.irrigation_duration
              ? Number(s6.irrigation_duration)
              : -1,
            min_irrigation_interval: s6.min_irrigation_interval
              ? Number(s6.min_irrigation_interval)
              : -1,
          },
        },
        {
          plant_device_id: s7.plant,
          actuator_channel: "7",
          threesholds: {
            min_hum:
              s7.variable === "humidity" ? Number(s7.min_variable_value) : -1,
            min_ec:
              s7.variable === "electircal_conductivity"
                ? Number(s7.min_variable_value)
                : -1,
            under_threshold_time: s7.min_time_below_threshold
              ? Number(s7.min_time_below_threshold)
              : -1,
            duration: s7.irrigation_duration
              ? Number(s7.irrigation_duration)
              : -1,
            min_irrigation_interval: s7.min_irrigation_interval
              ? Number(s7.min_irrigation_interval)
              : -1,
          },
        },
        {
          plant_device_id: s8.plant,
          actuator_channel: "8",
          threesholds: {
            min_hum:
              s8.variable === "humidity" ? Number(s8.min_variable_value) : -1,
            min_ec:
              s8.variable === "electircal_conductivity"
                ? Number(s8.min_variable_value)
                : -1,
            under_threshold_time: s8.min_time_below_threshold
              ? Number(s8.min_time_below_threshold)
              : -1,
            duration: s8.irrigation_duration
              ? Number(s8.irrigation_duration)
              : -1,
            min_irrigation_interval: s8.min_irrigation_interval
              ? Number(s8.min_irrigation_interval)
              : -1,
          },
        },
      ],
    }).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("irrigationSuccess"), { variant: "success" });
        setDeviceId("");
        setS1({
          plant: "",
          irrigation_duration: "",
          variable: "",
          min_variable_value: "",
          min_irrigation_interval: "",
          min_time_below_threshold: "",
        });
        setS2({
          plant: "",
          irrigation_duration: "",
          variable: "",
          min_variable_value: "",
          min_irrigation_interval: "",
          min_time_below_threshold: "",
        });
        setS3({
          plant: "",
          irrigation_duration: "",
          variable: "",
          min_variable_value: "",
          min_irrigation_interval: "",
          min_time_below_threshold: "",
        });
        setS4({
          plant: "",
          irrigation_duration: "",
          variable: "",
          min_variable_value: "",
          min_irrigation_interval: "",
          min_time_below_threshold: "",
        });
        setS5({
          plant: "",
          irrigation_duration: "",
          variable: "",
          min_variable_value: "",
          min_irrigation_interval: "",
          min_time_below_threshold: "",
        });
        setS6({
          plant: "",
          irrigation_duration: "",
          variable: "",
          min_variable_value: "",
          min_irrigation_interval: "",
          min_time_below_threshold: "",
        });
        setS7({
          plant: "",
          irrigation_duration: "",
          variable: "",
          min_variable_value: "",
          min_irrigation_interval: "",
          min_time_below_threshold: "",
        });
        setS8({
          plant: "",
          irrigation_duration: "",
          variable: "",
          min_variable_value: "",
          min_irrigation_interval: "",
          min_time_below_threshold: "",
        });
      } else {
        enqueueSnackbar(t("irrigationError"), { variant: "error" });
      }
    });
  };

  if (irrigationLoading || loadingPlants || loading) {
    return <CustomLoading />;
  }

  return (
    <>
      <TitleWithBack
        title={t("irrigationAlgorithm")}
        key="irrigationAlgorithm"
      />
      <Grid item xs={12} md={8} style={{ marginBottom: "32px" }}>
        <Card variant="outlined">
          <CardContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="primary">
                  {t("selectIrrigationDevice")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  placeholder={`${t("device")}`}
                  keyValue="deviceId"
                  name="deviceId"
                  value={deviceId}
                  onChange={(e: any) => setDeviceId(e.target.value)}
                  options={devices.map((d) => {
                    return { [d.id]: d.name };
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider> Canale 1</Divider>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                {irrigationFields(t, plants).map((el: any) =>
                  addField(el, s1, setS1)
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider> Canale 2</Divider>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                {irrigationFields(t, plants).map((el: any) =>
                  addField(el, s2, setS2)
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider> Canale 3</Divider>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                {irrigationFields(t, plants).map((el: any) =>
                  addField(el, s3, setS3)
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider> Canale 4</Divider>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                {irrigationFields(t, plants).map((el: any) =>
                  addField(el, s4, setS4)
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider> Canale 5</Divider>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                {irrigationFields(t, plants).map((el: any) =>
                  addField(el, s5, setS5)
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider> Canale 6</Divider>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                {irrigationFields(t, plants).map((el: any) =>
                  addField(el, s6, setS6)
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider> Canale 7</Divider>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                {irrigationFields(t, plants).map((el: any) =>
                  addField(el, s7, setS7)
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider> Canale 8</Divider>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                {irrigationFields(t, plants).map((el: any) =>
                  addField(el, s8, setS8)
                )}
              </Grid>
              <Grid
                xs={12}
                item
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item>
                  <ButtonConfItem
                    buttonLabel={t("reset")}
                    buttonOnConfirm={() => {
                      setS1({
                        plant: "",
                        irrigation_duration: "",
                        variable: "",
                        min_variable_value: "",
                        min_irrigation_interval: "",
                        min_time_below_threshold: "",
                      });
                      setS2({
                        plant: "",
                        irrigation_duration: "",
                        variable: "",
                        min_variable_value: "",
                        min_irrigation_interval: "",
                        min_time_below_threshold: "",
                      });
                      setS3({
                        plant: "",
                        irrigation_duration: "",
                        variable: "",
                        min_variable_value: "",
                        min_irrigation_interval: "",
                        min_time_below_threshold: "",
                      });
                      setS4({
                        plant: "",
                        irrigation_duration: "",
                        variable: "",
                        min_variable_value: "",
                        min_irrigation_interval: "",
                        min_time_below_threshold: "",
                      });
                      setS5({
                        plant: "",
                        irrigation_duration: "",
                        variable: "",
                        min_variable_value: "",
                        min_irrigation_interval: "",
                        min_time_below_threshold: "",
                      });
                      setS6({
                        plant: "",
                        irrigation_duration: "",
                        variable: "",
                        min_variable_value: "",
                        min_irrigation_interval: "",
                        min_time_below_threshold: "",
                      });
                      setS7({
                        plant: "",
                        irrigation_duration: "",
                        variable: "",
                        min_variable_value: "",
                        min_irrigation_interval: "",
                        min_time_below_threshold: "",
                      });
                      setS8({
                        plant: "",
                        irrigation_duration: "",
                        variable: "",
                        min_variable_value: "",
                        min_irrigation_interval: "",
                        min_time_below_threshold: "",
                      });
                    }}
                    questionLabel={t("question")}
                  />
                </Grid>
                <Grid item>
                  <ButtonItem
                    buttonType={primaryType}
                    label={t("ok")}
                    buttonOnClick={handleSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default CustomerIrrigation;
