import { GetExportInfoRequest } from "../requests/timeSeries";
import { methodGet, verticalFetch } from "./httpRequests";
import { BackendUrl, GetExportInfoUrl } from "../constants";

export const getExportInfo = (req: GetExportInfoRequest) => {
  return verticalFetch(methodGet, GetExportInfoUrl(req));
};

export const getFirmware = (type: "plant" | "pump" | "actuator") => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/firmwares/latest?type=${type}`
  );
};
