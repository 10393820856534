import {
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getAlarmsCalibration,
  updateAlarmsCalibration,
} from "../../../api/services/installationsService";
import TitleWithBack from "../../custom/TitleWithBack";
import { Installation } from "../../../api/requests/installationsService";
import { Alarm, actuator, plant, pump } from "./costant";
import ButtonConfItem from "../../custom/ButtonConfItem";
import ButtonItem from "../../custom/ButtonItem";
import { primaryType } from "../../../costants/costants";
import { getAllDevices } from "../../../api/services/deviceService";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import FormSelect from "../../custom/select/FormSelect";

type AlarmConfigurationProps = {
  installation_id: string;
};

const AlarmConfiguration: React.FC<AlarmConfigurationProps> = ({
  installation_id,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getAllDevices().then((res) => {
      if (res && res.installations) {
        const tmp = res.installations.filter(
          (i: any) => i.id === installation_id
        );
        if (tmp.length > 0) setSelectedInstallation(tmp[0]);
      }
    });
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [alarms, setAlarms] = useState<any[]>([]);
  useEffect(() => {
    getAlarmsCalibration(installation_id).then((res: any) => {
      if (res && res.alarms) {
        setAlarms(res.alarms);
      }
      setLoading(false);
    });
  }, []);

  const [alarmsConfiguration, setAlarmsConfiguration] = useState<any[]>([]);
  useEffect(() => {
    if (selectedInstallation) {
      if (selectedInstallation.model === "actuator") {
        setAlarmsConfiguration(actuator(t));
      }
      if (selectedInstallation.model === "plant") {
        setAlarmsConfiguration(plant(t));
      }
      if (selectedInstallation.model === "pump") {
        setAlarmsConfiguration(pump(t));
      }
    }
  }, [selectedInstallation]);

  const checkAlarmEnable = (name: string, label: string) => {
    const tmp = alarms.filter((a: any) => String(name).includes(a.name));
    if (tmp.length === 1) {
      return tmp[0].config[label];
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    const body: {
      installation_id: string;
      alarms_array_update: {
        config_id: string;
        config: {
          enabled: boolean;
          level?: string;
          duration?: number;
          upper_threshold?: string;
          lower_threshold?: string;
        };
      }[];
    } = {
      installation_id: installation_id,
      alarms_array_update: [],
    };
    alarmsConfiguration.forEach((a: Alarm) => {
      let tmp: {
        enabled: boolean;
        level?: string;
        duration?: number;
        upper_threshold?: string;
        lower_threshold?: string;
      } = { enabled: false };
      a.field.forEach((f: any) => {
        if (f.type === "switch") {
          tmp = { ...tmp, enabled: f.value };
        } else {
          if (f.name.includes("min_threshold")) {
            tmp = { ...tmp, lower_threshold: String(Number(f.value)) };
          }
          if (f.name.includes("max_threshold")) {
            tmp = { ...tmp, upper_threshold: String(Number(f.value)) };
          }
          if (f.name.endsWith("_timelimit")) {
            tmp = { ...tmp, duration: Number(f.value) };
          }
          if (f.name.endsWith("_level")) {
            tmp = { ...tmp, level: String(f.value) };
          }
        }
      });
      if (
        a &&
        a.field &&
        a.field.length > 0 &&
        a.field[0].name &&
        (alarms || []).filter((aa: any) =>
          String(a.field[0].name).includes(aa.name)
        ).length > 0
      ) {
        body.alarms_array_update.push({
          config_id: (alarms || []).filter((aa: any) =>
            String(a.field[0].name).includes(aa.name)
          )[0].id,
          config: { ...tmp },
        });
      }
    });
    updateAlarmsCalibration(installation_id, body).then((res) => {
      if (res && !res.err) {
        enqueueSnackbar(t("alarmUpdateSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        history.goBack();
      } else {
        enqueueSnackbar(res.err?.message || t("somethingWentWrong"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack title={t("alarms")} />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                {alarmsConfiguration.map((a: Alarm, index: number) => {
                  return (
                    <Grid
                      key={index}
                      item
                      container
                      spacing={2}
                      xs={12}
                      justifyContent="space-between"
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6">{a.label}</Typography>
                      </Grid>
                      {a.field.map((f: any) => {
                        f.value = checkAlarmEnable(
                          f.name,
                          f.label === t("level")
                            ? "level"
                            : f.label === t("status")
                            ? "enabled"
                            : f.label === t("lowerThreshold")
                            ? "lower_threshold"
                            : f.label === t("upperThreshold")
                            ? "upper_threshold"
                            : "duration"
                        );
                        if (f.type === "switch") {
                          return (
                            <Grid
                              item
                              justifyContent="flex-start"
                              container
                              xs={2}
                              alignItems="center"
                            >
                              <Grid item>
                                <Switch
                                  defaultChecked={Boolean(f.value)}
                                  onChange={(e) => (f.value = e.target.checked)}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant="body1">
                                  {t("enabled")}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }
                        if (f.type === "number") {
                          return (
                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                fullWidth
                                type="number"
                                label={f.label}
                                variant="outlined"
                                defaultValue={Number(f.value)}
                                onChange={(e) =>
                                  (f.value = Number(e.target.value))
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {f.endAdornment ? f.endAdornment : ""}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          );
                        }
                        if (f.type === "select") {
                          return (
                            <Grid item xs={6}>
                              <FormSelect
                                placeholder={f.label}
                                keyValue={f.name}
                                name={f.name}
                                defaultValue={f.value}
                                onChange={(e: any) => {
                                  f.value = String(e.target.value);
                                }}
                                options={f.options ? f.options : []}
                              />
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  );
                })}
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ButtonConfItem
                      buttonLabel={t("reset")}
                      buttonOnConfirm={() => {
                        console.log("reset");
                      }}
                      questionLabel={t("question")}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("ok")}
                      buttonOnClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AlarmConfiguration;
