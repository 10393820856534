import { address, city, description, name } from "../../../costants/costants";
import { InputType } from "../../types/types";

export const installationFields: (t: any) => InputType[] = (t: any) => [
  {
    key: name.key,
    name: name.name,
    label: t("name"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: description.key,
    name: description.name,
    label: t("description"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: address.key,
    name: address.name,
    label: t("address"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: city.key,
    name: city.name,
    label: t("city"),
    rules: { required: true, message: t("requiredField") },
  },
];
