import { InputType } from "../../types/types";

export const irrigationFields: (t: any, opts: any[]) => InputType[] = (
  t: any,
  opts: any[]
) => [
  {
    key: "plant",
    name: "plant",
    label: t("device"),
    rules: { required: true, message: t("requiredField") },
    type: "select",
    options: opts,
  },
  {
    type: "number",
    key: "irrigation_duration",
    name: "irrigation_duration",
    label: t("irrigationDuration"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    key: "variable",
    name: "variable",
    label: t("variable"),
    rules: { required: true, message: t("requiredField") },
    type: "select",
    options: [
      { electircal_conductivity: t("electricalConductivity") },
      { humidity: t("humidity") },
    ],
  },
  {
    type: "number",
    key: "min_variable_value",
    name: "min_variable_value",
    label: t("minimumVariableValue"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    type: "number",
    key: "min_irrigation_interval",
    name: "min_irrigation_interval",
    label: t("minimumIrrigationInterval"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    type: "number",
    key: "min_time_below_threshold",
    name: "min_time_below_threshold",
    label: t("timeBelowThreshold"),
    rules: { required: true, message: t("requiredField") },
  },
];

export const startIrrigationFields: (t: any) => InputType[] = (t: any) => [
  {
    type: "number",
    key: "min_humidity",
    name: "min_humidity",
    label: t("minimumHumidityThreshold"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    type: "number",
    key: "min_electric_conducibily",
    name: "min_electric_conducibily",
    label: t("minimumElectricalConductivityThreshold"),
    rules: { required: true, message: t("requiredField") },
  },
];

export const endIrrigationFields: (t: any) => InputType[] = (t: any) => [
  {
    type: "number",
    key: "max_humidity",
    name: "max_humidity",
    label: t("maximumHumidityThreshold"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    type: "number",
    key: "max_electric_conducibily",
    name: "max_electric_conducibily",
    label: t("maximumElectricalConductivityThreshold"),
    rules: { required: true, message: t("requiredField") },
  },
  {
    type: "number",
    key: "min_temperature",
    name: "min_temperature",
    label: t("minimumTemperatureThreshold"),
    rules: { required: true, message: t("requiredField") },
  },
];
