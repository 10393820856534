import React from "react";
import {
  customersListPageUrl,
  installationsListToUpdateUrl,
  installersListPageUrl,
  mainUrl,
  subscriptionsListToUpdateUrl,
} from "../pages/costants";
import { Grid, Typography } from "@mui/material";
import TitleWithBack from "./TitleWithBack";
import { useTranslation } from "react-i18next";
import errorImage from "../../images/error.svg";
import successImage from "../../images/success.svg";

type OperationResultProps = {
  status: "success" | "error";
  operation: "create" | "update";
  entity: "user" | "installer" | "installation" | "subscription" | "signal";
  statusDescription?: string;
  onClickProp?: () => void;
  error?: string;
};

const OperationResult: React.FC<OperationResultProps> = ({
  status,
  operation,
  entity,
  statusDescription,
  onClickProp,
  error,
}) => {
  const { t } = useTranslation();
  const messages = {
    create: {
      user: {
        link: mainUrl,
        success: t("createUserSuccess"),
        error: t("createUserError"),
      },
      installer: {
        link: mainUrl,
        success: t("createInstallerSuccess"),
        error: t("createInstallerError"),
      },
      installation: {
        link: mainUrl,
        success: t("createInstallationSuccess"),
        error: t("createInstallationError"),
      },
      subscription: {
        link: mainUrl,
        success: t("createSubscriptionSuccess"),
        error: t("createsubscriptionError"),
      },
      signal: {
        link: mainUrl,
        success: t("createSignalSuccess"),
        error: t("createSignalError"),
      },
    },
    update: {
      user: {
        link: customersListPageUrl,
        success: t("updateUserSuccess"),
        error: t("updateUserError"),
      },
      installer: {
        link: installersListPageUrl,
        success: t("updateInstallerSuccess"),
        error: t("updateInstallerError"),
      },
      installation: {
        link: installationsListToUpdateUrl,
        success: t("updateInstallationSuccess"),
        error: t("updateInstallationError"),
      },
      subscription: {
        link: subscriptionsListToUpdateUrl,
        success: t("updateSubscriptionSuccess"),
        error: t("updatesubscriptionError"),
      },
      signal: {
        link: installationsListToUpdateUrl,
        success: t("updateSignalSuccess"),
        error: t("updateSignalError"),
      },
    },
  };

  return (
    <>
      <TitleWithBack
        title={""}
        link={messages[operation][entity]["link"]}
        onClickProp={onClickProp ? onClickProp : undefined}
      />
      <Grid container spacing={2} flexDirection="column" alignItems="center">
        <Grid item xs={12} md={3}>
          <img
            src={status === "success" ? successImage : errorImage}
            alt={status === "success" ? "success" : "error"}
            width="100%"
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {(status === "success"
              ? t("success")
              : t("error")
            ).toLocaleUpperCase()}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" align="center">
            {error && error !== "success"
              ? error
              : messages[operation][entity][status]}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default OperationResult;
