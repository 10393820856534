import {
  CreateIdentityRequest,
  CreateInstallationRequest,
  DeleteIdentityRequest,
  GetJobStatusRequest,
  GetInstallationRequest,
  GetInstallationVariablesRequest,
  SendJobToInstallationRequest,
  SetInstallationVariableRequest,
  UpdateIdentityRequest,
  UpdateInstallationRequest,
  InstallationUsageRequest,
  JobRequest,
  WifiRequest,
} from "../requests/installationsService";
import {
  binaryFetch,
  httpFetch,
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";
import {
  BackendUrl,
  InstallationsServiceUrl,
  SubscriptionsUrl,
  rowsPerTablePage,
} from "../constants";

export const CreateInstallation = (r: CreateInstallationRequest) => {
  return verticalFetch(methodPost, SubscriptionsUrl, JSON.stringify(r));
};

export const GetAllInstallations = (
  page: string,
  sort: string,
  search: string
) => {
  return verticalFetch(
    methodGet,
    `${SubscriptionsUrl}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

export const UpdateInstallation = (r: UpdateInstallationRequest) => {
  return verticalFetch(
    methodPut,
    `${SubscriptionsUrl}/${r.id}/info`,
    JSON.stringify(r)
  );
};

export const getInstallation = (r: GetInstallationRequest) => {
  return verticalFetch(methodGet, `${InstallationsServiceUrl}/${r.id}`);
};

export const SendJobToInstallation = (r: SendJobToInstallationRequest) => {
  return verticalFetch(
    methodPost,
    `${SubscriptionsUrl}/${r.id}/jobs/${r.job_name}`,
    JSON.stringify(r)
  );
};

export const GetJobStatus = (r: GetJobStatusRequest) => {
  return verticalFetch(
    methodGet,
    `${SubscriptionsUrl}/${r.id}/jobs/${r.job_name}`
  );
};

export const SetInstallationVariable = (r: SetInstallationVariableRequest) => {
  return verticalFetch(
    methodPost,
    `${SubscriptionsUrl}/${r.id}/vars/${r.variable_name}`,
    JSON.stringify(r)
  );
};

export const GetInstallationVariable = (r: GetInstallationVariablesRequest) => {
  return verticalFetch(methodGet, `${SubscriptionsUrl}/${r.id}/vars`);
};

//Identity
export const CreateIdentity = (
  r: CreateIdentityRequest,
  installation_id: string
) => {
  return verticalFetch(
    methodPost,
    `${SubscriptionsUrl}/${installation_id}/identities/create`,
    JSON.stringify(r)
  );
};

export const GetIdentity = (installation_id: string) => {
  return verticalFetch(
    methodGet,
    `${SubscriptionsUrl}/${installation_id}/identities`
  );
};

export const UpdateIdentity = (
  r: UpdateIdentityRequest,
  installation_id: string,
  dcn: string
) => {
  return verticalFetch(
    methodPut,
    `${SubscriptionsUrl}/${installation_id}/identities/${dcn}`,
    JSON.stringify(r)
  );
};

export const DeleteIdentity = (r: DeleteIdentityRequest) => {
  return verticalFetch(
    methodDelete,
    `${SubscriptionsUrl}/${r.installation_id}/identities/${r.dcn}`
  );
};

export const GetInstallationUsage = (r: InstallationUsageRequest) => {
  return verticalFetch(
    methodGet,
    `${SubscriptionsUrl}/${r.installation_id}/usage?start=${r.start}&end=${r.end}`
  );
};

export const GetAllAlarms = (page: string, sort: string, search: string) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/timeseries/alarms/installations?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

export const GetAlarms = (device_id: string, start: string, end: string) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/timeseries/alarms/installations?installation_id=${device_id}&start=${start}&end=${end}&sort=-start`
  );
};

export const getJob = (device_id: string, type: string) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/installations/${device_id}/calibrations/${type}`
  );
};

export const setJob = (device_id: string, type: string, request: any) => {
  return verticalFetch(
    methodPost,
    `${BackendUrl}/installations/${device_id}/calibrations/${type}`,
    JSON.stringify(request)
  );
};

//Get the history of an installation
export const getHistory = (installation_id: string, startHour: string) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/timeseries/installations/${installation_id}/history?start=${startHour}&finish=${new Date().toISOString()}&page=-1`
  );
};

//Installation maintenance
export const maintenanceInstallation = (
  installation_id: string,
  note: string
) => {
  return verticalFetch(
    methodPut,
    `${BackendUrl}/installations/${installation_id}/maintenance`,
    JSON.stringify({ notes: note })
  );
};

export const checkServerPort = (p: string) => {
  return httpFetch(methodGet, `http://127.0.0.1:${p}/check`);
};

//Get alarms calibration
export const getAlarmsCalibration = (device_id: string) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/installations/${device_id}/alarms`
  );
};

//Update alarms calibration
export const updateAlarmsCalibration = (
  device_id: string,
  request: {
    installation_id: string;
    alarms_array_update: {
      config_id: string;
      config: {
        enabled: boolean;
        duration?: number;
        upper_threshold?: string;
        lower_threshold?: string;
      };
    }[];
  }
) => {
  return verticalFetch(
    methodPut,
    `${BackendUrl}/installations/${device_id}/alarms`,
    JSON.stringify(request)
  );
};

export const wifiConfiguration = (device_id: string, body: WifiRequest) => {
  return verticalFetch(
    methodPost,
    `${BackendUrl}/installations/${device_id}/jobs/set_wifi`,
    JSON.stringify(body)
  );
};

export const saveLogs = (device_id: string, logs: any[]) => {
  return verticalFetch(
    methodPost,
    `${BackendUrl}/installations/${device_id}/testLogs`,
    JSON.stringify({ logs: logs })
  );
};

export const getInstallationLogs = (
  device_id: string,
  page: string,
  sort: string,
  search: string
) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/installations/${device_id}/testLogs?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

//Delete installation
export const DeleteInstallation = (installationId: string) => {
  return verticalFetch(
    methodDelete,
    `${BackendUrl}/installations`,
    JSON.stringify({ installation_id: installationId })
  );
};

//get device from identity
export const getZdmDeviceFromIdentity = (dcn: string) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/installations/devIDDCN/${dcn}`
  );
};

//DEVICE CONFIGURATION

export const mklfs = (files: any) => {
  return verticalFetch(
    methodPost,
    `${BackendUrl}/installations/mklfs`,
    JSON.stringify(files, null, 2)
  );
};

export const uploadFiles = (device_id: string, files: any[]) => {
  const credentials: any = "include";
  const formdata = new FormData();
  files.map((f) => formdata.append(f.file.name, f.file));
  return fetch(
    `${BackendUrl}/installations/dev/${device_id}/uploadConfigFiles`,
    {
      method: methodPost,
      body: formdata,
      credentials: credentials,
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const commit = (device_id: string) => {
  return verticalFetch(
    methodPost,
    `${BackendUrl}/installations/dev/${device_id}/zfsCommit`
  );
};

//Get cloud token
export const GetCloudToken = (installationId: string) => {
  return verticalFetch(
    methodPost,
    `${BackendUrl}/installations/device/${installationId}/credentials`,
    JSON.stringify({
      devid: installationId,
      endpoint_mode: "insecure",
      provision_mode: "cloud_token",
    })
  );
};

//IRRIGAZIONE API
export const createIrrigation = (actuatorId: string, body: any) => {
  return verticalFetch(
    methodPost,
    `${BackendUrl}/installations/irrigation/${actuatorId}/createIrrigationMonitoring`,
    JSON.stringify(body)
  );
};

export const getIrrigation = () => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/installations/irrigation/getAllIrrigationMonitoring`
  );
};
