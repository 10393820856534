import ButtonItem from "../../custom/ButtonItem";
import ButtonConfItem from "../../custom/ButtonConfItem";
import { AddUser as ApiAddUser } from "../../../api/services/userService";
import React, { useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import {
  accountDataFields,
  businessNameFields,
  installerDataFields,
} from "./inputs";
import { roles } from "../costants";
import OperationResult from "../../custom/OperationResult";
import { errorStatus, primaryType } from "../../../costants/costants";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { addField, checkValues } from "../../../utilities/utilities";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const CreateInstaller: React.FC = () => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState<string>("");
  const goBack = () => setStatus("");

  const [error, setError] = useState<boolean>(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    company_phone: "",
    address: "",
    city: "",
    province: "",
    country: "",
    zip_code: "",
    subscription_name: "",
    company_address: "",
    company_city: "",
    company_province: "",
    company_country: "",
    company_zip_code: "",
    company_vat_number: "",
    company_sdi: "",
    pec: "",
    name: "",
    surname: "",
  });

  const handleSubmit = () => {
    console.log("V", values);
    if (checkValues(values)) {
      ApiAddUser({
        //DATI ACCOUNT
        name: values.name,
        email: values.email,
        password: values.password,
        confirm_password: values.password,
        role: roles.installer,
        //DATI UTENZA
        user_info: {
          phone: values.company_phone,
          address: values.address,
          city: values.city,
          province: values.province,
          country: values.country,
          zip_code: values.zip_code,
          //DATI FATTURAZIONE
          billing_info: {
            soc_reason: values.subscription_name,
            address: values.company_address,
            city: values.company_city,
            province: values.company_province,
            state: values.company_country,
            zip_code: values.company_zip_code,
            piva: values.company_vat_number,
            sdi: values.company_sdi,
            pec: values.pec,
          },
          //TITOLARE LEGALE
          legal_owner_info: {
            name: values.name,
            surname: values.surname,
          },
        },
      })
        .then((res: any) => {
          if (res && res.user) {
            setStatus("success");
          } else {
            setStatus(res?.err?.message || "");
          }
        })
        .catch((e) => {
          setStatus(e);
          console.log(e);
        });
    } else {
      setError(true);
    }
  };

  if (status) {
    return (
      <OperationResult
        onClickProp={status === errorStatus ? goBack : undefined}
        status={status === "success" ? "success" : "error"}
        operation={"create"}
        entity={"installer"}
        error={status}
      />
    );
  }
  return (
    <>
      <CustomTitle title={t("createInstaller")} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">{t("installerData")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {installerDataFields(t).map((el) =>
                    addField(el, values, setValues, error)
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="h6">{t("businessName")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {businessNameFields(t).map((el) =>
                    addField(el, values, setValues, error)
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="h6">{t("accountData")}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  {accountDataFields(t).map((el) =>
                    addField(el, values, setValues, error)
                  )}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ButtonConfItem
                      buttonLabel={t("reset")}
                      buttonOnConfirm={() =>
                        setValues({
                          email: "",
                          password: "",
                          company_phone: "",
                          address: "",
                          city: "",
                          province: "",
                          country: "",
                          zip_code: "",
                          subscription_name: "",
                          company_address: "",
                          company_city: "",
                          company_province: "",
                          company_country: "",
                          company_zip_code: "",
                          company_vat_number: "",
                          company_sdi: "",
                          pec: "",
                          name: "",
                          surname: "",
                        })
                      }
                      questionLabel={t("question")}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("ok")}
                      buttonOnClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateInstaller;
