import React, { useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { dccTitleKey, primaryType } from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { useTranslation } from "react-i18next";
import ButtonItem from "../../custom/ButtonItem";
import { CheckCircle } from "@mui/icons-material";
import { useHistory } from "react-router";
import { installationsListToUpdateUrl } from "../costants";

type SuccesConfigurationProps = {
  installation_id: string;
};

const SuccesConfiguration: React.FC<SuccesConfigurationProps> = ({
  installation_id,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const hasReloaded = localStorage.getItem("hasReloaded");
    if (!hasReloaded || hasReloaded === "false") {
      // Esegui il ricaricamento solo se non è già stato fatto
      window.location.reload();
      localStorage.setItem("hasReloaded", "true"); // Imposta il flag di ricaricamento
    }
  }, []);

  return (
    <>
      <TitleWithBack
        title={t("configuration")}
        key={dccTitleKey}
        onClickProp={() => history.push(installationsListToUpdateUrl)}
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">
                    {t("configurationSuccess")}
                  </Typography>
                </Grid>
                <Grid item>
                  <CheckCircle
                    style={{
                      fontSize: "128px",
                      color: "#4c8c2c",
                    }}
                  />
                </Grid>
                <Grid item>
                  <ButtonItem
                    buttonType={primaryType}
                    label={t("ok")}
                    buttonOnClick={() =>
                      history.push(installationsListToUpdateUrl)
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default SuccesConfiguration;
