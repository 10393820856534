import data from "./img/graph.png";
import associations from "./img/associations.png";
import installer from "./img/installer.png";
import editFleet from "./img/editFleet.png";
import alarm from "./img/alert.png";
import admin from "./img/admin.png";
import book from "./img/book.png";
import configurator from "./img/inst.png";
import history from "./img/fleet.png";
import user from "./img/user.png";
import device from "./img/inst.png";

import {
  adminPanelIconsUrl,
  campaignPageUrl,
  installerPanelIconsUrl,
  installersAssociationsUrl,
  irrigationConfigurationCustomerUrl,
  routes,
  subscriptionsListToUpdateUrl,
} from "../costants";
import { adminRole, installerRole } from "../../../costants/costants";

export type IconCard = {
  img: string;
  text: string[];
  link: string[];
};

export const roleBasedIcons: (t: any, r: string) => IconCard[] = (t, r) => {
  if (r === installerRole) {
    return [
      {
        img: configurator,
        text: [t("configurator")],
        link: [installerPanelIconsUrl],
      },
    ];
  } else if (r === adminRole) {
    return [
      {
        img: admin,
        text: [t("adminPanel")],
        link: [adminPanelIconsUrl],
      },
    ];
  } else {
    return [
      {
        img: configurator,
        text: [t("irrigation")],
        link: [irrigationConfigurationCustomerUrl],
      },
    ];
  }
};

export const homePageIconCards: (t: any, r: string) => IconCard[] = (t, r) => {
  return [
    {
      img: data,
      text: [t("realTimeData")],
      link: [routes.subscriptionsListToInstallationPage],
    },
    {
      img: history,
      text: [t("dataHistory")],
      link: [routes.subscriptionsListToDataHistory],
    },
    {
      img: alarm,
      text: [t("alarms")],
      link: [routes.alarms],
    },
    {
      img: book,
      text: [t("campaignNotebook")],
      link: [campaignPageUrl],
    },
    ...roleBasedIcons(t, r),
  ];
};

export const adminPanelIconCards: (t: any) => IconCard[] = (t: any) => [
  {
    img: installer,
    text: [t("createInstaller"), t("editInstaller")],
    link: [routes.addInstaller, routes.installersList],
  },
  {
    img: associations,
    text: [t("installersAssociations")],
    link: [installersAssociationsUrl],
  },
  {
    img: editFleet,
    text: [t("installationsManagement")],
    link: [subscriptionsListToUpdateUrl],
  },
];

export const configuratorPanelIconCards: (
  role: string,
  t: any
) => IconCard[] = (role: string, t: any) => {
  return [
    {
      img: user,
      text: [t("createFleet"), t("editFleet")],
      link: [routes.addSubscription, routes.subscriptionsListToUpdate],
    },
    {
      img: device,
      text: [t("createDevice"), t("editDevice")],
      link: [routes.addInstallation, routes.installationsListToUpdate],
    },
  ];
};
