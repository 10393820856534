import React, { useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { dccTitleKey, primaryType } from "../../../costants/costants";
import TitleWithBack from "../../custom/TitleWithBack";
import { useTranslation } from "react-i18next";
import ButtonConfItem from "../../custom/ButtonConfItem";
import ButtonItem from "../../custom/ButtonItem";
import { addField } from "../../../utilities/utilities";
import { createDevice } from "../../../api/services/deviceService";
import { useHistory } from "react-router";
import { configurationInputs } from "./constants";
import { useSnackbar } from "notistack";

type CreateDeviceProps = {
  installation_id: string;
  update: boolean;
};

const CreateDevice: React.FC<CreateDeviceProps> = ({
  installation_id,
  update,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    name: "",
    type: "",
  });

  const handleSubmit = () => {
    createDevice({
      subscription_id: installation_id,
      name: values.name,
      model: values.type,
      installation_info: {},
    }).then((res: any) => {
      if (res && res.installation) {
        enqueueSnackbar(t("createDeviceSuccess"), {
          variant: "success",
          preventDuplicate: true,
        });
        history.goBack();
      } else {
        enqueueSnackbar(t("createDeviceError"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    });
  };

  return (
    <>
      <TitleWithBack title={t("createDevice")} key={dccTitleKey} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} style={{ marginBottom: "64px" }}>
          <Card variant="outlined">
            <CardContent>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item container spacing={2}>
                  {configurationInputs(t).map((el: any) =>
                    addField(el, values, setValues, undefined, true)
                  )}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ButtonConfItem
                      buttonLabel={t("reset")}
                      buttonOnConfirm={() => {
                        setValues({
                          name: "",
                          type: "",
                        });
                      }}
                      questionLabel={t("question")}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonItem
                      buttonType={primaryType}
                      label={t("ok")}
                      buttonOnClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default CreateDevice;
